/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { Button, CustomTable, SideDrawer } from "../../Library/Module";
import MyAccountWrapper from "./MyAccountWrapper";
import MyAppointmentRow from "./MyAppointmentsRow";
import { GetFamily } from "../../servies/services";
import MedicalHistoryRow from "./MedicalHistoryRow";
import FamilyDetailsRow from "./FamilyDetailsRow";
import AddFamilyForm from "../Family/AddFamilyForm";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "name",
            display: "Name",
            sort: true,
        },
        {
            name: "age",
            display: "Age",
            sort: true,
        },
        {
            name: "gender",
            display: "Gender",
            sort: true,
        },

        {
            name: "relation",
            display: "Relation",
            sort: true,
        },
        
        {
            name: "created_on",
            display: "Added Date",
            sort: true,
        },
        {
            name: "created_on",
            display: "Questionnaire",
            sort: false,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};


const FamilyDetails = (): JSX.Element => {
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});

    const pageData = 'Members'
    const pageDescData = 'You can manage the Members in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {
        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })
    }

    const showCellCallback = () => {

    }

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";
            const apidata = await GetFamily(user_details_frontend?.id);
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI]);

    const AddMember = () => {
        setActionType({
            type: "add",
            id: 'id',

        })

    }
    const CloseDrawer = () => {
        setActionType({});
    };
    const FormSubmission = (data: boolean) => {
        setActionType({});
        setReloadAPI(true);
    }
    return (
        <MyAccountWrapper>

            <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={pageData}
                tableSubTitle={pageDescData}
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
                headerAction={<Button onClick={() => AddMember()} className="btn btn-primary">Add Member</Button>}
            >
                {({ rowData, index }: { rowData: any; index: any }) => (
                    <FamilyDetailsRow
                        rowData={rowData}
                        index={index}
                        showCells={showCells}
                        reloadData={reloadData}
                        callbackId={callbackFresh}
                    ></FamilyDetailsRow>
                )}
            </CustomTable>
            {(actionType?.type === "add" || actionType?.type === "edit") && (
                <SideDrawer
                    size={'400px'}
                    pagetitle={`Family Details`}
                    action={CloseDrawer}
                >
                    <AddFamilyForm reloadAPI={FormSubmission}></AddFamilyForm>
                </SideDrawer>
            )}
        </MyAccountWrapper>
    );
};
export default FamilyDetails;
