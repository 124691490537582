/* eslint-disable no-empty-pattern */
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AddFamilyMember, AddMedicalHistory, AddQuestionnaire, GetFamily, GetFamilyDetailById, GetQuestionnaireById, GetServices, UpdateQuestionnaireById, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Card, CustomTable, Input, Select, SideDrawer } from "../../Library/Module";
import { theme } from "../../theme";
import Login from "../Login/Login";
import LoginForm from "../Login/LoginForm";
import TabComp from "../../Library/Tab/Tab";
import RegisterForm from "../Register/RegisterForm";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'

import { objectToQueryString } from "../../Library/Utility/Utility";
import PlanDetails from "../../components/PlanDetails";
import AuthDetect from "../MyAccount/AuthDetect";
import QuestionnaireInner from "./QuestionnaireInner";
import QuestionnaireInnerV2 from "./QuestionnaireInner2";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "select",
            display: "Select Patient",
            sort: false,
        },
        {
            name: "name",
            display: "Name",
            sort: true,
        },
        {
            name: "age",
            display: "Age",
            sort: true,
        },
        {
            name: "gender",
            display: "Gender",
            sort: true,
        },

        {
            name: "relation",
            display: "Relation",
            sort: true,
        },
        {
            name: "created_on",
            display: "Added Date",
            sort: true,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};

const Questionnaire = (): JSX.Element => {
    const [formData, setFormData] = useState<any>();
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});
    const navigate = useNavigate();
    const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();
    const pageData = 'Family Details'
    const pageDescData = 'You can manage the Family Details in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {
        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })

        setTableDataCalendarSingle([]);
        const getData2 = async () => {
            const query = {
                parent_id: [data?.parent_id]
            }
            const apidata = await GetFamilyDetailById(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            setTableDataCalendarSingle(APIResponse);
        };
        getData2();
    }



    const showCellCallback = () => {

    }

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetFamily();
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI]);

    const AddMember = () => {
        setActionType({
            type: "add",
            id: 'id',

        })

    }
    const CloseDrawer = () => {
        setActionType({});
    };

    const FormSubmission = (data: boolean) => {
        setActionType({});
        setReloadAPI(true);
    }

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const [apiType, setApiType] = useState('post')
    const [id, setId] = useState(null)
    useEffect(() => {
        const GetData = async () => {
            // @ts-ignore
            const familyDetails = JSON.parse(localStorage.getItem("family_details"));
            const APIResponse = await GetQuestionnaireById(familyDetails?._id);
            console.log("APIResponse asdsad", APIResponse?.data?.data)
            if (APIResponse?.data?.data?.[0]?.questionnaire) {
                console.log("APIResponse", APIResponse?.data?.data?.[0]?.questionnaire)
                setApiType('patch')
                setFormData(APIResponse?.data?.data?.[0]?.questionnaire)
                setId(APIResponse?.data?.data?.[0]?._id)
            }
        }

        GetData()
    }, [])

    const Submit = async () => {
        if (
            formData !== undefined
        ) {

            // @ts-ignore
            const selectedServices = JSON.parse(localStorage.getItem("selected_services"));
            // @ts-ignore
            const timeDate = JSON.parse(localStorage.getItem("time_date"));
            // @ts-ignore
            const familyDetails = JSON.parse(localStorage.getItem("family_details"));

            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
            const data = {
                "user": user_details_frontend?.user_name,
                "member_name": familyDetails?.name,
                "member_id": familyDetails?._id,
                "user_id": user_details_frontend?.id,
                "questionnaire": formData,
                "status": "1",
                "created_by": user_details_frontend?.user_name,
                ...(apiType === "patch" && id ? { _id: id } : {})
            }

            const APIResponse = apiType === "post" ? await AddQuestionnaire(data) : await UpdateQuestionnaireById(data);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success("Your details submitted succesfully.");
                navigate("/payment")
            } else {
                if (APIResponse?.data?.messages) {
                    toast.error(APIResponse?.data?.messages);
                } else {
                    toast.error("Something went wrong. please try again.");
                }
            }
        } else {
            toast.error("Please enter required fields");
        }
    }

    const options = [
        {
            "text": "No",
            "value": "No",
            "id": 2
        }, {
            "text": "Yes",
            "value": "Yes",
            "id": 1
        }, {
            "text": "Not Sure",
            "value": "Not Sure",
            "id": 3
        },
    ]

    const HighBloodPressure = [
        {
            "text": "None",
            "value": "None",
            "id": 3
        },
        {
            "text": "well controlled",
            "value": "well controlled",
            "id": 2
        }, {
            "text": "not well controlled",
            "value": "not well controlled",
            "id": 1
        },
    ]

    const Health_History = [
        {
            "text": "Patient",
            "value": "Patient",
            "id": 3
        },
        {
            "text": "Family Member",
            "value": "Family Member",
            "id": 2
        }, {
            "text": "Health Care Provider",
            "value": "Health Care Provider",
            "id": 1
        }, {
            "text": "Other (specify)",
            "value": "Other (specify)",
            "id": 1
        },
    ]

    // Patient " Family Member " Health Care Provider " Other (specify):

    return (
        <AuthDetect>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-8">
                        <div className="inner">
                            <QuestionnaireInnerV2></QuestionnaireInnerV2>
                            </div>
                            {/* <div className="inner">
                                <div className="note mb-3">Please complete the questionaire to give doctor as much informaiton as you can. you can also edit later</div>

                                <table className="table" id="questionnaireTable">
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>HEART</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td>Do you have: </td>
                                        <td style={{ width: '180px' }}>
                                            No / Yes / Not Sure
                                        </td>
                                        <td style={{ width: '180px' }}>Comments</td>
                                    </tr>
                                    <tr>
                                        <td>Any heart problem? (e.g., heart attack, murmur, angina, blockages, angioplasty, stent, valve problems, irregular
                                            heartbeat, heart surgery, heart failure).</td>
                                        <td><Select
                                            inputName={'any_heart_problem'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.any_heart_problem ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.any_heart_problem)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.biopsy_done_at}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="any_heart_problem_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.name}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>High blood pressure? </td>
                                        <td>
                                            <Select
                                                inputName={'high_blood_pressure'}
                                                options={options ?? []}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                selectedItem={formData?.high_blood_pressure ? options?.find(
                                                    (selected: any) => {
                                                        return (selected.value === formData?.high_blood_pressure)
                                                    }
                                                ) : options?.[0]}
                                                search_option={false}
                                                isLoading={true}
                                                value={formData?.high_blood_pressure}
                                                customClass={'mb-0'}
                                            ></Select></td>
                                        <td>
                                            <Select
                                                inputName={'any_heart_problem_comments'}
                                                options={HighBloodPressure ?? []}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                selectedItem={formData?.any_heart_problem_comments ? HighBloodPressure?.find(
                                                    (selected: any) => {
                                                        return (selected.value === formData?.any_heart_problem_comments)
                                                    }
                                                ) : HighBloodPressure?.[0]}
                                                search_option={false}
                                                isLoading={true}
                                                value={formData?.any_heart_problem_comments}
                                                customClass={'mb-0'}
                                            ></Select>

                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Chest pain or breathlessness after climbing 2 flights of *
                                            stairs? </td>
                                        <td><Select
                                            inputName={'breathlessness'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.breathlessness ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.breathlessness)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.breathlessness}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="breathlessness_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.breathlessness_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>A pacemaker or an implantable defibrillator? </td>
                                        <td><Select
                                            inputName={'pacemaker'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.pacemaker ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.pacemaker)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.pacemaker}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="pacemaker_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.pacemaker_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you take Aspirin (ASA) regularly?  </td>
                                        <td><Select
                                            inputName={'aspirin'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.aspirin ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.aspirin)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.aspirin}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="aspirin_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.aspirin_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>A prescription for blood thinners? (e.g., warfarin,
                                            coumadin, plavix, dabigatran, rivaroxaban) </td>
                                        <td><Select
                                            inputName={'blood_thinners'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.blood_thinners ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.blood_thinners)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.blood_thinners}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="blood_thinners_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.blood_thinners_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>An artificial heart valve?</td>
                                        <td><Select
                                            inputName={'artificial_heart_valve'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.artificial_heart_valve ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.artificial_heart_valve)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.artificial_heart_valve}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="artificial_heart_valve_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.artificial_heart_valve_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Any other artificial implants that require you to take Specify:
                                            antibiotics before surgery, or going to the dentist?
                                            e.g., knee, hip, other). </td>
                                        <td><Select
                                            inputName={'artificial_implants'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.artificial_implants ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.artificial_implants)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.artificial_implants}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="artificial_implants_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.artificial_implants_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Any other heart issues? </td>
                                        <td><Select
                                            inputName={'any_other_heart_issues'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.any_other_heart_issues ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.any_other_heart_issues)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.any_other_heart_issues}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="any_other_heart_issues_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.any_other_heart_issues_comments}
                                            />
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>BREATHING</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td>Emphysema, chronic obstructive pulmonary disease *
                                            (COPD) or chronic bronchitis? </td>
                                        <td><Select
                                            inputName={'emphysema'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.emphysema ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.emphysema)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.emphysema}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="emphysema_comments"
                                                inputType="text"
                                                placeholder="Why?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.emphysema_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Asthma?</td>
                                        <td><Select
                                            inputName={'asthma'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.asthma ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.asthma)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.asthma}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Select
                                                inputName={'asthma_comments'}
                                                options={HighBloodPressure ?? []}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                selectedItem={formData?.asthma_comments ? HighBloodPressure?.find(
                                                    (selected: any) => {
                                                        return (selected.value === formData?.asthma_comments)
                                                    }
                                                ) : HighBloodPressure?.[0]}
                                                search_option={false}
                                                isLoading={true}
                                                value={formData?.asthma_comments}
                                                customClass={'mb-0'}
                                            ></Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sleep apnea? (diagnosed by a physician) </td>
                                        <td><Select
                                            inputName={'sleep_apnea'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.sleep_apnea ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.sleep_apnea)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.sleep_apnea}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="sleep_apnea_comments"
                                                inputType="text"
                                                placeholder="Why?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.sleep_apnea_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>A problem lying flat for at least 30 minutes because of *
                                            difficulty breathing? </td>
                                        <td><Select
                                            inputName={'problem_lying'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.problem_lying ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.problem_lying)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.problem_lying}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="problem_lying_comment"
                                                inputType="text"
                                                placeholder="Why?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.problem_lying_comment}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>A breathing machine to help you sleep? </td>
                                        <td><Select
                                            inputName={'breathing_machine'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.breathing_machine ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.breathing_machine)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.breathing_machine}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="breathing_machine_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.breathing_machine_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Inhalers (puffers)?</td>
                                        <td><Select
                                            inputName={'inhalers'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.inhalers ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.inhalers)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.inhalers}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="inhalers_comments"
                                                inputType="text"
                                                placeholder=" Inhalers (puffers)? ?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.inhalers_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Oxygen at home to help you breathe?</td>
                                        <td><Select
                                            inputName={'oxygen'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.oxygen ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.oxygen)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.oxygen}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="oxygen_comments"
                                                inputType="text"
                                                placeholder="Why?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.oxygen_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> Do you smoke tobacco of any kind? (e.g., cigarettes,
                                            cigars, pipes).</td>
                                        <td><Select
                                            inputName={'cigarettes'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.cigarettes ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.cigarettes)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.cigarettes}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="cigarettes_comments"
                                                inputType="text"
                                                placeholder="Specify"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.cigarettes_comments}
                                            />
                                            <Input
                                                inputName="cigarettes_day"
                                                inputType="text"
                                                placeholder="number /day:"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.cigarettes_day}
                                            />
                                            <Input
                                                inputName="cigarettes_years"
                                                inputType="text"
                                                placeholder="number of years"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.cigarettes_years}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Have you had shortness of breath for which you have been
                                            admitted to hospital within the last 2 months? </td>
                                        <td><Select
                                            inputName={'shortness_of_breath'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.shortness_of_breath ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.shortness_of_breath)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.shortness_of_breath}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="shortness_of_breath_comments"
                                                inputType="text"
                                                placeholder="Why?"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.shortness_of_breath_comments}
                                            />
                                        </td>
                                    </tr> <tr>
                                        <td> Have you had pneumonia in the past 2 months? </td>
                                        <td><Select
                                            inputName={'pneumonia'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.pneumonia ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.pneumonia)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.pneumonia}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="pneumonia_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.pneumonia_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Do you have any other breathing issues? </td>
                                        <td><Select
                                            inputName={'other_breathing_issues'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.other_breathing_issues ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.other_breathing_issues)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.other_breathing_issues}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="other_breathing_issues_comments"
                                                inputType="text"
                                                placeholder="Why?"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.other_breathing_issues_comments}
                                            />
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>SUPER PROBLE</th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td>Do you have high sugar (diabetes),</td>
                                        <td><Select
                                            inputName={'high_sugar'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.high_sugar ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.high_sugar)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.high_sugar}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="high_sugar_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.high_sugar_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Do you take tablets or insulin ? ( give drop down box for tablets, insulin, Both, None (diet control).</td>
                                        <td><Select
                                            inputName={'insulin'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.insulin ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.insulin)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.insulin}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="insulin_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.insulin_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>When and what was your last HbA1C done ?</td>
                                        <td><Select
                                            inputName={'HbA1C'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.HbA1C ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.HbA1C)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.HbA1C}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="HbA1C_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.HbA1C_comments}
                                            />
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>BLOOD PROBLEMS </th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td>Anemia (low blood count)?</td>
                                        <td><Select
                                            inputName={'anemia'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.anemia ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.anemia)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.anemia}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="anemia_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.anemia_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>A bleeding disease or problem? </td>
                                        <td><Select
                                            inputName={'bleeding_disease'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.bleeding_disease ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.bleeding_disease)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.bleeding_disease}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="bleeding_disease_comments"
                                                inputType="text"
                                                placeholder="Specify"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.bleeding_disease_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Blood clots (in your lungs, legs or other)?</td>
                                        <td><Select
                                            inputName={'blood_clots'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.blood_clots ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.blood_clots)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.blood_clots}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="blood_clots_comments"
                                                inputType="text"
                                                placeholder="Specify"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.blood_clots_comments}
                                            />
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>NEUROLOGICAL </th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td>Memory problems or confusion? </td>
                                        <td><Select
                                            inputName={'memory_problems'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.memory_problems ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.memory_problems)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.memory_problems}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="memory_problems_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.memory_problems_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>A disease that affects your muscles and nerves? </td>
                                        <td><Select
                                            inputName={'muscles_nerves'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.muscles_nerves ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.muscles_nerves)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.muscles_nerves}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="muscles_nerves_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.muscles_nerves_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>A stroke or stroke- like symptoms in the past?</td>
                                        <td><Select
                                            inputName={'stroke_stroke'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.stroke_stroke ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.stroke_stroke)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.stroke_stroke}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="stroke_stroke_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.stroke_stroke_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Epilepsy or convulsions?</td>
                                        <td><Select
                                            inputName={'epilepsy'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.epilepsy ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.epilepsy)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.epilepsy}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="epilepsy_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.epilepsy_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Dizziness?</td>
                                        <td><Select
                                            inputName={'dizziness'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.dizziness ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.dizziness)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.dizziness}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="dizziness_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.dizziness_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Fainting spells?</td>
                                        <td><Select
                                            inputName={'fainting_spells'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.fainting_spells ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.fainting_spells)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.fainting_spells}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="fainting_spells_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.fainting_spells_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have problems with your balance?</td>
                                        <td><Select
                                            inputName={'problems_balance'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.problems_balance ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.problems_balance)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.problems_balance}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="problems_balance_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.problems_balance_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Have you had a fall in the last 3 months?</td>
                                        <td><Select
                                            inputName={'fall_last_3_months'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.fall_last_3_months ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.fall_last_3_months)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.fall_last_3_months}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="fall_last_3_months_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.fall_last_3_months_comments}
                                            />
                                        </td>
                                    </tr>
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>OTHER IMPORTANT MEDICAL INFORMATION </th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td>Do you have a chronic pain disorder?</td>
                                        <td><Select
                                            inputName={'chronic_pain_disorder'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.chronic_pain_disorder ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.chronic_pain_disorder)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.chronic_pain_disorder}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="chronic_pain_disorder_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.chronic_pain_disorder_comments}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Are you pregnant?</td>
                                        <td><Select
                                            inputName={'pregnant'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.pregnant ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.pregnant)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.pregnant}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="pregnant_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.pregnant_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Is there a possibility that you could be pregnant?</td>
                                        <td><Select
                                            inputName={'possibility_pregnant'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.possibility_pregnant ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.possibility_pregnant)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.possibility_pregnant}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="possibility_pregnant_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.possibility_pregnant_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Are you diabetic?</td>
                                        <td><Select
                                            inputName={'diabetic'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.diabetic ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.diabetic)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.diabetic}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="diabetic_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.diabetic_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Are you on dialysis?</td>
                                        <td><Select
                                            inputName={'dialysis'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.dialysis ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.dialysis)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.dialysis}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="dialysis_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.dialysis_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have kidney disease?</td>
                                        <td><Select
                                            inputName={'kidney_disease'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.kidney_disease ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.kidney_disease)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.kidney_disease}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="kidney_disease_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.kidney_disease_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have thyroid disease?</td>
                                        <td><Select
                                            inputName={'thyroid_disease'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.thyroid_disease ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.thyroid_disease)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.thyroid_disease}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="thyroid_disease_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.thyroid_disease_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have a urinary tract infection?</td>
                                        <td><Select
                                            inputName={'urinary'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.urinary ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.urinary)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.urinary}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="urinary_commnets"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.urinary_commnets}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td> Have you had an infection requiring isolation in the hospital?</td>
                                        <td><Select
                                            inputName={'isolation'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.isolation ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.isolation)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.isolation}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="isolation_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.isolation_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you currently have a cold, chest infection or fever?</td>
                                        <td><Select
                                            inputName={'chest_infection'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.chest_infection ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.chest_infection)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.chest_infection}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="chest_infection_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.chest_infection_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have liver disease?</td>
                                        <td><Select
                                            inputName={'liver_disease'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.liver_disease ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.liver_disease)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.liver_disease}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="liver_disease_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.liver_disease_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Have you had an organ transplant?</td>
                                        <td><Select
                                            inputName={'transplant'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.transplant ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.transplant)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.transplant}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="transplant_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.transplant_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have stomach ulcers, heartburn or a hiatus hernia?</td>
                                        <td><Select
                                            inputName={'stomach_ulcers'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.stomach_ulcers ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.stomach_ulcers)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.stomach_ulcers}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="stomach_ulcers_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.stomach_ulcers_comments}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Do you have arthritis?</td>
                                        <td><Select
                                            inputName={'arthritis'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.arthritis ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.arthritis)
                                                }
                                            ) : options?.[0]}
                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.arthritis}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="arthritis_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.arthritis_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you drink more than 14 alcoholic beverages per week?</td>
                                        <td><Select
                                            inputName={'alcoholic'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.alcoholic ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.alcoholic)
                                                }
                                            ) : options?.[0]}
                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.alcoholic}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="alcoholic_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.alcoholic_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have a hearing impairment or wear a hearing aid?</td>
                                        <td><Select
                                            inputName={'hearing_impairment'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.hearing_impairment ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.hearing_impairment)
                                                }
                                            ) : options?.[0]}
                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.hearing_impairment}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="hearing_impairment_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.hearing_impairment_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you have to climb stairs when you are at home?</td>
                                        <td><Select
                                            inputName={'climb_stairs'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.climb_stairs ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.climb_stairs)
                                                }
                                            ) : options?.[0]}
                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.climb_stairs}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="climb_stairs_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.climb_stairs_comments}
                                            />
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>ALLERGIES </th>
                                        </tr>
                                    </thead>
                                    
                                    <tr>
                                        <td>Eggs?</td>
                                        <td><Select
                                            inputName={'eggs'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.eggs ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.eggs)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.eggs}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="eggs_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.eggs_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Other food?</td>
                                        <td><Select
                                            inputName={'other_food'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.other_food ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.other_food)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.other_food}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="other_food_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.other_food_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Medication?</td>
                                        <td><Select
                                            inputName={'medication'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.medication ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.medication)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.medication}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="medication_comments"
                                                inputType="text"
                                                placeholder="Comments"

                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.medication_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Metal?</td>
                                        <td><Select
                                            inputName={'metal'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.metal ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.metal)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.metal}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="metal_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.metal_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Anything else?</td>
                                        <td><Select
                                            inputName={'anything_else'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.anything_else ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.anything_else)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.anything_else}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="anything_else_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.anything_else_comments}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Do you use a wheelchair, walker, cane, scooter or other aid?</td>
                                        <td><Select
                                            inputName={'wheelchair'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.wheelchair ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.wheelchair)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.wheelchair}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="wheelchair_comments"
                                                inputType="text"
                                                placeholder="Comments"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.wheelchair_comments}
                                            />
                                        </td>
                                    </tr>



                                    <thead>
                                        <tr>
                                            <th colSpan={3}>LIST ANY SURGERIES OR MINOR PROCEDURES USING ANESTHETIC YOU HAVE HAD IN THE PAST. </th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td colSpan={3}>
                                            <table className="table" style={{ width: "100%" }}>
                                                <tr>
                                                    <td>Procedure</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Input
                                                            inputName="procedure1"
                                                            inputType="textarea"
                                                            placeholder="Procedure"
                                                            onChangeSingleCallback={onChangeSingleCallback}
                                                            col="12"
                                                            isFormSubmitted={false}
                                                            value={formData?.procedure1}
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <thead>
                                        <tr>
                                            <th colSpan={3}>
                                                LIST ALL OF THE MEDICATIONS THAT YOU TAKE (INCLUDING HERBAL MEDICATION, VITAMINS, AND NON
                                                PRESCRIPTION DRUGS). ATTACH LIST IF NECESSARY
                                            </th>
                                        </tr>
                                    </thead>

                                    <tr>
                                        <td colSpan={3}>
                                            <table className="table">
                                                <tr>
                                                    <td>
                                                        <Input
                                                            inputName="medications1"
                                                            inputType="text"
                                                            onChangeSingleCallback={onChangeSingleCallback}
                                                            col="12"
                                                            isFormSubmitted={false}
                                                            value={formData?.medications1}
                                                        /></td>
                                                    <td><Input
                                                        inputName="medications2"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications2}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications3"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications3}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications4"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications4}
                                                    /></td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Input
                                                            inputName="medications5"
                                                            inputType="text"
                                                            onChangeSingleCallback={onChangeSingleCallback}
                                                            col="12"
                                                            isFormSubmitted={false}
                                                            value={formData?.medications5}
                                                        /></td>
                                                    <td><Input
                                                        inputName="medications6"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications6}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications7"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications7}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications8"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications8}
                                                    /></td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Input
                                                            inputName="medications9"
                                                            inputType="text"
                                                            onChangeSingleCallback={onChangeSingleCallback}
                                                            col="12"
                                                            isFormSubmitted={false}
                                                            value={formData?.medications9}
                                                        /></td>
                                                    <td><Input
                                                        inputName="medications10"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications10}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications11"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications11}
                                                    /></td>
                                                    <td><Input
                                                        inputName="medications12"
                                                        inputType="text"
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        isFormSubmitted={false}
                                                        value={formData?.medications12}
                                                    /></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Do you have any other illness, limitations or any other concerns we should know about?</td>
                                        <td><Select
                                            inputName={'any_heart_problem'}
                                            options={options ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.any_heart_problem ? options?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.any_heart_problem)
                                                }
                                            ) : options?.[0]}

                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.biopsy_done_at}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            <Input
                                                inputName="any_heart_problem1"
                                                inputType="text"
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={false}
                                                value={formData?.name}
                                            />
                                        </td>
                                    </tr>



                                    <tr>
                                        <td>Patient Health History Questionnaire completed by:</td>
                                        <td><Select
                                            inputName={'health_history_questionnaire'}
                                            options={Health_History ?? []}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            selectedItem={formData?.health_history_questionnaire ? Health_History?.find(
                                                (selected: any) => {
                                                    return (selected.value === formData?.health_history_questionnaire)
                                                }
                                            ) : Health_History?.[0]}
                                            search_option={false}
                                            isLoading={true}
                                            value={formData?.health_history_questionnaire}
                                            customClass={'mb-0'}
                                        ></Select></td>
                                        <td>
                                            {formData?.health_history_questionnaire === "Other (specify)" ?
                                                <Input
                                                    inputName="any_heart_problem1"
                                                    inputType="text"
                                                    placeholder="Name"
                                                    onChangeSingleCallback={onChangeSingleCallback}
                                                    col="12"
                                                    isFormSubmitted={false}
                                                    value={formData?.name}
                                                />
                                                : ''}
                                        </td>
                                    </tr>
                                </table>



                                <div className="navigationBar">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <NavLink className={'btn'} to={'/family-details'}>Back to Member</NavLink>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <Button onClick={() => Submit()}>Submit &#38; Next</Button>
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                        <div className="col-md-4">
                            <PlanDetails></PlanDetails>
                        </div>
                    </div>
                </div>

            </div>
        </AuthDetect>
    );
};
export default Questionnaire;
