import Calendar from 'rc-year-calendar';

const CalenderWrapper = ({
    minDate,
    disabledWeekDays,
    onRangeSelected,
    onYearChanged,
    data,
    isLoading,
    disabledDaysData
}) => {
    return (
        <>
            {isLoading ? 'Loading ...' :
                <Calendar
                    {...(minDate ? minDate = { minDate } : {})}
                    allowOverlap={true}
                    disabledWeekDays={disabledWeekDays}
                    enableRangeSelection={true}
                    enableContextMenu={true}
                    displayDisabledDataSource={true}
                    onRangeSelected={(e) => onRangeSelected(e)}
                    onYearChanged={(e) => onYearChanged(e)}
                    disabledDays={disabledDaysData}
                    dataSource={data}
                />
            }
        </>
    )
}

export default CalenderWrapper