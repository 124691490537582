/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input } from "../../Library/Module";
import Wrapper from "../Wrapper";
import { toast } from "react-toastify";
import { EnquiryDetails } from "../../servies/services";
import { mainAddress } from "../../Library/Utility/Utility";

type formDataProps = {
    email_id: string;
    name: string;
    mobile?: string;
    comments?: string;
};

const ContactUs = () => {
    let { id, type, action } = useParams();
    const [isLoading, setIsLoading]: any = useState(false);
    const [data, setData]: any = useState(false);
    const [isError, setIsError] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        name: '',
        email_id: '',
        mobile: '',
        comments: ""
    });

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    // useEffect(() => {
    //     setIsLoading(false);
    //     const getData = async () => {
    //         const APIResponse = await CMSBySlug('contact-us');
    //         if (APIResponse?.data?.isSuccess === true) {
    //             setData(APIResponse?.data?.data);
    //             setIsLoading(true);
    //         } else {
    //             toast.error("Something went wrong, please try again");
    //             setIsError(true);
    //             setIsLoading(true);
    //         }
    //     };
    //     getData();
    // }, []);

    useEffect(() => {
        if (formData?.name && formData?.name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    const Submit = async () => {
        const APIResponse = await EnquiryDetails(formData);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success('Your enquiry has been submitted successfully.', {
                position: toast.POSITION.TOP_CENTER
            });

            setFormData({
                name: '',
                email_id: '',
                mobile: '',
                comments: ""
            });
        } else {
            toast.error("Something went wrong, please try again");
            setIsError(true);
            setIsLoading(true);
        }
    }

    return (
        <Wrapper pageTitle="Contact Us" pageDes={`We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.`}>
            <div className="pageSection">
                <div className="aboutUs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="contactUsDetails">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="innerContactdetails">
                                                <h1>Contact Information</h1>
                                                <p>Let's get this conversation started, Tell us bit about yourself and we'll get in touch as soon as we can. </p>
                                                <ul>
                                                    <li><span className="material-symbols-outlined"> mail </span> <span>info@dranandjoshi.com </span></li>
                                                    <li><span className="material-symbols-outlined"> call </span> <span>+91 98660 35911</span> </li>
                                                    <li><span className="material-symbols-outlined"> pin_drop </span> <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span> </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-sm-12 col-md-6">
                                <div className="contact-form">
                                    <h3>Get in touch with us</h3>
                                    <p>Let's get this conversation started, Tell us bit about yourself and we'll get in touch as soon as we can.</p>
                                    <Input
                                        inputName="name"
                                        inputType="text"
                                        labelName={"Full Name"}
                                        placeholder="Enter Full Name"
                                        required={true}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        value={formData?.name}
                                    />
                                    <Input
                                        inputName="email_id"
                                        inputType="email"
                                        labelName={"Email ID"}
                                        placeholder="Enter Email ID"
                                        required={true}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        value={formData?.email_id}
                                    />
                                    <Input
                                        inputName="mobile"
                                        inputType="text"
                                        labelName={"Phone Number"}
                                        placeholder="Enter Phone Number"
                                        required={false}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        value={formData?.mobile}
                                    />
                                    <Input
                                        inputName="comments"
                                        inputType="textarea"
                                        labelName={"Comment"}
                                        placeholder="Enter Comment"
                                        required={false}
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={isFormSubmitted}
                                        value={formData?.comments}
                                    />
                                    <Button
                                        onClick={() => Submit()}
                                        buttonText={"Submit"}
                                        buttonStyleType={"primary"}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <iframe className="display-block border" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.818230586071!2d78.36789307516683!3d17.468411983432652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9232ce327fdd%3A0x19b2565b2880669f!2sSolitaire%20Apartment!5e0!3m2!1sen!2sin!4v1705623364922!5m2!1sen!2sin" width="100%" height="450" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default ContactUs;
