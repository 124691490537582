import React, { Component, useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { Button } from "../Library/Module";
import { Animated } from "react-animated-css";
import SliderImage1 from '../images/banner2.jpg'
import SliderImage2 from '../images/slider2.jpg'
import SliderImage3 from '../images/slider3.jpg'
import SliderImage4 from '../images/slider4.jpg'
import SliderImage5 from '../images/slider5.jpg'
import SliderImage6 from '../images/slider6.jpg'
import { CMSByType } from "../servies/services";
import { toast } from "react-toastify";
import { ThemeContext } from "../Context/Theme/Context";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const SimpleSlider = () => {
    return (
        <Slider {...settings}>
            <div className="sliderMain">
               
            </div>
            <div className="sliderMain2">
               
            </div>
        </Slider>
    );
}

export default SimpleSlider