import React, { Component, useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { Button } from "../Library/Module";
import { Animated } from "react-animated-css";
import SliderImage1 from '../images/slider1.jpg'
import SliderImage2 from '../images/slider2.jpg'
import SliderImage3 from '../images/slider3.jpg'
import SliderImage4 from '../images/slider4.jpg'
import SliderImage5 from '../images/slider5.jpg'
import SliderImage6 from '../images/slider6.jpg'
import { CMSByType } from "../servies/services";
import { toast } from "react-toastify";
import { ThemeContext } from "../Context/Theme/Context";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const TestimonialsSlider = () => {
    const { testimonilasList } = useContext(ThemeContext)
    return (
        <div className="sliderMain2">
            <Slider {...settings}>
                {testimonilasList?.slice(0,5)?.map((item: any) => {
                    return (
                        <div className="testimonailsListItem">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <h1>{item.title}</h1>
                                        <div className="content" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    </div>
                                    <div className="col-sm-4"> <div className="picture">
                                        {item?.image ? <img src={process.env.react_app_base_url + "/" + item?.image} alt="" title="" /> :
                                            item.title.substring(0, 1)}
                                    </div></div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>

    );
}

export default TestimonialsSlider