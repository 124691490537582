/* eslint-disable no-empty-pattern */
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetFamily, GetFamilyDetailById, GetFamilyDetailByUserId, GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Card, CustomTable, SideDrawer } from "../../Library/Module";
import { theme } from "../../theme";
import Login from "../Login/Login";
import LoginForm from "../Login/LoginForm";
import TabComp from "../../Library/Tab/Tab";
import RegisterForm from "../Register/RegisterForm";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'
import Row from "./Row";
import { objectToQueryString } from "../../Library/Utility/Utility";
import AddFamilyForm from "./AddFamilyForm";
import PlanDetails from "../../components/PlanDetails";
import AuthDetect from "../MyAccount/AuthDetect";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "select",
            display: "Select Patient",
            sort: false,
        },
        {
            name: "name",
            display: "Name",
            sort: true,
        },
        {
            name: "age",
            display: "Age",
            sort: true,
        },
        {
            name: "gender",
            display: "Gender",
            sort: true,
        },

        {
            name: "relation",
            display: "Relation",
            sort: true,
        },
        {
            name: "created_on",
            display: "Added Date",
            sort: true,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};

const AddFamily2 = (): JSX.Element => {
    const navigate = useNavigate()
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});
    const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();

    const pageData = 'Members'
    const pageDescData = 'You can manage the Members in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {


        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })

        setTableDataCalendarSingle([]);
        const getData2 = async () => {
            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";
            const apidata = await GetFamily(user_details_frontend?.id);
            const APIResponse = apidata?.data?.data;
            setTableDataCalendarSingle(APIResponse);
        };
        getData2();
    }

    const showCellCallback = () => {

    }

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
    console.log("user_details_frontend", user_details_frontend)

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetFamily(user_details_frontend?.id);
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI, user_details_frontend?.id]);

    const AddMember = () => {
        setActionType({
            type: "add",
            id: 'id',

        })

    }
    const CloseDrawer = () => {
        setActionType({});
    };

    const FormSubmission = (data: boolean) => {
        setActionType({});
        setReloadAPI(true);
    }

    const Next = () => {
        navigate('/problem')
    }

    const [selectedPatientId, setSelectedPatientId] = useState('')

    return (
        <AuthDetect>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="inner">

                                <CustomTable
                                    showHeader={true}
                                    header={tableConfig.tableHeader}
                                    sortDefault={tableConfig.sortDefault}
                                    data={tableData}
                                    tableTitle={pageData}
                                    tableSubTitle={pageDescData}
                                    dropdown={""}
                                    search_bar={false}
                                    showCellCallback={showCellCallback}
                                    sortState={sortState}
                                    isLoading={isLoading}
                                    headerAction={<><Button onClick={() => AddMember()} className="btn btn-primary">Add Member</Button></>}
                                >
                                    {({ rowData, index }: { rowData: any; index: any }) => (
                                        <Row
                                            rowData={rowData}
                                            index={index}
                                            showCells={showCells}
                                            reloadData={reloadData}
                                            callbackId={callbackFresh}
                                            selectedPatient={setSelectedPatientId}
                                            selectedPatientId={selectedPatientId}
                                        ></Row>
                                    )}
                                </CustomTable>
                                <div className="navigationBar">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <NavLink className={'btn'} to={'/availability'}>Back to Date</NavLink>
                                        </div>
                                        <div className="col-md-6">
                                        <Button className="float-right" disabled={!selectedPatientId} onClick={() => Next()}>Next</Button>
                                        </div>
                                    </div>
                                </div>

                                
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <PlanDetails></PlanDetails>
                        </div>
                    </div>
                </div>
                {(actionType?.type === "add" || actionType?.type === "edit") && (
                    <SideDrawer
                        size={'400px'}
                        pagetitle={`Members`}
                        action={CloseDrawer}
                    >
                        <AddFamilyForm reloadAPI={FormSubmission}></AddFamilyForm>
                    </SideDrawer>
                )}
            </div>
        </AuthDetect>
    );
};
export default AddFamily2;
