/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { UserById, authenticate } from "../../servies/services";
import Checkboxes from "../../Library/Checkbox/Checkboxes";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import './login.css'
import { theme, variables } from "../../theme";
import { isPossiblePhoneNumber } from 'react-phone-number-input'

type formDataProps = {
    user_name: string;
    password: string;
    remeber_me: string;
};

const LoginForm = () => {
    const navigate = useNavigate();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };
    const [userType, setUserType] = useState('mobile')

    const onValueChange = (value: string) => {
        return setUserType(value)
    }

    const Submit = async (event: any) => {


        if (formData?.remeber_me === 'true') {
            const details = {
                "user_name": formData?.user_name,
                "password": formData?.password,
            }
            localStorage.setItem("login_details_frontend", JSON.stringify(details))
        }

        setIsFormSubmitted(true);
        if (
            formData?.user_name !== undefined &&
            formData?.user_name.length !== 0 &&
            formData?.password !== undefined &&
            formData?.password.length !== 0
        ) {

            let login_type = "user_name"
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const hasNumbers = (t: any) => {
                return /^\d+$/.test(t);
            }


            if (formData?.user_name.match(validRegex)) {
                login_type = "email_id"
            }
            if (isPossiblePhoneNumber(formData?.user_name) === true) {
                login_type = "mobile"
            }
            setIsValid(true);
            const postData = {
                user_name: formData?.user_name,
                password: formData?.password,
                user_type: "patient",
                login_type: login_type
            };

            const APIResponse = await authenticate(postData);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success("User logged succesfully");
                localStorage.setItem("user_details_frontend", JSON.stringify(APIResponse?.data));
                if (APIResponse?.data?.id) {
                    getProfileDetails(APIResponse?.data?.id);
                } else {
                    navigate("/my-account/my-appointment/dashboard");
                }
            } else {
                toast.error(APIResponse?.data?.messages);
                setIsError(true);
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const getProfileDetails = async (data: any) => {
        const APIResponse = await UserById(data);
        console.log("CreateMedia", APIResponse);
        if (APIResponse !== null) {
            localStorage.setItem(
                "profile_details_frontend",
                JSON.stringify(APIResponse?.data?.data)
            );
            // toast.success(APIResponse.data.messages);
            navigate("/my-account/my-appointment/dashboard");
        } else {
            toast.error("Something went wrong, please try again");
        }
    };

    const ForgotPassword = () => {
        navigate("/forgot-password");
    };
    useEffect(() => {
        if (isPossiblePhoneNumber(formData?.user_name.toString() ?? '') === true) {
            console.log("isPossiblePhoneNumber", "yes")
        }
    }, [formData?.user_name])
    return (
        <div className="login_admin2">
            <Card>
                {/* <div className="logoPlaceholder">
                    <img src={Logo} alt="" title="" />
                </div> */}
                <h3>Welcome Back !</h3>
                <p className="text-center mb-4">
                    Sign in to continue to {variables?.site_title}
                </p>
                <div className="container-fluid">
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    <div className="row">
                        <p><label>Select Username type </label>
                            <div className="mb-3 col-md-12">
                                <span className="mr-3">
                                    <input
                                        type="radio"
                                        value='mobile'
                                        checked={userType === "mobile"}
                                        onChange={(e) => onValueChange(e.target.value)}
                                        name="user_type"
                                    /> <label className="inline" onClick={() => onValueChange("mobile")}>Using Mobile number</label>
                                </span>
                                <input
                                    type="radio"
                                    value='user_name'
                                    checked={userType === "user_name"}
                                    onChange={(e) => onValueChange(e.target.value)}
                                    name="user_type"
                                /><label className="inline" onClick={() => onValueChange("user_name")}>Using Email ID or Username</label>
                            </div>
                        </p>

                        {userType === "user_name" ?
                            <Input
                                inputName="user_name"
                                inputType="text"
                                labelName={"Email ID / Username"}
                                placeholder="Enter Email ID / Username"
                                required={true}
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="12"
                                isFormSubmitted={isFormSubmitted}
                                value={formData?.user_name}
                            /> :
                            <Input
                                inputName="user_name"
                                inputType="mobile"
                                labelName={"Mobile"}
                                placeholder="Enter Mobile Number"
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="12"
                                isFormSubmitted={isFormSubmitted}
                                value={formData?.user_name}
                            />
                        }
                        <Input
                            inputName="password"
                            inputType="password"
                            labelName={"Password"}
                            placeholder="Enter Password"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.password}
                        />
                        <div className="float-left width-auto">
                            <CustomTooltip
                                title={"Remember Me"}
                                position={"bottom"}
                                disabled={false}
                                content={`Don't turn on this on public computer`}>
                                <Checkboxes
                                    inputName={"remeber_me"}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    options={[{ text: "Remember Me", id: 1, value: "true" }]}
                                    selectedItem={{ text: "Remember Me", id: 1, value: "true" }}
                                >
                                </Checkboxes>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="row align-content-end justify-content-end">
                        <div className="col-sm-6 text-left">
                            <NavLink to="/forgot-password">Forgot Password</NavLink>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Button
                                onClick={() => Submit("")}
                                buttonText={"Login"}
                                buttonStyleType={"primary"}
                                theme={theme}
                            >
                                <span className="material-symbols-outlined">
                                    login
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};
export default LoginForm;
