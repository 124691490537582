import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert, Tag } from "../../Library/Module";
import { DeleteBookAppointment, DeleteCityService, DeleteFamily } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";




import { getColorCode, GetTimeFrame } from "../../Library/Utility/Utility";
import { confirmAlert } from "react-confirm-alert";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: any;
}

const FamilyDetailsRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId
}: AlertProps): JSX.Element => {
    const navigate = useNavigate();

    const editPage = () => {
        localStorage.setItem('family_details', JSON.stringify(rowData))
        navigate(`/my-account/questionnaire`);
    };

    const DeleteCity = () => {
        DeleteCityAPI()
        // confirmAlert({
        //   title: `Are you sure you want to delete this ${rowData.title}?`,
        //   message: `This Item will be deleted immediately. You can't undo this action.`,
        //   buttons: [
        //     {
        //       label: "Yes",
        //       onClick: () => DeleteCityAPI(),
        //     },
        //     {
        //       label: "No",
        //       onClick: () => "",
        //     },
        //   ],
        // });
    };

    const DeleteCityAPI = async () => {
        const APIResponse = await DeleteFamily(rowData._id);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success(APIResponse?.data?.messages);
            reloadData(true);
        } else {
            toast.error("Something went wrong, please try again");
        }
    };


    return (
        <>
            <tr key={index}>
                <td>{rowData.name}</td>
                <td>{rowData?.age}</td>
                <td> {rowData?.gender}</td>
                <td>{rowData.relation}</td>
                <td>{moment(rowData?.created_on).format("DD/MM/YYYY")}</td>
                <td><Button
                            onClick={() => editPage()}
                            buttonStyleOutline
                            buttonStyleType="danger"
                        >
                            <span className="material-symbols-outlined">
                                visibility
                            </span>
                        </Button></td>
                <td>
                    <div className="flex">
                        
                        <Button
                            onClick={() => DeleteCity()}
                            buttonStyleOutline
                            buttonStyleType="danger"
                        >
                            <span className="material-symbols-outlined">
                                delete
                            </span>
                        </Button>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default FamilyDetailsRow;
