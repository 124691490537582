import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
const TestimonialsInner = () => {
    const { testimonilasList } = useContext(ThemeContext)
    return (
        <div className="pageSection">
            <div className="aboutUs">
                <div className="container">
                    <div className="row testimonilasList">
                        {testimonilasList?.map((test: any) => {
                            return (
                                <>
                                    <div className="col-sm-4 mb-4">
                                        <div className="inner">
                                            <div className="content" dangerouslySetInnerHTML={{ __html: test.description }}></div>
                                            <div className="picture">
                                                {test?.image ? <img src={process.env.react_app_base_url + "/" + test?.image} alt="" title="" /> :
                                                    test.title.substring(0, 1)}
                                            </div>
                                            <p className="text-center"> {test.title}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
export default TestimonialsInner;