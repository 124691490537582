import React, { Component, useEffect, useState } from "react";
import { validateEmail, validateUrl } from "../Utility/Utility";
import CustomTooltip from "../Tooltip/Tippy";

import 'react-phone-number-input/style.css'



interface InputProps {
    inputText?: string | null;
    inputType?: "text" | "number" | "email" | "password" | "url" | "textarea" | "mobile";
    className?: string;
    icon?: string;
    children?: any;
    placeholder?: string;
    inputName: string;
    labelName?: string;
    inputSubType?: "incrementer";
    id?: string;
    value?: string | number | undefined;
    required?: boolean;
    min?: number;
    max?: number;
    maxLength?: number;
    minLength?: number;
    disabled?: boolean;
    customValidationMsg?: string;
    onChangeFileCallback?: any;
    onChangeFileSingleCallback?: any;
    suffix?: string;
    col?: "1" | "2" | "3" | "4" | "5" | "6" | "9" | "10" | "11" | "12";
    isFormSubmitted?: boolean;
    readonly?: boolean;
    tooltipInfo?: any
    multiple?: boolean;
}
const InputFile = ({
    inputText = null,
    inputType = "text",
    className,
    children,
    icon,
    placeholder = "",
    inputName,
    labelName,
    id,
    value,
    required = false,
    min,
    max,
    maxLength,
    minLength,
    disabled,
    customValidationMsg,
    onChangeFileCallback,
    onChangeFileSingleCallback,
    suffix,
    col = "12",
    inputSubType,
    isFormSubmitted = false,
    readonly = false,
    tooltipInfo,
    multiple = true
}: InputProps): JSX.Element => {
    const [file, setFile] = useState<any>(null);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            console.log("e.target.files", e.target.files)
            setFile(e.target.files);
            let inputData = {
                key: inputName,
                value: e.target.files,
            };
            let single = { [inputName]: e.target.files };
            onChangeFileCallback?.(inputData);
            onChangeFileSingleCallback?.(single);
        }
    }

    return (
        <div className={`mb-3 col-md-${col}`}>
            {labelName && (
                <label>
                    {labelName} {required ? <span className="red">*</span> : ""}
                    {tooltipInfo &&
                        <CustomTooltip
                            title={"Patient Details"}
                            position={"bottom"}
                            content={
                                tooltipInfo
                            }>
                            <span className="material-symbols-outlined">
                                info
                            </span>
                        </CustomTooltip>
                    }
                </label>
            )}

            <input type="file"
                id={id}
                onChange={handleFileChange}
                required={required}
                name=""
                className={`form-control`}
                disabled={disabled}
                readOnly={readonly}
                {...(multiple ? { multiple: true } : {})}
                accept=".pdf,.doc,.docx,image/*"

            />
            {children && children}
        </div>
    );
};

export default InputFile;
