/* eslint-disable no-empty-pattern */
import { useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { useEffect } from "react";

type MyAccountWrapperProps = {
    children: any
}
const AuthDetect = ({
    children
}: MyAccountWrapperProps): JSX.Element => {

    const navigate = useNavigate()

    useEffect(() => {
        // @ts-ignore
        const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";
        
        if (user_details_frontend?.token) {
            console.log("user_details_frontend", user_details_frontend)
        } else {
            navigate('/my-account')
        }
    }, [navigate])

    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};
export default AuthDetect;
