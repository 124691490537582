import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert, Tag } from "../../Library/Module";
import { DeleteBookAppointment, DeleteCityService, DeleteFamily, GetFamilyDetailById } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { getColorCode, GetTimeFrame, objectToQueryString } from "../../Library/Utility/Utility";
import CustomTooltip from "../../Library/Tooltip/Tippy";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: any;
}

const MyAppointmentRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId
}: AlertProps): JSX.Element => {
    const navigate = useNavigate()
    const getMember = (member_id: any) => {
        const getData2 = async () => {
            const apidata = await GetFamilyDetailById(member_id);
            const APIResponse = apidata?.data?.data;
            console.log("APIResponse", APIResponse)
            localStorage.setItem('family_details', JSON.stringify(APIResponse))
            navigate('/my-account/questionnaire')
        };
        getData2();
    }
    return (
        <>
            <tr key={index}>
                <td>{index + 1}</td>
                <td>
                    <p>{rowData.service_type}</p>
                    <p className="fs-sm fc-grey" dangerouslySetInnerHTML={{ __html: rowData.plan_name }}></p>
                </td>
                {/* <td></td> */}
                <td>{rowData.appointment_date_time}</td>
                <td>
                    {/* {JSON.stringify(rowData?.details?.)} */}
                    <CustomTooltip
                        title={"title"}
                        position={"bottom"}
                        content={<>
                            <p>Name - {rowData?.details?.patient_name}</p>
                            <p>Age - {rowData?.details?.patient_age}</p>
                            <p>Gender - {rowData?.details?.patient_gender}</p>
                            <p>Relation  - {rowData?.details?.patient_relation}</p>
                            <p>Other Details  {rowData?.details?.other_details}</p>
                        </>}>
                        {rowData?.details?.patient_name}
                    </CustomTooltip></td>
                <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
                <td>
                    {rowData.service_type === "Online Meeting" ?
                        rowData?.meeting_type !== "WhatsApp video call" && rowData?.meeting_start_url ? <a target="_blank" href={rowData?.meeting_start_url} rel="noreferrer">Meeting Link</a> :
                            <a href="https://api.whatsapp.com/send?phone=919866035911">WhatsApp Call</a> :
                        rowData.service_type === "In-person Meeting" ? 'In-person Meeting' : ''}
                </td>
                <td><Button onClick={() => getMember(rowData?.member_id)}>View</Button></td>
                <td><Button onClick={() => callbackId(rowData)}>View</Button></td>
                {/* <td>{rowData.created_by}</td> */}
                {/* <td>
                    <div className="flex">
                        <Button
                            onClick={() => ReSchedule()}
                            buttonStyleOutline
                            buttonStyleType="danger"
                        >
                            <span className="material-symbols-outlined">
                                change_circle
                            </span>
                        </Button>
                    </div>
                </td> */}

            </tr>
        </>
    );
};

export default MyAppointmentRow;
