import react from "react";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../Library/Tooltip/Tippy";
import { Button } from "../../../Library/Module";


interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: (data: any) => void;
    selectedAction?: (data: any) => void;
}

const PathologyRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId,
    selectedAction
}: AlertProps): JSX.Element => {

    const navigate = useNavigate();

    const viewPageQuestionnaire = () => {
        navigate(`/appointments-management/view-appointments/questionnaire/${rowData._id}`);
    };

    const viewPageProblem = () => {
        navigate(`/appointments-management/view-appointments/${rowData.member_id}/${rowData._id}`);
    };

    const files = typeof rowData?.details?.file_path === 'object' ? rowData?.details?.file_path && rowData?.details?.file_path?.map((item: any) => {
        console.log("item", item)
        return <li><a className="mr-2" href={process.env.react_app_base_url + "/" + item}>Download</a></li>
    }) : ''
    console.log("files", typeof rowData?.details?.file_path, rowData?.details?.file_path)

    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <p>Name - {rowData?.details?.full_name}</p>
                        <p>City - {rowData?.details?.city}</p>
                        <p>Mobile - {rowData?.details?.mobile}</p>
                        <p>Email Id  - {rowData?.details?.email_Id}</p>
                    </>}>
                    {rowData?.details?.full_name}
                </CustomTooltip>
            </td>
            {/* <td>{rowData?.details?.city}</td>
            <td>{rowData?.details?.mobile}</td>
            <td>{rowData?.details?.email_Id}</td> */}
            <td>{rowData?.details?.biopsy}</td>
            <td>{rowData?.details?.biopsy_done_at}</td>
            <td>{rowData?.details?.specimen}</td>
            <td>{rowData?.details?.slides}</td>
            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <h5>Download</h5>
                        <ul className="listItem">
                            {files}
                        </ul>
                    </>}>
                    Download
                </CustomTooltip>
            </td>
            <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
            <td>{rowData.created_by}</td>
            <td><Button onClick={() => callbackId?.(rowData)}>Doctor's Opinion</Button></td>

        </tr>
    );
};

export default PathologyRow;
