import React from "react";
import Wrapper from "../Wrapper";
import { mainAddress } from "../../Library/Utility/Utility";

const TermOfUse = () => {
    return (
        <Wrapper
            pageDes={`Welcome toDr Anand Joshi's website ("Website"). By accessing or using this Website, you agree to be bound by these Terms and Conditions ("Terms"). Please review these Terms carefully before using the Website.`}
            pageTitle="terms of use">
            <div className="sectionInner">
                <div className="container pt-4 pb-4">
                    <div className="row align-items-center justify-content-center pb-3 pt-3">
                        <div className="col-md-12 col-sm-12 cms_pages">
                            <p>Welcome to Dr Anand Joshi's website ("Website"). By accessing or using this Website, you agree to be bound by these Terms and Conditions ("Terms"). Please review these Terms carefully before using the Website.</p>
                            <h5>1. Use of Website:</h5>
                            <ul>
                                <li>You may use the Website for informational purposes only.</li>
                                <li>You may not use the Website for any illegal or unauthorized purpose, including but not limited to:</li>
                                <li>Violating any laws or regulations.</li>
                                <li>Infringing on any third-party's intellectual property rights.</li>
                                <li>Transmitting any harmful or malicious content.</li>
                                <li>Interfering with the operation of the Website.</li>
                                <li>You are responsible for ensuring that any information you provide through the Website is accurate and complete.</li>
                            </ul>
                            <h5>2. Medical Disclaimer:</h5>
                            <p>The information provided on this Website is intended for informational purposes only and should not be considered medical advice. You should always consult with a qualified healthcare professional for diagnosis and treatment of any medical condition.</p>
                            <p>Reliance on the information provided on this Website is solely at your own risk. We assume no responsibility for any consequences arising from your use of the information on this Website.</p>
                            <h5>3. Intellectual Property:</h5>
                            <p>All content on this Website, including text, graphics, logos, images, and software, is protected by copyright and other intellectual property laws. You may not use any content from this Website without our express written permission.</p>
                            <p>You may print or download content from this Website for your personal, non-commercial use only. Any other use of the content is strictly prohibited.</p>
                            <h5>4. User Accounts:</h5>
                            <p>If you are required to create an account to access certain features of the Website, you are responsible for maintaining the confidentiality of your account information and password. You are also responsible for all activity that occurs under your account.</p>
                            <p>We reserve the right to terminate your account at any time, for any reason, without notice.</p>
                            <h5>5. Links to Other Websites:</h5>
                            <p>This Website may contain links to other websites. We are not responsible for the content of any linked websites and we do not endorse any third-party products or services.</p>
                            <h5>6. Disclaimers:</h5>
                            <p>The Website is provided "as is" and without any warranties of any kind, express or implied. We disclaim all warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                            <p>We do not warrant that the Website will be uninterrupted or error-free, or that the Website will be secure.</p>
                            <h5>7. Limitation of Liability:</h5>
                            <p>We will not be liable for any damages arising out of your use of the Website, including but not limited to, direct, indirect, incidental, consequential, and punitive damages.</p>
                            <h5>8. Governing Law:</h5>
                            <p>These Terms will be governed by and construed in accordance with the laws of Maharashtra/India.</p>
                            <h5>9. Amendments:</h5>
                            <p>We reserve the right to amend these Terms at any time. We will post any changes on the Website. You are responsible for reviewing the Terms periodically. Your continued use of the Website after the posting of any changes constitutes your acceptance of the amended Terms.</p>
                            <h5>10. Contact Us:</h5>
                            <p>If you have any questions about these Terms, please contact us at info@dranandjoshi.com or +91 98660 35911.</p>
                            <h5>Additional Considerations:</h5>
                            <ul>
                                <li>You may need to add specific sections depending on your practice and jurisdiction, such as:</li>
                                <li>Payment terms and conditions if you offer paid services through the Website.</li>
                                <li>Cancellation policy for appointments.</li>
                                <li>Dispute resolution procedures.</li>
                                <li>Minimum age requirement for using the Website.</li>
                                <li>It is recommended to consult with a lawyer to ensure your Terms and Conditions comply with all applicable laws and regulations.</li>
                            </ul>

                            <div className="innerContactdetails">
                                <ul>
                                    <li><span className="material-symbols-outlined"> mail </span> <span>info@dranandjoshi.com </span></li>
                                    <li><span className="material-symbols-outlined"> call </span> <span>+91 98660 35911</span> </li>
                                    <li><span className="material-symbols-outlined"> pin_drop </span> <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>

    );
};
export default TermOfUse;


