/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Card, Input, Button, Select } from "../../Library/Module";
import { toast } from "react-toastify";
import { AddFamilyMember } from "../../servies/services";
import { theme } from "../../theme";
type formDataProps = {
    name: string;
    age: string;
    gender: string;
    status: string;
    relation: string;
    created_by: string;
    user: string;
    user_name?: string;
    user_id?: string;
    address?: string;
};

const genderOptions = [
    {
        "text": "Male",
        "value": "male",
        "id": 1
    }, {
        "text": "Female",
        "value": "female",
        "id": 2
    }, {
        "text": "Other",
        "value": "other",
        "id": 3
    },
]

const statusOptions = [{
    "text": "Active",
    "value": "active",
    "id": 1
},
{
    "text": "Inactive",
    "value": "inactive",
    "id": 2
}]

type props = {
    reloadAPI: (data: boolean) => void
}

const AddFamilyForm = ({
    reloadAPI
}: props) => {


    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [refreshPage, setRefreshPage] = useState<boolean>(false);

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    // const login_details_frontend = localStorage.getItem("login_details_frontend")
    // useEffect(() => {
    //     console.log("login_details_frontend", login_details_frontend)
    //     if (login_details_frontend) {
    //         setFormData(JSON.parse(login_details_frontend))
    //     }
    // }, [login_details_frontend])

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
    console.log("user_details_frontend", user_details_frontend)

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.name !== undefined &&
            formData?.name.length !== 0 &&
            formData?.age !== undefined &&
            formData?.age.length !== 0 &&
            formData?.gender !== undefined &&
            formData?.gender.length !== 0 &&
            formData?.relation !== undefined &&
            formData?.relation.length !== 0
        ) {
            setIsValid(true);
            formData.user = user_details_frontend?.user_name
            formData.status = "active"
            formData.created_by = user_details_frontend?.user_name
            formData.user_id = user_details_frontend?.id
            delete formData.user_name
            const APIResponse = await AddFamilyMember(formData);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success("Family member added successfully.");
            } else {
                toast.error("Something went wrong, please try again");
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
        reloadAPI(true)
    };

    return (
        <Card>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            inputName="name"
                            inputType="text"
                            labelName={"Name"}
                            placeholder="Name"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.name}
                        />
                        <Input
                            inputName="age"
                            inputType="text"
                            labelName={"Age"}
                            placeholder="Enter Age"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.age}
                        />
                        <Select
                            inputName={'gender'}
                            labelName={'Gender'}
                            options={genderOptions ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            selectedItem={undefined}
                            required={true}
                            placeholder={'Select Gender'}
                            search_option={false}
                            isLoading={true}
                            value={formData?.gender}
                        ></Select>
                        {/* <Select
                            inputName={'status'}
                            labelName={'Status'}
                            options={statusOptions ?? []}
                            onChangeSingleCallback={onChangeSingleCallback}
                            selectedItem={undefined}
                            required={true}
                            placeholder={'Select status'}
                            search_option={false}
                            isLoading={true}
                            value={formData?.status}
                        ></Select> */}

                        <Input
                            inputName="relation"
                            inputType="text"
                            labelName={"Relation"}
                            placeholder="Relation"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.relation}
                        />

                        <Input
                            inputName="address"
                            inputType="text"
                            labelName={"Address"}
                            placeholder="Address"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.address}
                            tooltipInfo={'Address is used for home visit service.'}
                        />
                    </div>
                </div>
                <div className="row align-content-end justify-content-end">
                    <div className="col-sm-6 text-right">
                        <Button
                            onClick={() => Submit("")}
                            buttonText={"Submit"}
                            buttonStyleType={"primary"}
                            theme={theme}
                        >
                        </Button>
                    </div>
                </div>
            </div>
        </Card>

    );
};
export default AddFamilyForm;
