import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
// import { GetScheduleByDay } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Select } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import PathologyForm from "./PathologyForm";
import QuickSMSForm from "./QuickSMSForm";
import AcademyForm from "./AcademyForm";
import { objectToQueryString } from "../../Library/Utility/Utility";

const ServiceDetails = (): JSX.Element => {
    const [formData, setFormData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);

    const { mainCategory } = useContext(ThemeContext)
    const navigate = useNavigate();
    const { title } = useParams()
    const details = mainCategory?.find((service: any) => {
        return service?.slug === title
    })

    const clickHandle = (data: any) => {
        navigate(`/availability`);
        localStorage.setItem('selected_services', JSON.stringify(data))
    }

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const Submit = () => {
        return false
    }

    const genderOptions = [
        {
            "text": "Hospital",
            "value": "Hospital",
            "id": 1
        }, {
            "text": "Doctor",
            "value": "Doctor",
            "id": 2
        }
    ]

    const Options = [
        {
            "text": "Block",
            "value": "Block",
            "id": 1
        }, {
            "text": "Slide",
            "value": "Slide",
            "id": 2
        }
    ]


    const SlideOptions = [
        {
            "text": "1-2 Slides - 1500 Rs",
            "value": "1500",
            "id": 1
        }, {
            "text": "3-6 Slides - 3000 Rs",
            "value": "3000",
            "id": 1
        },
        {
            "text": "More then 7 Slides - 4500 Rs",
            "value": "4500",
            "id": 1
        }
    ]

    // useEffect(() => {
    //     const getData = async () => {
    //         const postData = {
    //             submitted_by: "doctor",
    //             services: 'short-meeting'
    //         };
    //         const APIResponse = await GetScheduleByDay(objectToQueryString(postData));
    //         console.log("APIResponse", APIResponse)
    //         if (APIResponse?.data?.isSuccess) {

    //         } else {
    //             toast.error(APIResponse?.data?.messages);
    //         }
    //     }
    //     getData()
    // }, [])



    return (
        <Wrapper pageTitle="Services" pageDes="We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.">
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-2 mb-4 servicesList2">
                            <div className="inner">
                                <ul className="listItem">
                                    {mainCategory?.map((service: any) => {
                                        return (
                                            <li>
                                                <NavLink to={`/services/${service?.slug}`}>
                                                    <span dangerouslySetInnerHTML={{ __html: service?.title }}></span>
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="inner">
                                <div className="header2">
                                    <div className="mobileNav">
                                        <NavLink to={'/services'} className={'btn btn-primary'}>Back to Services</NavLink>
                                    </div>
                                    <h2 className="desc" dangerouslySetInnerHTML={{ __html: details?.title }}></h2>
                                    <p className="">Meeting Type : {details?.service_type}</p>
                                    {details?.service_type === "Pathology" || details?.service_type === "SMS Query" || details?.service_type === "Career Counselling" ?
                                        '' :
                                        <p className="">Duration : {details?.duration} Min.</p>
                                    }

                                </div>
                                <div className="desc" dangerouslySetInnerHTML={{ __html: details?.description }}></div>
                                <p className="price">Rs {details?.price} INR </p>
                                {details?.service_type === "Pathology" || details?.service_type === "SMS Query" || details?.service_type === "Career Counselling" ?
                                    '' :
                                    <Button theme={theme} onClick={() => clickHandle(details)} buttonStyleType="primary">
                                        Book An Appointment
                                    </Button>
                                }
                            </div>
                        </div>
                        {details?.service_type === "Pathology" &&
                            <PathologyForm details={details}></PathologyForm>
                        }

                        {details?.service_type === "SMS Query" &&
                            <QuickSMSForm details={details}></QuickSMSForm>
                        }

                        {details?.service_type === "Career Counselling" &&
                            <AcademyForm details={details}></AcademyForm>
                        }


                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default ServiceDetails;
