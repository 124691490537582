import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";

const Services = (): JSX.Element => {
    const { mainCategory } = useContext(ThemeContext)
    const navigate = useNavigate();


    const clickHandle = (data: any) => {
        console.log("asd", data)
        navigate(data?.slug);
        localStorage.setItem('selected_services', JSON.stringify(data))
    }

    return (
        <Wrapper pageTitle="Services" pageDes="We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.">
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        {mainCategory?.map((service: any) => {
                            return (
                                <div className="col-md-4 mb-4">
                                    <div className="inner">
                                        <div className="flex justify-content-between">
                                            <h2 className="desc" dangerouslySetInnerHTML={{ __html: service?.title }}></h2>
                                            <p className="type">{service?.service_type}</p>
                                        </div>
                                        <div className="desc" dangerouslySetInnerHTML={{ __html: service?.short_description }}></div>
                                        {/* <p className="price">Rs {service?.price} INR </p> */}
                                        <p className="mt-2">
                                            <Button theme={theme} onClick={() => clickHandle(service)} buttonStyleType="primary"> Read More </Button>
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default Services;
