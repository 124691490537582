/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { Button, CustomTable } from "../../Library/Module";
import MyAccountWrapper from "./MyAccountWrapper";
import MyAppointmentRow from "./MyAppointmentsRow";
import { GetFamily } from "../../servies/services";
import MedicalHistoryRow from "./MedicalHistoryRow";
import FamilyDetailsRow from "./FamilyDetailsRow";
import QuestionnaireInner from "../Questionnaire/QuestionnaireInner";
import QuestionnaireInnerV2 from "../Questionnaire/QuestionnaireInner2";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "name",
            display: "Name",
            sort: true,
        },
        {
            name: "age",
            display: "Age",
            sort: true,
        },
        {
            name: "gender",
            display: "Gender",
            sort: true,
        },

        {
            name: "relation",
            display: "Relation",
            sort: true,
        },
        {
            name: "created_on",
            display: "Updated Date",
            sort: true,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};


const MyQuestionnaire = (): JSX.Element => {
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});

    const pageData = 'Questionnaire Details'
    const pageDescData = 'You can manage the Questionnaire Details in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {
        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })
    }

    const showCellCallback = () => {

    }

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetFamily();
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI]);

    const AddMember = () => {

    }
    return (
        <MyAccountWrapper>
            <h1>Update Questionnaire</h1>
            <QuestionnaireInnerV2></QuestionnaireInnerV2>
        </MyAccountWrapper>
    );
};
export default MyQuestionnaire;
