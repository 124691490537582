/* eslint-disable no-empty-pattern */
import './login.css'
import LoginForm from "./LoginForm";


const Login = () => {
  return (
    <div className="loginWrapper">
      <div className="loginBoxBg">
        <LoginForm></LoginForm>
      </div>
    </div>
  );
};
export default Login;
