import React, { useEffect, useState } from 'react'
import { Button, Card, CustomTable, Input, Select, SideDrawer, Tag } from "../Library/Module"
import CustomTooltip from "../Library/Tooltip/Tippy";
import { GetDiscount } from '../servies/services';
import { objectToQueryString } from '../Library/Utility/Utility';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js";

function loadScript(src: any) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const __DEV__ = document.domain === 'localhost';

const PlanDetails = () => {
    // @ts-ignore
    const selectedServices = JSON.parse(localStorage.getItem("selected_services"));
    // @ts-ignore
    const timeDate = JSON.parse(localStorage.getItem("time_date"));
    // @ts-ignore
    const familyDetails = JSON.parse(localStorage.getItem("family_details"));

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

    console.log("selectedServices", selectedServices)

    const [tableData, setTableData] = useState([])
    const [actionType, setActionType] = useState<any>({});

    const [formData, setFormData] = useState<any>({});



    const ApplyBtn = () => {
        setActionType({
            type: "add",
            id: 'id',
        })
    }

    const CloseDrawer = () => {
        setActionType({});
    };

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };


    const [discountFinal, setDiscountFinal] = useState('0')

    const DiscountFees = (discountAmt: any) => {
        localStorage.setItem('discount', discountAmt)
        console.log("discountAmt", discountAmt)
        setDiscountFinal(discountAmt ? discountAmt : '0')
        setActionType({});
    }

    const getDiscountData = async (code: any) => {
        const query = {
            code: code
        }
        const apidata = await GetDiscount(objectToQueryString(query));
        const APIResponse = apidata?.data?.data?.[0];
        if (APIResponse && APIResponse?.length > 0) {
            console.log("APIResponse", APIResponse)
            const discountAmount = APIResponse?.type === "Flat" ? APIResponse?.discount : selectedServices?.price / 100 * APIResponse?.discount
            DiscountFees(discountAmount)
            toast.info(`Discount code applied ${APIResponse?.name}.`);
        } else {
            toast.error(`Invalid discount code, please try again.`);
        }
    };

    const logoutAction = () => {
        getDiscountData(formData?.discount)
    }


    return (
        <>
            <Card>
                <div className="planDetails">
                    <div className="inner">
                        <h1>Plan Details</h1>
                        <p><label>Appointment Type</label>
                            <span dangerouslySetInnerHTML={{ __html: selectedServices?.title }}></span>
                        </p>
                        <p><label>Service Type</label>
                            {selectedServices?.service_type}
                        </p>
                        <p><label>Appointment Date & Time</label>{timeDate?.date} {timeDate?.time}</p>


                        <CustomTooltip
                            title={"Patient Details"}
                            position={"bottom"}
                            content={
                                <>  <p><strong>Patient Details</strong></p>
                                    <p><label>Name</label>{familyDetails?.name}</p>
                                    <p><label>Age</label>{familyDetails?.age}</p>
                                    <p><label>Gender</label>{familyDetails?.gender}</p>
                                    <p><label>Relation</label>{familyDetails?.relation}</p>
                                </>
                            }>
                            <p><label>Patient <span className="small-size material-symbols-outlined">
                                info
                            </span></label>{familyDetails?.relation} </p>
                        </CustomTooltip>
                        <p><label>Cost</label>{selectedServices?.price} INR</p>
                        {/* <div className='infoText'>
                            <p>
                                <Input
                                    inputName="discount"
                                    inputType="text"
                                    labelName={"Discount"}
                                    placeholder="Enter Discount Code"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    value={formData?.discount}
                                />

                                <Button onClick={() => logoutAction()} buttonStyleType="primary">Apply</Button>

                                {discountFinal === '0' ? 0 : `${discountFinal} INR`}
                            <Button onClick={() => ApplyBtn()}>{discountFinal && discountFinal === '0' ? 'Apply' : `Change`} Discount</Button>
                            </p>
                        </div> */}
                        <p><label>Payment</label>
                            {selectedServices?.price - Number(discountFinal)} INR</p>
                        {/* <Button onClick={() => displayRazorpay({
                            user_details_frontend: user_details_frontend,
                            plan_name: selectedServices?.title,
                            service_type: selectedServices?.service_type,
                            appointment_date_time: timeDate?.date,
                            patient_name: familyDetails?.name,
                            patient_age: familyDetails?.age,
                            patient_gender: familyDetails?.gender,
                            patient_relation: familyDetails?.relation,
                            other_details: "null",
                            price: selectedServices?.price,
                            discount: discountFinal,
                            amount: (selectedServices?.price - Number(discountFinal)).toString()
                        })}>Pay Now With RazorPay</Button> */}

                        {/* <Button onClick={() => displayStripPay({
                            user_details_frontend: user_details_frontend,
                            plan_name: selectedServices?.title,
                            service_type: selectedServices?.service_type,
                            appointment_date_time: timeDate?.date,
                            email_id: user_details_frontend?.email_id,
                            mobile: user_details_frontend?.mobile,
                            details: {
                                patient_name: familyDetails?.name,
                                patient_age: familyDetails?.age,
                                patient_gender: familyDetails?.gender,
                                patient_relation: familyDetails?.relation,
                                other_details: "null",
                            },
                            price: selectedServices?.price,
                            discount: discountFinal ?? "0",
                            amount: (selectedServices?.price - Number(discountFinal)).toString(),
                        })}>Pay Now With StripePay</Button> */}

                    </div>

                </div>

            </Card>
            {(actionType?.type === "add" || actionType?.type === "edit") && (
                <SideDrawer
                    size={'750px'}
                    pagetitle={`Discount Details`}
                    action={CloseDrawer}
                >
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <td> Name </td>
                                <td> Type </td>
                                <td> Discount </td>
                                <td> Title </td>
                                <td> Price </td>
                                <td> Final Price </td>
                                <td> Action </td>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((item: any) => {
                                const discountAmount = item?.type === "Flat" ? item?.discount : selectedServices?.price / 100 * item?.discount
                                return <tr>
                                    <td> {item?.name} </td>
                                    <td> {item?.type} </td>
                                    <td> {item?.discount ?? 0}{item?.type === "Flat" ? ' INR' : "%"} </td>
                                    <td> <span dangerouslySetInnerHTML={{ __html: selectedServices?.title }}></span></td>
                                    <td>{selectedServices?.price} </td>
                                    <td>
                                        <Tag title={item?.type === "Flat" ? selectedServices?.price - discountAmount :
                                            selectedServices?.price - discountAmount} color="blue"
                                        ></Tag>
                                    </td>
                                    <td> <Button onClick={() => DiscountFees(discountAmount)}>Apply</Button> </td>
                                </tr>
                            })}
                            <tr></tr>
                        </tbody>
                    </table>
                </SideDrawer>
            )}
        </>
    )
}

export default PlanDetails


export const displayStripPay = async ({
    user_details_frontend,
    plan_name,
    service_type,
    appointment_date_time,
    details,
    price,
    discount,
    amount,
    email_id,
    mobile,
    patient_name
}: any) => {
    let inputdata = {
        plan_name: plan_name,
        service_type: service_type,
        appointment_date_time: appointment_date_time,
        details: details,
        amount: amount,
        user_id: user_details_frontend?.id ?? 'not-logged-in',
        user_name: user_details_frontend?.user_name ?? 'not-logged-in',
        created_by: user_details_frontend?.user_name ?? 'not-logged-in',
        email_id: email_id,
        mobile: mobile,
        organization_id: 'drAnandJoshi',
        status: "1",
        price: price,
        discount: discount,
        patient_name: patient_name
    }

    try {
        // 
        // test // pk_test_51O25mhSGukGk5MZ2KJYQjFzawUXN7BsOjETBD6bLLZX1IUi2pALxlF4nEqM8PZs4x40tSDMZEUPyvoLIFpcdAc1b00bmOoNYgk
        const stripe: any = await loadStripe("pk_live_51OEo9JSIt7oS6EfiwADruWQXjhssLfGvLxIuHfMYeCfzwHc9cHloc3TuXfokYez307C7WQwoZSBxbCBhbjOrMJPZ00k9hRpC2a");
        const headers = {
            "Content-Type": "application/json",
        };

        const response = await fetch(
            "https://dranandjoshi.com/nodeapi/api/v1/order/create-checkout-session",
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(inputdata),
            }
        );

        const session = await response.json();

        const result = stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.log(result.error);
        }

    } catch (error: any) {
        alert('Failed');
        console.log('placeorder API error', error);
        //const message = error.response.data.messages;
        // toast(message)
    }
}


export const displayRazorpay = async ({
    user_details_frontend,
    plan_name,
    service_type,
    appointment_date_time,
    patient_name,
    patient_age,
    patient_gender,
    patient_relation,
    other_details,
    price,
    discount,
    amount
}: any) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
        return
    }
    let inputdata = {
        plan_name: plan_name,
        service_type: service_type,
        appointment_date_time: appointment_date_time,
        patient_name: patient_name,
        patient_age: patient_age,
        patient_gender: patient_gender,
        patient_relation: patient_relation,
        other_details: other_details,
        amount: amount,
        user_id: user_details_frontend?.user_name,
        email_id: user_details_frontend?.email_id,
        mobile: user_details_frontend?.mobile,
        created_by: user_details_frontend?.user_name,
        organization_id: 'course_id',
        status: "1",
        price: price,
        discount: discount,
    }

    try {
        const res = await axios.post(process.env.react_app_base_url + '/api/v1/order/placeorder', inputdata);
        const resp = res.data;
        if (res.status === 200) {
            console.log('placeorder', resp);
            const options = {
                key: __DEV__ ? 'rzp_test_e2QPjCFeGTnDsi' : 'rzp_test_e2QPjCFeGTnDsi',
                currency: resp.currency,
                amount: parseInt(resp.amount),
                order_id: resp.order_id,
                name: resp.service_type,
                description: resp.appointment_date_time + " - " + resp.plan_name,
                image: process.env.react_app_base_url_app + '/logo.jpg',
                handler: async function (response: any) {
                    console.log("response response response response response response response ", response)
                    const paymentDetails = {
                        "payment_id": response.razorpay_payment_id,
                        "order_id": response.razorpay_order_id,
                        "razorpay_signature": response.razorpay_signature,
                    }
                    const patchData = {
                        "id": resp.id,
                        "payment_id": response.razorpay_payment_id,
                        "order_id": response.razorpay_order_id,
                        "razorpay_signature": response.razorpay_signature
                    }
                    try {
                        const respPatch = await axios.patch(process.env.react_app_base_url + '/api/v1/order/placeorder', patchData);
                        if (respPatch.status === 200) {
                            alert("We have received payment. Your order in progress, you can check the status in My Account - Payment details section.");
                            // window.location.assign(process.env.react_app_base_url_app+"/students/dashboard/")
                            // this.props.history.push(process.env.react_app_base_url_app+"/students/thank-you-purchase/")
                        }
                    } catch (error) {
                        alert('something went wrong, please try again');
                    }
                },
                prefill: {
                    name: localStorage.getItem('user_name'),
                    email: localStorage.getItem('user_email'),
                    contact: localStorage.getItem('user_mobile'),
                }
            }
            const paymentObject = new (window as any).Razorpay(options)
            paymentObject.open()
        } else {
            alert('Failed');
        }

    } catch (error: any) {
        alert('Failed');
        console.log('placeorder API error', error.response.data.messages);
        const message = error.response.data.messages;
        toast(message)
    }
}