import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CreateMeeting, GetServices, GetAppointmentsById, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import { mainAddress, objectToQueryString } from "../../Library/Utility/Utility";

const ThankYou = (): JSX.Element => {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [apiData, setApiData] = useState<any>()
    const [msg, setMsg] = useState('Getting appointment details')

    useEffect(() => {
        const apiData = async () => {
            setIsLoading(false)
            const apidata = await GetAppointmentsById(id);
            console.log("apidata?.data", apidata?.data?.data)
            if (apidata?.data?.data?.payment_status === "unpaid") {
                setMsg('Payment Failed')
            }
            if (apidata?.data?.data?.payment_status === "paid") {
                setMsg('Payment Received')
            }
            setApiData(apidata?.data?.data)
            setIsLoading(true)
        }

        apiData()

    }, [id])
    // const [searchParams, setSearchParams] = useSearchParams();
    // const session_id = searchParams.get("session_id")
    // const [apiData, setapiData] = useState<any>({})
    // const [apiStatus, setApiStatus] = useState<any>(false)
    // const errorMsg = 'We are not able to process your request. Please check your payment details in your dashboard.'
    // const [isSuccess, setIsSuccess] = useState()
    // const [creatMeeting, setCreatMeeting] = useState<any>()
    // const [orderDetails, setOrderDetails] = useState<any>()
    // const [isLoading, setIsloading] = useState(false)
    // const [msg, setMsg] = useState('Getting payment details')

    // const combineDateAndTime = (date: any, time: any) => {
    //     // var date = '2019-02-16';
    //     // var time = '8:24 PM';

    //     // tell moment how to parse the input string
    //     var momentObj = moment(date + time, 'DD/MM/YYYYTHH:mm:s');

    //     // conversion
    //     var dateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');

    //     console.log("dateTime", dateTime);
    //     return dateTime

    // };


    // useEffect(() => {
    //     if (session_id && session_id?.length === 66) {
    //         const apiData = async () => {
    //             setApiStatus(false)
    //             const query = {
    //                 session_id: session_id
    //             }
    //             setIsloading(true)
    //             const apidata = await GetSessionStatus(objectToQueryString(query));
    //             console.log("apidata?.data", apidata?.data?.isSuccess)
    //             setIsSuccess(apidata?.data?.isSuccess)
    //             if (apidata?.data?.isSuccess) {
    //                 setapiData(apidata?.data?.session)
    //                 if (apidata?.data?.session?.payment_status === "unpaid") {
    //                     setMsg('Payment Failed')
    //                     setApiStatus(false)
    //                 }
    //                 if (apidata?.data?.session?.payment_status === "paid") {
    //                     setMsg('Payment Received')
    //                 setApiStatus(true)
    //                 let orderDetails = apidata?.data?.order_details
    //                 setOrderDetails(apidata?.data?.order_details)
    //                 console.log("orderDetails?.service_type", orderDetails?.service_type)
    //                 if (orderDetails?.service_type === "Online Meeting" && orderDetails?.meeting_type !== "WhatsApp video call") {
    //                     const postData = {
    //                         "topic": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
    //                         "agenda": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
    //                         "duration": orderDetails?.meeting_duration,
    //                         "password": "12345678",
    //                         "host_email": "balaji.image@gmail.com",
    //                         "start_time": combineDateAndTime(orderDetails?.appointment_date_time, '08:24 PM'),
    //                         "host_name": "Balaji",
    //                         "patient_email": "btlakal25@gmail.com",
    //                         "id": orderDetails?.payment_id
    //                     }
    //                     console.log("postData", postData)
    //                     const apiMeetingData = await CreateMeeting(postData, apidata?.data?.session);
    //                     setCreatMeeting(apiMeetingData?.data?.zoom)
    //                     console.log("postData", apiMeetingData?.data?.zoom)
    //                     setIsloading(false)
    //                 }
    //             }
    //             } else {
    //                 toast.error("Unable to process your payment details.")
    //                 setMsg('Unable to process your payment details.')
    //             }
    //         }
    //         apiData()

    //     } else {
    //         toast.error(errorMsg)
    //         setApiStatus(false)
    //         setMsg('We are not able to process your request. Please check your payment details in your dashboard.')
    //     }
    // }, [session_id])

    // console.log("session_id", session_id, apiData)

    return (
        <>
            <Wrapper pageTitle={msg}>
                <div className="innerWrapperFull">
                    <div className="servicesList">
                        <div className="row">
                            <div className="inner list">
                                {isLoading ?
                                    <>
                                        {/* {JSON.stringify(apiData)} */}
                                        {apiData?.service_type === "In-person Meeting" ?
                                            <>
                                                <h2>Your appointment is booked at the following clinic.</h2>
                                                <p>Address: <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span>
                                                </p>
                                                <p><label>Date & Time </label>{apiData?.appointment_date_time}</p>
                                                <p>Please call secretary on following number for any query or change.</p>
                                            </>
                                            : apiData?.service_type === "SMS Query" ?
                                                <>
                                                    <h2>Your Query has been submitted, Doctor will be get in touch with you.</h2>
                                                </>
                                                :
                                                <>
                                                    <h2>Your appointment - link is as follows.</h2>
                                                    <p><label>{apiData?.meeting_type} Link </label>{apiData?.meeting_type === "WhatsApp video call" ? `Admin will call you on whatsapp at ${apiData?.appointment_date_time}` : apiData?.meeting_start_url ? <a href={`${apiData?.meeting_start_url}`}>Zoom Link url</a> : ''} </p>
                                                    <p>Please call or message to online number for any change.</p>
                                                </>
                                        }

                                        <h2 className="mt-3">Details</h2>
                                        <p><label>Plan Name</label>{apiData?.plan_name}</p>
                                        <p><label>Service Type</label>{apiData?.service_type}</p>
                                        <p><label>Date Time </label>{apiData?.appointment_date_time}</p>
                                        <p><label>Meeting Type</label>{apiData?.meeting_type}</p>



                                        <h2 className="mt-3">Payment Details</h2>
                                        <p><label>Price</label>{apiData?.price}</p>
                                        <p><label>Amount</label>{apiData?.amount}</p>
                                        <p><label>Discount</label>{apiData?.discount}</p>
                                        <p><label>Email</label>{apiData?.email_id}</p>
                                        <p><label>Name</label>{apiData?.patient_name}</p>
                                        <p><label>Payment Id</label>{apiData?.payment_id}</p>
                                        <p><label>Payment Status</label>{apiData?.payment_status}</p>
                                        <p><label>Status</label>{apiData?.status}</p>
                                    </>
                                    : 'Loading ...'}

                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};
export default ThankYou;
