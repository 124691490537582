/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { theme, variables } from "../../theme";
import Wrapper from "../Wrapper";
type formDataProps = {
  email_id: string;
  password: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [formData, setFormData] = useState<formDataProps>();

  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
  };

  const Submit = async (event: any) => {
    setIsFormSubmitted(true);
    if (formData?.email_id !== undefined && formData?.email_id.length !== 0) {
      setIsValid(true);

      let login_type = "user_name"
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const hasNumbers = (t: any) => {
        return /^\d+$/.test(t);
      }


      if (formData?.email_id.match(validRegex)) {
        login_type = "email_id"
      }

      await axios
        .get(`https://dranandjoshi.com/nodeapi/api/v1/user/resetpassword?email_id=${formData?.email_id}&login_type=${login_type}`)
        .then((response) => {
          console.log("response.data", response.data);
          if (response?.data?.isSuccess) {
            toast.success(response?.data?.messages);
          } else {
            toast.error(response?.data?.messages);
            setIsError(true);
          }
        })
        .catch(function (error) {
          console.log("response.data", JSON.stringify(error))
          toast.error("Something went wrong, please try again");
          setIsError(true);
          console.log(error);
        });
    } else {
      setIsValid(false);
      toast.error("Please enter required fields");
    }
  };

  const ForgotPassword = () => {
    navigate("/");
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="forgotPage">
          <div className="login_admin2">
            <Card>
              <h3 className="text-center">Forgot Your Password</h3>
              <p className="text-center mb-4">
                Enter Email ID to continue to {variables?.site_title}
              </p>
              <div className="container-fluid">

                {!isValid && (
                  <Alert
                    AlertStyleType="danger"
                    AlertText={`Please enter required fields`}
                  ></Alert>
                )}
                <div className="row">
                  <Input
                    inputName="email_id"
                    inputType="text"
                    labelName={"Email ID / Username"}
                    placeholder="Enter email id / Username"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="12"
                    isFormSubmitted={isFormSubmitted}
                  />
                </div>
                <div className="row align-content-end justify-content-end">
                  <div className="col-sm-6 text-left">
                    <NavLink to="/">Login</NavLink>
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button
                      onClick={() => Submit("")}
                      buttonText={"Forgot Password"}
                      buttonStyleType={"primary"}
                      theme={theme}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default ForgotPassword;
