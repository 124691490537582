import Wrapper from "../Wrapper";
import Image1 from "../../images/steps/s1.jpg"
import Image2 from "../../images/steps/s2.jpg"
import Image3 from "../../images/steps/s3.jpg"
import Image4 from "../../images/steps/s4.jpg"
import Image5 from "../../images/steps/s5.jpg"
import Image6 from "../../images/steps/s6.jpg"
import Image7 from "../../images/steps/s7.jpg"
import Image8 from "../../images/steps/s8.jpg"
import Image9 from "../../images/steps/s9.jpg"
import Image10 from "../../images/steps/s9.jpg"
import Image11 from "../../images/steps/s9.jpg"
import Image12 from "../../images/steps/s9.jpg"
import Image13 from "../../images/steps/s9.jpg"
import Image14 from "../../images/steps/s9.jpg"

const Step = (): JSX.Element => {
    return (
        <>
            <Wrapper pageTitle={'How to book an appointment?'} pageDes={`Following below 6 eas`}>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 1</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image1} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image2} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 2</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 3</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image3} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image4} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 4</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 5</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image5} alt="" title="" /></div>
                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image6} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 6</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 7</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image7} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image8} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 8</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 9</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image9} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image10} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 10</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 11</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image11} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image12} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 12</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 13</div>
                                <p>You can select the our service from service list.</p>
                            </div>
                            <div className="col-md-8"><img src={Image13} alt="" title="" /></div>
                        </div>
                    </div>
                </div>
                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image14} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 14</div>
                                <p>You can select the our service from service list.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};
export default Step;
