import { NavLink, useNavigate } from "react-router-dom";
import { GetScheduleByDay, GetServices } from "../../servies/services";
import { useEffect, useState } from "react";
import { Button, SideDrawer } from "../../Library/Module";

import moment from "moment";
import { getTimeStops, objectToQueryString } from "../../Library/Utility/Utility";
import AuthDetect from "../MyAccount/AuthDetect";
import CalenderWrapper from "../../Library/Calendar/Calendar";
import { toast } from "react-toastify";

const Availability = (): JSX.Element => {
    const navigate = useNavigate();
    const [actionType, setActionType] = useState<any>({});
    const [calendarData, setCalendarData] = useState<any>([]);
    const [currentDate, setCurrentDate] = useState<any>()
    // const [services, setServices] = useState([])

    const [isLoadingDay, setIsLoadingDay] = useState(true);
    const [newData, setNewData] = useState([])

    const [isLoadingSlotDay, setIsLoadingSlotDay] = useState(true);
    const [dataSlotDay, setDataSlotDay] = useState<any>([]);

    useEffect(() => {
        setIsLoadingDay(true)
        const getData = async () => {
            const query = {
                offType: "full_day"
            }
            const apidata = await GetScheduleByDay(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            let updatedData: any = []
            APIResponse?.map((i: any) => {
                return updatedData.push(new Date(moment(i?.date).format('YYYY/MM/DD')))
            })
            setNewData(updatedData)
            setIsLoadingDay(false)
        };
        getData();
    }, []);

    // useEffect(() => {
    //     getProfileDetails()
    // }, [])

    // const getProfileDetails = async () => {
    //     const APIResponse = await GetServices();
    //     console.log("CreateMedia", APIResponse);
    //     setServices(APIResponse?.data?.data)
    // };
    // const [tableDataCalendar, setTableDataCalendar] = useState([])

    // const currentMonth = (data: any) => {
    //     console.log("data", data)
    //     const duration: any = localStorage.getItem('selected_services')
    //     const durationParse = JSON.parse(duration ?? '')
    //     console.log("duration", durationParse?.duration)
    //     const timeStops = getTimeStops('09:00', '16:59', Number(durationParse?.duration) + 15);
    //     console.log('timeStops ', timeStops);
    //     console.log("timeStops", data)
    //     var date = new Date(data?.currentDay);

    //     var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //     var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //     console.log("firstDay timeStops", firstDay)
    //     console.log("lastDay timeStops", lastDay)
    //     const startDate = moment(firstDay).format('YYYY-MM-DD');
    //     const endDate = moment(lastDay).format('YYYY-MM-DD');
    //     const mstartDate = moment(startDate);
    //     const mendDate = moment(endDate);

    //     const getDaysBetweenDates2 = (mstartDate: any, mendDate: any) => {
    //         console.log("mstartDate", mstartDate)
    //         console.log("mstartDate", mendDate)
    //         var now = mstartDate.clone(),
    //             dates: any = [];

    //         while (now.isSameOrBefore(mendDate)) {
    //             console.log("isSameOrBefore", moment(new Date()).isSameOrBefore(now))
    //             console.log("getDaysBetweenDates2 now", moment(now).format('DD/MM/YYYY').toString())
    //             const day = moment(now).format('d')
    //             console.log("getDaysBetweenDates2 day", day)
    //             console.log("day now", now)
    //             console.log("Sunday", moment(now, 'DD/MM/YYYY').weekday(0))
    //             if (moment(new Date()).isSameOrBefore(now)) {
    //                 if (day !== '6') {
    //                     if (day !== '0') {
    //                         timeStops?.map((i: any, index) => {
    //                             const startTime = moment(i, 'HH:mm')
    //                             const endtime = startTime.add(Number(durationParse?.duration), 'minutes')
    //                             console.log()
    //                             return dates.push({
    //                                 "status": "Available",
    //                                 "time": `${i + " - " + moment(endtime).format('HH:mm')}`,
    //                                 "date": now.format('DD/MM/YYYY'),
    //                             })
    //                         })
    //                     }
    //                 }
    //             }
    //             now.add(1, 'days')
    //         }
    //         return dates;
    //     }
    //     const dateList2: any = getDaysBetweenDates2(mstartDate, mendDate);
    //     console.log("timeStops dateList outise", dateList2)
    //     setTableDataCalendar(dateList2)
    //     return false
    // }


    const onRangeSelected = (e: any) => {
        console.log("onRangeSelected", moment(e?.startDate).format("DD/MM/YYYY"))
        setActionType({
            type: "add",
            id: 'id',
            data: e?.startDate
        })
        getAppointsStatus(e?.startDate)
        setCurrentDate(moment(e?.startDate).format("DD/MM/YYYY"))
    }

    const onYearChanged = (e: any) => {
        console.log("onYearChanged", e)
    }

    const CloseDrawer = () => {
        console.log("CloseDrawer")
        setActionType({});
    };



    const duration: any = localStorage.getItem('selected_services')
    const durationParse = JSON.parse(duration ?? '')
    var ActualCurrentDate = moment().format("DD/MM/YYYY");
    var ActualCurrentDateTime: any = moment().add(4, 'hours').format("HH");
    console.log("CurrentDate", currentDate, ActualCurrentDate, ActualCurrentDateTime)
    let timeStops
    if (currentDate === ActualCurrentDate) {
        if (ActualCurrentDateTime >= '15' || ActualCurrentDateTime < '06') {
            timeStops = []
        } else {
            timeStops = getTimeStops(`${ActualCurrentDateTime}:00`, '16:59', Number(durationParse?.duration) + 15);
        }
    } else {
        timeStops = getTimeStops('09:00', '16:59', Number(durationParse?.duration) + 15);
    }
    // const timeStops = getTimeStops('09:00', '16:59', Number(durationParse?.duration) + 15);
    const slot: any = [];
    timeStops?.map((i: any, index) => {
        const startTime = moment(i, 'HH:mm')
        const endtime = startTime.add(Number(durationParse?.duration), 'minutes')
        console.log("startTime", startTime, i)
        return slot.push({
            "status": "Available",
            "time": `${i + " - " + moment(endtime).format('HH:mm')}`,
            "date": currentDate,
        })
    })

    const clickHanddle = (item: any) => {
        console.log("selected", item)
        localStorage.setItem('time_date', JSON.stringify(item))
        navigate('/family-details')
    }

    const getAppointsStatus = (date: any) => {
        console.log("date", date)
        setIsLoadingSlotDay(true)
        const getData = async () => {
            const query = {
                date: moment(date).format("YYYY/MM/DD"),
                // offType: "partial_day"
            }
            const apidata = await GetScheduleByDay(objectToQueryString(query));
            console.log("apidata", apidata)
            const APIResponse = apidata?.data?.data;
            const slotList: any = []
            APIResponse?.map((item: any) => {
                console.log("item?.time", item?.time)
                return slotList.push(item?.time)
            })

            // Other call

            const queryTwo = {
                date: moment(date).format("YYYY/MM/DD"),
                "services": durationParse?.slug
            }
            const apidataTwo = await GetScheduleByDay(objectToQueryString(queryTwo));
            console.log("apidata", apidataTwo)
            const APIResponse2 = apidataTwo?.data?.data;
            APIResponse2?.map((item: any) => {
                console.log("item?.time", item?.time)
                return slotList.push(item?.time)
            })

            setDataSlotDay(slotList)
            setIsLoadingSlotDay(false)
        };

        getData();
    }

    useEffect(() => {
        if (durationParse?.service_type === "In-person Meeting" || durationParse?.service_type === "Online Meeting") {

        } else {
            toast.error("Please select other meeting type, this feature is not available for this service.")
            navigate('/services')
        }
    }, [durationParse?.service_type, navigate])

    let date = new Date();

    // add a day
    date.setDate(date.getDate() - 1);

    return (
        <AuthDetect>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-12">
                            <CalenderWrapper
                                minDate={date}
                                disabledWeekDays={[0]}
                                onRangeSelected={onRangeSelected}
                                onYearChanged={onYearChanged}
                                disabledDaysData={newData}
                                data={calendarData}
                                isLoading={isLoadingDay}
                            ></CalenderWrapper>
                            <div className="navigationBar">
                                <div className="row">
                                    <div className="col-md-6">
                                        <NavLink className={'btn'} to={'/services'}>Back to Service</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {JSON.stringify(actionType)}
            {(actionType?.type === "add" || actionType?.type === "edit") && (
                <SideDrawer
                    size={'850px'}
                    pagetitle={`Availability Details :  ${moment(actionType?.data).format("DD/MM/YYYY")}`}
                    action={() => CloseDrawer()}
                >

                    <div className="p-1">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th> Time </th>
                                    <th> Date </th>
                                    <th> Status </th>
                                    <th> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {slot && slot?.length === 0 ?
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="note">Slot not available for selected date</div>
                                        </td>
                                    </tr> : ''}
                                {slot?.map((item: any) => {
                                    const isAvailable = dataSlotDay?.includes(item?.time)
                                    console.log("isAvailable", isAvailable, dataSlotDay)
                                    return (
                                        <tr>
                                            <td> {item?.time} </td>
                                            <td> {item?.date} </td>
                                            <td> {isAvailable ? 'Not Available' : item?.status} </td>
                                            <td> <Button disabled={isAvailable} onClick={() => clickHanddle(item)}>Select</Button> </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </SideDrawer>
            )}

        </AuthDetect>
    );
};
export default Availability;
