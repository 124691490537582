import {
  React
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { setUpAxios } from './servies/services';
import './css/theme.css'
import './css/style.css'
import Login from './container/Login/Login';
import Register from './container/Register/Register';

import ChangePassword from './container/ChangePassword/ChangePassword'
import ForgotPassword from './container/ForgotPassword/ForgotPassword'
import VerifyAccount from './container/VerifyAccount/VerifyAccount'
import Dashboard from './container/Dashboard/Dashboard';
import './App.css'
import Home from './container/Home/Home';
import Services from './container/Services/Services';
import Appointment from './container/Appointment/Appointment';
import Availability from './container/Availability/Availability';
import AddFamily from './container/Family/AddFamily2';
import AboutUs from './container/AboutUs/AboutUs';
import Testimonials from './container/Testimonials/Testimonials';
import ContactUs from './container/ContactUs/ContactUs';
import { ThemeProvider } from './Context/Theme/Context'
import Questionnaire from './container/Questionnaire/Questionnaire';
import MyAccount from './container/MyAccount/MyAccount';
import UpdateProfile from './container/MyAccount/UpdateProfile';
import ChangePasswordInner from './container/MyAccount/ChangePassword';
import MyAppointments from './container/MyAccount/MyAppointments';
import MedicalHistory from './container/MyAccount/MedicalHistory';
import FamilyDetails from './container/MyAccount/FamilyDetails';
import MyQuestionnaire from './container/MyAccount/Questionnaire';
import ErrorBoundary from './container/ErrorBoundary';
import OrderSummery from './container/OrderSummery/OrderSummery';
import ServiceDetails from './container/Services/ServiceDetails';
import MyPayments from './container/MyAccount/MyPayments';
import MyOrders from './container/MyAccount/MyOrders';
import { loadStripe } from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Payment from './hooks/Payment';
import Success from './container/Payment/Success';
import CurrentProblems from './container/Questionnaire/CurrentProblems';
import ScrollToTop from './components/ScrollToTop';
import Cancel from './container/Payment/Cancel';
import PrivacyPolicy from './container/CMS/PrivacyPolicy';
import TermOfUse from './container/CMS/TermOfUse';
import RefundPolicy from './container/CMS/RefundPolicy';
import PythologyReport from './container/MyAccount/PythologyReport/PythologyReport';
import SMSQuery from './container/MyAccount/SMSQuery/SMSQuery';
import ThankYou from './container/Payment/ThankYou';
import Step from './container/Steps/Steps';
// import ZoomMeeting from './container/ZoomMeeting';
// import CheckoutForm from './hooks/CheckoutForm';


function App() {
  setUpAxios();
  console.log("window.location.pathname setUpAxios", window.location.pathname)
  console.log("window.location.search setUpAxios", window.location.search)

  const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'inr',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };


  return (
    <>
      <BrowserRouter >
        <ThemeProvider>
        <ScrollToTop path={window.location.pathname} />
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/success" element={<Success />} />
            <Route path="/thank-you/:id" element={<ThankYou />} />
            {/* 65b21a9d04886898af027e93 */}
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/book-appointment-steps" element={<Step />} />
            {/* <Route path="/meeting" element={<ZoomMeeting />} /> */}
            
            {/* <Route path="/success-quick-sms" element={<Success />} /> */}
            {/* <Route path="/cancel-quick-sms" element={<Success />} /> */}
            
            <Route path="/services/:title" element={<ServiceDetails />} />

            <Route path="/availability" element={<Availability />} />
            <Route path="/family-details" element={<AddFamily />} />
            <Route path="/questionnaire" element={<Questionnaire />} />

            <Route path="/problem" element={<CurrentProblems />} />
            
            <Route path="/payment" element={<OrderSummery />} />

            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/my-account/my-appointment/dashboard" element={<MyAppointments />} />

            <Route path="/my-account/my-appointment/sms-query" element={<SMSQuery />} />
            <Route path="/my-account/my-appointment/pathology" element={<PythologyReport />} />

            <Route path="/my-account/my-payments" element={<MyPayments />} />
            <Route path="/my-account/my-orders" element={<MyOrders />} />

            <Route path="/my-account/update-profile" element={<UpdateProfile />} />
            <Route path="/my-account/change-password" element={<ChangePasswordInner />} />
            <Route path="/my-account/medical-history" element={<MedicalHistory />} />

            <Route path="/my-account/relatives" element={<FamilyDetails />} />
            <Route path="/my-account/questionnaire" element={<MyQuestionnaire />} />
            {/* <Route path="/my-account/orders" element={<UpdateProfile />} /> */}

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<VerifyAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change_password" element={<ChangePassword />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/about-us" element={<AboutUs />} /> */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermOfUse />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            {/* <Route path="/payment" element={
              <Elements stripe={stripePromise} options={options}>
                <Payment />
              </Elements>} /> */}


            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="*" element={<Home />} />
          </Routes>

          <ToastContainer position='top-center'></ToastContainer>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App