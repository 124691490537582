/* eslint-disable no-empty-pattern */
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AddFamilyMember, AddMedicalHistory, CreateMedia, GetFamily, GetFamilyDetailById, GetMedicalHistoryByAppointment, GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Card, CustomTable, Input, SideDrawer } from "../../Library/Module";
import { theme } from "../../theme";
import Login from "../Login/Login";
import LoginForm from "../Login/LoginForm";
import TabComp from "../../Library/Tab/Tab";
import RegisterForm from "../Register/RegisterForm";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'

import { objectToQueryString } from "../../Library/Utility/Utility";
import PlanDetails from "../../components/PlanDetails";
import AuthDetect from "../MyAccount/AuthDetect";
import InputFile from "../../Library/File/File";
import moment from "moment";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "select",
            display: "Select Patient",
            sort: false,
        },
        {
            name: "name",
            display: "Name",
            sort: true,
        },
        {
            name: "age",
            display: "Age",
            sort: true,
        },
        {
            name: "gender",
            display: "Gender",
            sort: true,
        },

        {
            name: "relation",
            display: "Relation",
            sort: true,
        },
        {
            name: "created_on",
            display: "Added Date",
            sort: true,
        },
        {
            name: "Action",
            display: "Action",
            sort: false,
        },
    ],
};

const CurrentProblems = (): JSX.Element => {
    const [formData, setFormData] = useState<any>();
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});
    const navigate = useNavigate();
    const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();
    const pageData = 'Family Details'
    const pageDescData = 'You can manage the Family Details in this module.'
    const [userQuery, setUserQuery] = useState<any>([]);
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {


        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })

        setTableDataCalendarSingle([]);
        const getData2 = async () => {
            const query = {
                parent_id: [data?.parent_id]
            }
            const apidata = await GetFamilyDetailById(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            setTableDataCalendarSingle(APIResponse);
        };
        getData2();
    }



    const showCellCallback = () => {

    }

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetFamily();
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI]);

    const AddMember = () => {
        setActionType({
            type: "add",
            id: 'id',

        })

    }
    const CloseDrawer = () => {
        setActionType({});
    };

    const FormSubmission = (data: boolean) => {
        setActionType({});
        setReloadAPI(true);
    }

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const Submit = async () => {
        if (
            formData?.problems !== undefined &&
            formData?.problems.length !== 0
        ) {
            // @ts-ignore
            const selectedServices = JSON.parse(localStorage.getItem("selected_services"));
            // @ts-ignore
            const timeDate = JSON.parse(localStorage.getItem("time_date"));
            // @ts-ignore
            const familyDetails = JSON.parse(localStorage.getItem("family_details"));

            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

            // @ts-ignore
            const profile_details_frontend = JSON.parse(localStorage.getItem("profile_details_frontend"));

            let fileNamePath: any = []
            if (formData?.medical_report) {
                console.log("formData?.medical_report", formData?.medical_report)
                const formData1 = new FormData();
                const files = Array.from(formData?.medical_report).map((i: any) => {
                    return formData1.append("file", i);
                })
                console.log("files", files)
                formData1.append("email_id", profile_details_frontend?.email_id);
                formData1.append("type", "currentProblem");
                console.log(JSON.stringify(formData1));
                const filePath = await CreateMedia(formData1);
                console.log("CreateMedia", filePath?.data?.data, filePath?.data?.data?.length);
                if (filePath?.data?.data?.length) {
                    filePath?.data?.data?.map((path: any) => {
                        fileNamePath?.push(path?.path)
                    })
                } else {
                    fileNamePath?.push(filePath?.data?.data?.path)

                }
            }
            console.log("fileNamePath", fileNamePath)

            const data = {
                "user": user_details_frontend?.user_name,
                "name": familyDetails?.name,
                "user_id": user_details_frontend?.id,
                "member_id": familyDetails?._id,
                "member_details": familyDetails,
                "problems": formData?.problems,
                "submitted_by": "patient",
                "appointment_date_time": timeDate?.date + " " + timeDate?.time?.split(" - ")?.[0],
                "service_title": `${selectedServices?.title}`,
                "service_type": `${selectedServices?.service_type}`,
                "submitted_type": "query",
                "file_path": fileNamePath,
                "email_id": profile_details_frontend?.email_id
            }

            const APIResponse = await AddMedicalHistory(data);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success("Your details submitted succesfully.");
                GetData()
            } else {
                toast.error("Something went wrong, please try again");
            }
        } else {
            toast.error("Please enter required fields");
        }
    }

    const GetData = async () => {
        // @ts-ignore
        const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
        // @ts-ignore
        const timeDate = JSON.parse(localStorage.getItem("time_date"));
        const query = {
            "appointment_date_time": timeDate?.date + " " + timeDate?.time?.split(" - ")?.[0],
            "submitted_by": "patient",
            "submitted_type": "query",
            user_id: user_details_frontend?.id
        }
        const APIResponse = await GetMedicalHistoryByAppointment(objectToQueryString(query));
        console.log("GetMedicalHistory", APIResponse?.data?.data)
        setUserQuery(APIResponse?.data?.data)
    }

    useEffect(() => {
        GetData()
    }, [])

    const onChangeFileSingleCallback = (data: any) => {
        console.log("data onChangeFileSingleCallback", data)
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const next = () => {
        navigate("/questionnaire")
    }
    return (
        <AuthDetect>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="inner">
                                <div className="note mb-2">Please give your current problem list for consultation</div>
                                <Input
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    inputName="problems"
                                    required
                                    inputType="textarea"
                                    labelName="Current issue and problems?"></Input>

                                <InputFile multiple={true} onChangeFileSingleCallback={onChangeFileSingleCallback} inputName="medical_report" />
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <Button onClick={() => Submit()}>Upload</Button>
                                    </div>
                                </div>

                                <div className="p-5">
                                    <Card >
                                        <h5>List of Current Problem</h5>
                                        <div className="p-2">
                                            {userQuery?.length > 0 ? userQuery?.map((problem: any) => {
                                                const files = typeof problem?.file_path === 'object' ? problem?.file_path && problem?.file_path?.map((item: any) => {
                                                    console.log("item", item)
                                                    return <a className="mr-2 btn btn-primary float-left" target="_blank" href={process.env.react_app_base_url + "/" + item} rel="noreferrer">Download</a>
                                                }) : ''
                                                console.log("files", typeof problem?.file_path, problem?.file_path)
                                                return (
                                                    <div className="info">
                                                        <p>{problem?.problems}</p>
                                                        <p>Submitted At - {moment(problem?.created_on).format("DD/MM/YYYY hh:mm:ss a")}</p>
                                                        {files}
                                                        <div className="clearfix"></div>
                                                    </div>
                                                )
                                            }) : 'Patient Problem not found.'}
                                        </div>
                                    </Card>
                                </div>

                                <div className="navigationBar">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <NavLink className={'btn'} to={'/family-details'}>Back to Member Selection</NavLink>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <Button disabled={userQuery?.length === 0} onClick={() => next()}>Next</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <PlanDetails></PlanDetails>
                        </div>
                    </div>
                </div>

            </div>
        </AuthDetect >
    );
};
export default CurrentProblems;
