import React, { useEffect, useState } from 'react'
import { Button, Card, CustomTable, Input, Select, SideDrawer, Tag } from "../../Library/Module"
import CustomTooltip from "../../Library/Tooltip/Tippy";
import { CreateMeeting, GetDiscount, PostScheduleByDay } from '../../servies/services';
import { combineDateAndTime, mainAddress, objectToQueryString, replaceWithDash } from '../../Library/Utility/Utility';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js";
import AuthDetect from '../MyAccount/AuthDetect';
import moment from 'moment';

function loadScript(src: any) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const __DEV__ = document.domain === 'localhost';

const PlanDetails = () => {
    // @ts-ignore
    const selectedServices = JSON.parse(localStorage.getItem("selected_services"));
    // @ts-ignore
    const timeDate = JSON.parse(localStorage.getItem("time_date"));
    // @ts-ignore
    const familyDetails = JSON.parse(localStorage.getItem("family_details"));

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
    // @ts-ignore
    const profile_details_frontend = JSON.parse(localStorage.getItem("profile_details_frontend"));


    console.log("selectedServices", selectedServices)

    const [tableData, setTableData] = useState([])
    const [actionType, setActionType] = useState<any>({});

    const [formData, setFormData] = useState<any>({});



    const ApplyBtn = () => {
        setActionType({
            type: "add",
            id: 'id',
        })
    }

    const CloseDrawer = () => {
        setActionType({});
    };

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };


    const [discountFinal, setDiscountFinal] = useState('0')

    const DiscountFees = (discountAmt: any) => {
        localStorage.setItem('discount', discountAmt)
        console.log("discountAmt", discountAmt)
        setDiscountFinal(discountAmt ? discountAmt : '0')
        setActionType({});
    }

    const getDiscountData = async (code: any) => {
        const query = {
            code: code
        }
        const apidata = await GetDiscount(objectToQueryString(query));
        console.log("APIResponse =======================", apidata)

        const APIResponse = apidata?.data?.data?.[0];
        if (APIResponse && APIResponse) {
            const discountAmount = APIResponse?.type === "Flat" ? APIResponse?.discount : selectedServices?.price / 100 * APIResponse?.discount
            DiscountFees(discountAmount)
            toast.info(`Discount code applied ${APIResponse?.name}.`);
        } else {
            toast.error(`Invalid discount code, please try again.`);
        }
    };

    const logoutAction = () => {
        getDiscountData(formData?.discount)
    }

    const biopsyDoneAt = [

        {
            "text": "WhatsApp video call",
            "value": "WhatsApp video call",
            "id": 1
        }, {
            "text": "Zoom video call",
            "value": "Zoom video call",
            "id": 2
        }
    ]

    return (
        <AuthDetect>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner appointment_details">
                                <Card>
                                    <h1>Appointment Details</h1>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p><label>Appointment Type</label>
                                                <span dangerouslySetInnerHTML={{ __html: selectedServices?.title }}></span>
                                            </p>
                                            <p><label>Service Type</label>
                                                {selectedServices?.service_type}
                                            </p>
                                            <p><label>Appointment Date & Time</label>{timeDate?.date} {timeDate?.time}</p>
                                            {selectedServices?.service_type === "Online Meeting" ?
                                                <>
                                                    <Select
                                                        inputName={'meeting_type'}
                                                        labelName={'Meeting Type'}
                                                        options={biopsyDoneAt ?? []}
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        selectedItem={formData?.meeting_type ? biopsyDoneAt?.find(
                                                            (selected: any) => {
                                                                return (selected.value === formData?.meeting_type)
                                                            }
                                                        ) : biopsyDoneAt?.[0]}
                                                        required={true}
                                                        placeholder={'Select Meeting Type'}
                                                        search_option={false}
                                                        isLoading={true}
                                                        col="8"
                                                        value={formData?.meeting_type}
                                                    ></Select>
                                                </>

                                                :
                                                <>
                                                    <p>Address: 
                                                    <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span>
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <p><strong>Patient Details</strong></p>
                                            <p><label>Name</label>{familyDetails?.name}</p>
                                            <p><label>Age</label>{familyDetails?.age}</p>
                                            <p><label>Gender</label>{familyDetails?.gender}</p>
                                            <p><label>Relation</label>{familyDetails?.relation}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p><label>Cost</label>{selectedServices?.price} INR</p>
                                            <div className='infoText'>
                                                <p>
                                                    <Input
                                                        inputName="discount"
                                                        inputType="text"
                                                        labelName={"Discount"}
                                                        placeholder="Enter Discount Code"
                                                        required={true}
                                                        onChangeSingleCallback={onChangeSingleCallback}
                                                        col="12"
                                                        value={formData?.discount}
                                                    />

                                                    <Button onClick={() => logoutAction()} buttonStyleType="primary">Apply</Button>


                                                </p>
                                            </div>
                                            <p><label>Payment</label>
                                                {(selectedServices?.price - Number(discountFinal)).toFixed(2)} INR</p>
                                            {/* {selectedServices?.service_type === "Online Meeting" ? formData?.meeting_type ? formData?.meeting_type : biopsyDoneAt?.[0].value : selectedServices?.service_type} */}
                                            <p>
                                                {/* <Button onClick={() => displayStripPay({
                                                    user_details_frontend: user_details_frontend,
                                                    plan_name: selectedServices?.title,
                                                    service_type: selectedServices?.service_type,
                                                    appointment_date_time: timeDate?.date + " " + timeDate?.time?.split(" - ")?.[0],
                                                    email_id: profile_details_frontend?.email_id,
                                                    mobile: profile_details_frontend?.mobile,
                                                    meeting_type: selectedServices?.service_type === "Online Meeting" ? formData?.meeting_type ? formData?.meeting_type : biopsyDoneAt?.[0].value : selectedServices?.service_type,
                                                    patient_name: profile_details_frontend?.first_name + ' ' + profile_details_frontend?.last_name,
                                                    details: {
                                                        patient_name: familyDetails?.name,
                                                        patient_age: familyDetails?.age,
                                                        patient_gender: familyDetails?.gender,
                                                        patient_relation: familyDetails?.relation,
                                                        other_details: "null",
                                                    },
                                                    price: selectedServices?.price,
                                                    discount: discountFinal.toString() ?? "0",
                                                    amount: (Number(selectedServices?.price) - Number(discountFinal)).toFixed(4).toString(),
                                                })}>International Credit Card</Button> */}
                                            </p>
                                            <p>
                                                <Button onClick={() => displayRazorpay({
                                                    user_details_frontend: user_details_frontend,
                                                    plan_name: selectedServices?.title,
                                                    service_type: selectedServices?.service_type,
                                                    appointment_date_time: timeDate?.date + " " + timeDate?.time,
                                                    email_id: profile_details_frontend?.email_id,
                                                    mobile: profile_details_frontend?.mobile,
                                                    meeting_type: selectedServices?.service_type === "Online Meeting" ? formData?.meeting_type ? formData?.meeting_type : biopsyDoneAt?.[0].value : selectedServices?.service_type,
                                                    patient_name: profile_details_frontend?.first_name + ' ' + profile_details_frontend?.last_name,
                                                    member_id: familyDetails?._id,

                                                    year: timeDate?.date?.split("/")?.[2],
                                                    month: timeDate?.date?.split("/")?.[1],
                                                    day: timeDate?.date?.split("/")?.[0],
                                                    date: `${timeDate?.date?.split("/")?.[2]}/${timeDate?.date?.split("/")?.[1]}/${timeDate?.date?.split("/")?.[0]}`,
                                                    time: timeDate?.time,
                                                    internal_id: timeDate?.date + " " + timeDate?.time,

                                                    details: {
                                                        patient_name: familyDetails?.name,
                                                        patient_age: familyDetails?.age,
                                                        patient_gender: familyDetails?.gender,
                                                        patient_relation: familyDetails?.relation,
                                                        other_details: "null",
                                                    },
                                                    price: selectedServices?.price,
                                                    discount: discountFinal.toString() ?? "0",
                                                    amount: (Number(selectedServices?.price) - Number(discountFinal)).toFixed(4).toString(),
                                                })}>Pay Now</Button>
                                            </p>
                                        </div>
                                    </div>



                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthDetect>
    )
}

export default PlanDetails


export const displayStripPay = async ({
    user_details_frontend,
    plan_name,
    service_type,
    appointment_date_time,
    details,
    price,
    discount,
    amount,
    email_id,
    mobile,
    meeting_type,
    patient_name
}: any) => {
    let inputdata = {
        plan_name: plan_name,
        service_type: service_type,
        appointment_date_time: appointment_date_time,
        details: details,
        patient_name: patient_name,
        amount: amount,
        user_id: user_details_frontend?.id ?? 'not-logged-in',
        user_name: user_details_frontend?.user_name ?? 'not-logged-in',
        created_by: user_details_frontend?.user_name ?? 'not-logged-in',
        email_id: email_id,
        mobile: mobile,
        organization_id: 'drAnandJoshi',
        status: "1",
        price: price,
        discount: discount,
        meeting_type: meeting_type
    }

    try {
        // 
        // test // pk_test_51O25mhSGukGk5MZ2KJYQjFzawUXN7BsOjETBD6bLLZX1IUi2pALxlF4nEqM8PZs4x40tSDMZEUPyvoLIFpcdAc1b00bmOoNYgk
        const stripe: any = await loadStripe("pk_live_51OEo9JSIt7oS6EfiwADruWQXjhssLfGvLxIuHfMYeCfzwHc9cHloc3TuXfokYez307C7WQwoZSBxbCBhbjOrMJPZ00k9hRpC2a");
        const headers = {
            "Content-Type": "application/json",
        };
        console.log("inputdata", inputdata)

        const response = await fetch(
            "https://dranandjoshi.com/nodeapi/api/v1/order/create-checkout-session",
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(inputdata),
            }
        );

        const session = await response.json();

        const result = stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.log(result.error);
        }

    } catch (error: any) {
        alert('Failed');
        console.log('placeorder API error', error.response.data.messages);
        const message = error.response.data.messages;
        toast(message)
    }
}

const createMeetingZoom = async (orderDetails: any) => {
    if (orderDetails?.service_type === "Online Meeting" && orderDetails?.meeting_type !== "WhatsApp video call") {
        const postData = {
            "topic": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
            "agenda": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
            "duration": orderDetails?.meeting_duration,
            "password": "12345678",
            "host_email": "balaji.image@gmail.com",
            "start_time": combineDateAndTime(orderDetails?.appointment_date_time, '08:24 PM'),
            "host_name": "Balaji",
            "patient_email": "btlakal25@gmail.com",
            "id": orderDetails?.payment_id
        }
        console.log("postData", postData)
        const apiMeetingData = await CreateMeeting(postData, orderDetails?._id);
        return apiMeetingData
    }
}

const createSchedule = async (orderDetails: any) => {
    console.log("createSchedule orderDetails",
        orderDetails?.details?.patient_name,
        orderDetails?.plan_name,
        orderDetails?.date,
        orderDetails?.email_id,
        orderDetails?.details?.patient_name,
        orderDetails?.time,
        orderDetails?.date,
    )
    const postData = {
        "title": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
        "description": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
        "status": 'slot_blocked',
        "startDate": orderDetails?.date,
        "endDate": orderDetails?.date,
        "email_id": orderDetails?.email_id,
        "created_by": orderDetails?.details?.patient_name,
        "offType": "partial_day",
        "submitted_by": "patient",
        "time": orderDetails?.time,
        "date": orderDetails?.date,
        "servicesType": "appointment",
        "services": [replaceWithDash(orderDetails?.plan_name)],
    }
    console.log("createSchedule postData", postData)
    const apiMeetingData = await PostScheduleByDay(postData);
    console.log("createSchedule apiMeetingData", apiMeetingData)
    return apiMeetingData
}


export const displayRazorpay = async ({
    user_details_frontend,
    plan_name,
    service_type,
    appointment_date_time,
    details,
    price,
    discount,
    amount,
    email_id,
    mobile,
    meeting_type,
    patient_name,
    directURL,
    member_id,
    year,
    month,
    day,
    date,
    time,
    internal_id,
}: any) => {
    console.log("appointment_date_time", appointment_date_time, new Date(appointment_date_time))
    // const year = moment(new Date(appointment_date_time)).format("YYYY")
    // console.log("appointment_date_time", year)
    let inputdata = {
        plan_name: plan_name,
        service_type: service_type,
        appointment_date_time: appointment_date_time,
        details: details,
        patient_name: patient_name,
        amount: amount,
        user_id: user_details_frontend?.id ?? 'not-logged-in',
        user_name: user_details_frontend?.user_name ?? 'not-logged-in',
        created_by: user_details_frontend?.user_name ?? 'not-logged-in',
        email_id: email_id,
        mobile: mobile,
        organization_id: 'drAnandJoshi',
        status: "1",
        price: price,
        discount: discount,
        meeting_type: meeting_type,
        member_id: member_id ?? '',
        year: year,
        month: month,
        day: day,
        date: date,
        time: time,
        internal_id: internal_id,

        appointment_type:
            service_type === 'Online Meeting' ? 'appointment' :
                service_type === 'In-person Meeting' ? 'appointment' :
                    service_type
    }
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
        return
    }

    try {
        const res = await axios.post(process.env.react_app_base_url + '/api/v1/order/placeorder', inputdata);
        // const res = await axios.post('https://dranandjoshi.com/nodeapi/api/v1/order/placeorder', inputdata);
        const resp = res.data;
        if (res.status === 200) {
            console.log('placeorder', resp);
            const options = {
                // key: __DEV__ ? 'rzp_test_e2QPjCFeGTnDsi' : 'rzp_test_e2QPjCFeGTnDsi',
                key: __DEV__ ? 'rzp_live_Dt09JTc5lFyZ4E' : 'rzp_live_Dt09JTc5lFyZ4E',
                currency: resp.currency,
                amount: parseInt(resp.amount),
                order_id: resp.order_id,
                name: resp.service_type,
                description: resp.appointment_date_time + " - " + resp.plan_name,
                image: process.env.react_app_base_url_base + '/logo/email_logo.jpg',
                handler: async function (response: any) {
                    console.log("response response response response response response response", response)

                    const patchData = {
                        "id": resp.id,
                        "payment_id": response.razorpay_payment_id,
                        "order_id": response.razorpay_order_id,
                        "razorpay_signature": response.razorpay_signature,
                        "status": "completed",
                        "payment_status": "paid",
                    }
                    try {
                        const respPatch = await axios.patch(process.env.react_app_base_url + '/api/v1/order/placeorder', patchData);
                        console.log("respPatch?.data?.orderDetails", respPatch?.data?.orderDetails?.appointment_type, respPatch?.data?.orderDetails)


                        if (respPatch?.data?.orderDetails?.appointment_type === "appointment") {
                            try {
                                await createSchedule(respPatch?.data?.orderDetails)
                            } catch (error) {
                                console.log("error in createSchedule")
                            }
                        }

                        if (respPatch?.data?.orderDetails?.service_type === "Online Meeting" && respPatch?.data?.orderDetails?.meeting_type !== "WhatsApp video call") {
                            try {
                                await createMeetingZoom(respPatch?.data?.orderDetails)
                                alert("We have received payment.");
                                window.location.assign(process.env.react_app_base_url_base + `/thank-you/${respPatch?.data?.orderDetails?._id}`)
                            } catch (error) {
                                alert('Unable to creat the zoom meeting');
                            }
                        } else {
                            alert("We have received payment.");
                            window.location.assign(process.env.react_app_base_url_base + `/thank-you/${respPatch?.data?.orderDetails?._id}`)
                        }

                        if (respPatch.status === 200) {
                            // alert("We have received payment. Your order in progress, you can check the status in My Account - Payment details section.");
                            // window.location.assign(process.env.react_app_base_url_base + `/${directURL ?? 'thank-you'}`)
                        }
                    } catch (error) {
                        alert('something went wrong, please try again');
                    }
                },
                prefill: {
                    name: patient_name ?? '',
                    email: email_id ?? '',
                    contact: mobile ?? '',
                }
            }
            const paymentObject = new (window as any).Razorpay(options)
            paymentObject.open()
        } else {
            alert('Failed');
        }

    } catch (error: any) {
        alert('Failed');
        console.log('placeorder API error', error.response.data.messages);
        const message = error.response.data.messages;
        toast(message)
    }
}