import react from "react";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomTooltip from "../../../Library/Tooltip/Tippy";
import { Button } from "../../../Library/Module";


interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: any;
}

const QuickChatRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId
}: AlertProps): JSX.Element => {

    const files = typeof rowData?.details?.file_path === 'object' ? rowData?.details?.file_path && rowData?.details?.file_path?.map((item: any) => {
        console.log("item", item)
        return <a className="mr-2" href={item}>Download</a>
    }) : ''
    console.log("rowData", rowData)

    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <p>Name - {rowData?.details?.full_name}</p>
                        <p>age - {rowData?.details?.age}</p>
                        <p>city - {rowData?.details?.city}</p>
                        <p>gender  - {rowData?.details?.gender}</p>
                    </>}>
                    {rowData?.details?.full_name}
                </CustomTooltip>
            </td>
            <td>{rowData?.details?.medical_background_problems}</td>
            <td>{rowData?.details?.my_query}</td>
            <td><CustomTooltip
                title={"title"}
                position={"bottom"}
                content={<>
                    <h5>Download</h5>
                    <ul className="listItem">
                        {files}
                    </ul>
                </>}>
                Download
            </CustomTooltip></td>
            <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
            <td>{rowData.created_by}</td>
            <td><Button onClick={() => callbackId?.(rowData)}>Doctor's Reply</Button></td>
        </tr>
    );
};

export default QuickChatRow;
