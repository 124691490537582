import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetServices, GetSessionStatus, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import { objectToQueryString } from "../../Library/Utility/Utility";

const Cancel = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams();
    const session_id = searchParams.get("session_id")
    const [statusDetails, setStatusDetails] = useState<any>({})
    const [apiStatus, setApiStatus] = useState<any>(false)
    const errorMsg = 'We are not able to process your request. Please check your payment details in your dashboard.'
    const [isSuccess, setIsSuccess] = useState('')

    useEffect(() => {
        if (session_id && session_id?.length === 66) {
            toast.error('Payment Failed, We have not received your payment.')
        } else {
            toast.error(errorMsg)
            setApiStatus(false)
        }
    }, [session_id])

    console.log("session_id", session_id, statusDetails)

    return (
        <>
            <Wrapper pageTitle={'Payment Failed'} pageDes={`We have not received your payment.`}></Wrapper>
        </>
    );
};
export default Cancel;
