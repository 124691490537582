import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Button } from "../../Library/Module";
import { CMSByType, GetServices } from "../../servies/services";
import { objectToQueryString } from "../../Library/Utility/Utility";
type defaultType = { id: string; name: string };
type ThemeContextType = {
    orgUser: defaultType;
    updateOrgUser: (data: defaultType) => void;
    updateBgColor: (data: string) => void;
    bgTextColor: string;
    breadcrumb: React.ReactNode;
    updateBreadcrumb: (data: any) => void;
    mainCategory: any;
    sliderList: any;
    whyUsList: any;
    catlogData: any;
    updateCatlogData: (data: any) => void;
    testimonilasList: any;

};
export const ThemeContext = createContext<ThemeContextType>({
    orgUser: { id: "", name: "" },
    updateOrgUser: (data: defaultType) => null,
    updateBgColor: (data: string) => null,
    bgTextColor: "",
    breadcrumb: "",
    updateBreadcrumb: (data: any) => null,
    mainCategory: [],
    sliderList: [],
    whyUsList: [],
    testimonilasList: [],
    catlogData: [],
    updateCatlogData: (data: any) => null,
});

const { Provider } = ThemeContext;

export const ThemeConsumer = ThemeContext.Consumer;

const defaultValue: defaultType = { id: "", name: "" };

type ThemeProviderProps = {
    children: JSX.Element;
};

const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {
    const navigate = useNavigate();
    const [orgUser, setOrgUser] = useState(defaultValue);
    const [bgTextColor, setBgTextColor] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<any>("");
    const [catlogData, setCatlogData] = useState<any>([]);

    const updateCatlogData = useCallback((data) => {
        setCatlogData(data);
    }, []);

    const updateOrgUser = useCallback((data) => {
        setOrgUser(data);
    }, []);

    const updateBgColor = useCallback((data) => {
        setBgTextColor(data);
    }, []);

    const updateBreadcrumb = useCallback((data) => {
        const abc: React.ReactNode = () => {
            const back = data?.length > 2 ? data?.[data.length - 2] : data?.[0];
            return (
                <>
                    <div className="breadcrub">
                        <div className="container">
                            <ul>
                                {data?.map((item: any) => {
                                    return (
                                        <li>
                                            <NavLink to={item?.link}>{item?.name}</NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="float-right">
                                <Button
                                    onClick={() => navigate(back?.link)}
                                    buttonSize="sm"
                                    buttonStyleType="primary"
                                >
                                    Back
                                </Button>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </>
            );
        };
        setBreadcrumb(abc);
    }, []);

    const [mainCategory, setMainCategory] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const APIResponse = await GetServices();
            if (APIResponse?.data?.isSuccess === true) {
                let updatedList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setMainCategory(APIResponse?.data?.data);
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [])

    const [testimonilasList, setTestimonilasList] = useState([]);
    const getData = async () => {
        const APIResponse = await CMSByType('testimonials');
        if (APIResponse?.data?.isSuccess === true) {
            setTestimonilasList(APIResponse?.data?.data);
        } else {
            console.error("something went wrong, please try after sometime.")
        }
    };
    useEffect(() => {
        getData();
    }, [])

    const [sliderList, setSliderList] = useState([]);
    const getSliderList = async () => {
        const APIResponse = await CMSByType('slider');
        if (APIResponse?.data?.isSuccess === true) {
            setSliderList(APIResponse?.data?.data);
        } else {
            console.error("something went wrong, please try after sometime.")
        }
    };
    useEffect(() => {
        getSliderList();
    }, [])


    const [whyUsList, setWhyUsList] = useState([]);
    const getWhyUsList = async () => {
        const APIResponse = await CMSByType('why-us');
        if (APIResponse?.data?.isSuccess === true) {
            setWhyUsList(APIResponse?.data?.data);
        } else {
            console.error("something went wrong, please try after sometime.")
        }
    };
    useEffect(() => {
        getWhyUsList();
    }, [])

    return (
        <>
            <Provider
                value={{
                    orgUser,
                    updateOrgUser,
                    bgTextColor,
                    updateBgColor,
                    breadcrumb,
                    updateBreadcrumb,
                    mainCategory,
                    catlogData,
                    updateCatlogData,
                    testimonilasList,
                    sliderList,
                    whyUsList
                }}
            >
                {children}
            </Provider>
        </>
    );
};

export { ThemeProvider };
