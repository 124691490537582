import react, { useState, useMemo, useEffect } from "react";
import { Button } from "../Module";

interface AlertProps {
    tabList?: any,
    className?: any,
    children?: any,
    tabSelected: (data: any) => void
}
const TabComp = ({
    tabList,
    className,
    children,
    tabSelected
}: AlertProps): JSX.Element => {
    const [selected, setSelected] = useState(tabList?.[0].name)
    const handdleClick = (data: any) => {
        console.log(data)
        tabSelected(data)
        setSelected(data)
    }

    return (
        <>
            <div className="tab">
                <div className="tab_nav">
                    <ul>
                        {tabList?.map((item: any) => {
                            return (
                                <>
                                    <li className={selected === item.name ? 'active' : ''} onClick={() => handdleClick(item.name)}>{item.name}</li>
                                </>
                            )
                        })}
                    </ul>
                    {children}
                </div>
            </div>
        </>
    );
};

export default TabComp;
