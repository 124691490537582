import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CreateMedia, GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Radio, Select } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import InputFile from "../../Library/File/File";
import { loadStripe } from "@stripe/stripe-js";
import { displayRazorpay } from "../OrderSummery/OrderSummery";
import moment from "moment";


const QuickSMSForm = ({
    details
}: { details: any }) => {
    const [formData, setFormData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };


    // @ts-ignore
    const timeDate = JSON.parse(localStorage.getItem("time_date"));
    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

    const onChangeFileSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const genderOptions = [
        {
            "text": "Male",
            "value": "male",
            "id": 1
        }, {
            "text": "Female",
            "value": "female",
            "id": 2
        }, {
            "text": "Other",
            "value": "other",
            "id": 3
        },
    ]

    const [userType, setUserType] = useState('mobile')
    const onValueChange = (value: string) => {
        return setUserType(value)
    }


    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        if (formData?.full_name && formData?.age && formData?.city && formData?.age && formData?.medical_background_problems
            && formData?.my_query) {
            if (formData?.mobile || formData?.email_Id) {
                setIsValid(true)
            }
        }
    }, [formData])


    const Submit = async () => {
        if (
            details?.title !== undefined &&
            details?.title.length !== 0
        ) {
            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
            // @ts-ignore

            let fileNamePath: any = []
            if (formData?.medical_report) {
                console.log("formData?.medical_report", formData?.medical_report)
                const formData1 = new FormData();
                const files = Array.from(formData?.medical_report).map((i: any) => {
                    return formData1.append("file", i);
                })
                console.log("files", files)
                formData1.append("email_id", formData?.email_Id);
                formData1.append("type", "pythology");
                console.log(JSON.stringify(formData1));
                const filePath = await CreateMedia(formData1);
                console.log("CreateMedia", filePath?.data?.data);
                if (filePath?.data?.data?.length) {
                    filePath?.data?.data?.map((path: any) => {
                        fileNamePath?.push(path?.path)
                    })
                } else {
                    fileNamePath?.push(filePath?.data?.data?.path)
                }
            }
            console.log("fileNamePath", fileNamePath)

            displayRazorpay({
                user_details_frontend: user_details_frontend,
                plan_name: details?.title,
                service_type: details?.service_type,
                appointment_date_time: new Date(),
                email_id: formData?.email_Id,
                mobile: formData?.mobile,
                patient_name: formData?.full_name,

                year: moment().format("YYYY"),
                month: moment().format("MM"),
                day: moment().format("DD"),
                date: moment(),
                time: moment().format("hh/mm"),
                internal_id: moment(),

                details: {
                    full_name: formData?.full_name,
                    age: formData?.age,
                    city: formData?.city,
                    gender: formData?.gender,
                    medical_background_problems: formData?.medical_background_problems,
                    my_query: formData?.my_query,
                    reply: userType,
                    file_path: fileNamePath,
                },
                price: details?.price,
                discount: "0",
                amount: (details?.price - Number(0)).toString(),
            })


        } else {
            toast.error("Please enter required fields");
        }
    }
    return (
        <div className="col-md-4 mb-4">
            <div className="inner">
                <div className="row">
                    <h3>Form</h3>
                    <Input
                        inputName="full_name"
                        inputType="text"
                        labelName={"Name of patient"}
                        placeholder="Enter Name of patient"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="6"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.full_name}
                    />

                    <Input
                        inputName="age"
                        inputType="text"
                        labelName={"Age"}
                        placeholder="Enter Age"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="6"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.age}
                    />

                    <Select
                        inputName={'gender'}
                        labelName={'Gender'}
                        options={genderOptions ?? []}
                        onChangeSingleCallback={onChangeSingleCallback}
                        selectedItem={undefined}
                        required={true}
                        placeholder={'Select Gender'}
                        search_option={false}
                        isLoading={true}
                        value={formData?.gender}
                        col="6"
                    ></Select>

                    <Input
                        inputName="city"
                        inputType="text"
                        labelName={"City"}
                        placeholder="Enter City"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="6"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.city}
                    />
                    <p>Do want reply on Email or Whatsapp </p>
                    <div className="mb-3 col-md-12">
                        <span className="mr-3">
                            <input
                                type="radio"
                                value='mobile'
                                checked={userType === "mobile"}
                                onChange={(e) => onValueChange(e.target.value)}
                                name="user_type"
                            /> <label className="inline" onClick={() => onValueChange("mobile")}>Whatsapp</label>
                        </span>
                        <input
                            type="radio"
                            value='email_id'
                            checked={userType === "email_id"}
                            onChange={(e) => onValueChange(e.target.value)}
                            name="user_type"
                        /><label className="inline" onClick={() => onValueChange("email_id")}>Email Id</label>
                    </div>
                    <Input
                        inputName="mobile"
                        inputType="text"
                        labelName={"Mobile"}
                        placeholder="Enter Mobile"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.mobile}
                    />
                    <Input
                        inputName="email_Id"
                        inputType="text"
                        labelName={"Email Id"}
                        placeholder="Enter Email Id"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.email_Id}
                    />
                    <Input
                        inputName="medical_background_problems"
                        inputType="textarea"
                        labelName={"Medical background problems"}
                        placeholder="Enter Medical background problems"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.medical_background_problems}
                    />

                    <Input
                        inputName="my_query"
                        inputType="textarea"
                        labelName={"My Query/Question"}
                        placeholder="Enter My Query/Question"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.my_query}
                    />
                    <InputFile onChangeFileSingleCallback={onChangeFileSingleCallback} inputName="medical_report" />

                    <div className="col-md-6 text-right"></div>
                    <div className="col-md-6 text-right">
                        <Button
                            onClick={() => Submit()}
                            buttonText={"Pay Now"}
                            buttonStyleType={"primary"}
                            theme={theme}
                            disabled={!isValid}>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QuickSMSForm
