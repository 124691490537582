/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { Button, Card, CustomTable, SideDrawer } from "../../../Library/Module";
import MyAccountWrapper from "../MyAccountWrapper";
import PythologyReportRow from "./PythologyReportRow";
import { GetAppointments, GetFamily, GetFamilyDetailById, GetMedicalHistory, GetMedicalHistoryByAppointment } from "../../../servies/services";
import { NavLink, useParams } from "react-router-dom";
import { objectToQueryString } from "../../../Library/Utility/Utility";
import moment from "moment";

const tableConfig = {
  search: {
    name: "created_on",
    isAsc: false,
  },
  sortDefault: {
    name: "created_on",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "full_name",
      display: "full_name",
      sort: false,
    },
    // {
    //     name: "city",
    //     display: "city",
    //     sort: false,
    // },
    // {
    //     name: "mobile",
    //     display: "mobile",
    //     sort: false,
    // },
    // {
    //     name: "email_Id",
    //     display: "email_Id",
    //     sort: false,
    // },
    {
      name: "biopsy",
      display: "biopsy",
      sort: false,
    },
    {
      name: "biopsy_done_at",
      display: "biopsy_done_at",
      sort: false,
    },
    {
      name: "specimen",
      display: "specimen",
      sort: false,
    },
    {
      name: "slides",
      display: "slides",
      sort: false,
    }, {
      name: "file_path",
      display: "File",
      sort: false,
    },
    {
      name: "created_on",
      display: "Created Date",
      sort: true,
    },
    {
      name: "created_by",
      display: "created_by",
      sort: false,
    },
    {
      name: "created_by",
      display: "Doctor's Opinion",
      sort: false,
    }
    
  ],
};



const PythologyReport = (): JSX.Element => {
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [showCells, setShowCells] = useState(null);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [actionType, setActionType] = useState<any>({});
  const { type } = useParams()
  const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();

  const [userQuery, setUserQuery] = useState<any>([]);
  const [userSolutions, setUserSolutions] = useState<any>([]);

  const pageData = `Pythology Report Management`
  const pageDescData = `You can manage the ${pageData} in this module.`
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const callbackFresh = (data: any) => {
    setUserSolutions([])
    setUserQuery([])
    console.log('data', data)
    setActionType({
      type: "add",
      id: 'id',
      data: data
    })

    const GetData2 = async () => {
      const query = {
        "appointment_date_time": data?.appointment_date_time,
        "submitted_by": "doctor",
        "submitted_type": "solutions",
        user_id: data?.user_id
      }
      const APIResponse = await GetMedicalHistoryByAppointment(objectToQueryString(query));
      console.log("GetMedicalHistory", APIResponse?.data?.data)
      setUserSolutions(APIResponse?.data?.data)
    }
    if (data?.appointment_date_time) {
      GetData2()
    }

  }

  const showCellCallback = () => {

  }

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";

    const getData = async () => {
      const query = {
        "payment_status": "paid",
        "service_type": 'Pathology',
        // "sortBy":'appointment_date_time'
      }
      const apidata = await GetAppointments(user_details_frontend?.id, objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      console.log('APIResponse', APIResponse)
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI, type]);

  const CloseDrawer = () => {
    setActionType({});
  };


  return (
    <MyAccountWrapper>
      <CustomTable
        header={tableConfig.tableHeader}
        sortDefault={tableConfig.sortDefault}
        data={tableData}
        tableTitle={pageData}
        tableSubTitle={pageDescData}
        dropdown={""}
        search_bar={false}
        showCellCallback={showCellCallback}
        sortState={sortState}
        isLoading={isLoading}
        headerAction={<NavLink to={'/services'}><Button buttonStyleType="primary">Book An Appointment</Button></NavLink>}
      >
        {({ rowData, index }: { rowData: any; index: any }) => (
          <PythologyReportRow
            rowData={rowData}
            index={index}
            showCells={showCells}
            reloadData={reloadData}
            callbackId={callbackFresh}
          ></PythologyReportRow>
        )}
      </CustomTable>
      {(actionType?.type === "add" || actionType?.type === "edit") && (
        <SideDrawer
          size={'750px'}
          pagetitle={`Doctor's Opinion Details`}
          action={CloseDrawer}
        >
          <Card>
            <h5>Doctor's Opinion</h5>
            {userSolutions?.map((problem: any) => {
              const files = typeof problem?.file_path === 'object' ? problem?.file_path && problem?.file_path?.map((item: any) => {
                console.log("item", item)
                return <a className="mr-2 btn btn-primary d-inline-block mr-2" href={item}>Download</a>
              }) : ''
              console.log("files", typeof problem?.file_path, problem?.file_path)
              return (
                <div className="info">
                  <p><strong>Solutions</strong></p>
                  <p>{problem?.solutions}</p>
                  <p><strong>Submitted At</strong></p>
                  <p>{moment(problem?.created_on).format("DD/MM/YYYY hh:mm:ss a")}</p>
                  {files}
                  <div className="clearfix"></div>
                </div>
              )
            })}
          </Card>
        </SideDrawer>
      )}
    </MyAccountWrapper>
  );
};
export default PythologyReport;
