import react, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Button, Alert, Tag } from "../../Library/Module";
import { DeleteBookAppointment, DeleteCityService, DeleteFamily } from "../../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";




import { getColorCode, GetTimeFrame } from "../../Library/Utility/Utility";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: any;
}

const MedicalHistoryRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId
}: AlertProps): JSX.Element => {
    const navigate = useNavigate();

    const editPage = () => {
        navigate(`/questionnaire`);
    };

    const DeleteCity = () => {
        DeleteCityAPI()
        // confirmAlert({
        //   title: `Are you sure you want to delete this ${rowData.title}?`,
        //   message: `This Item will be deleted immediately. You can't undo this action.`,
        //   buttons: [
        //     {
        //       label: "Yes",
        //       onClick: () => DeleteCityAPI(),
        //     },
        //     {
        //       label: "No",
        //       onClick: () => "",
        //     },
        //   ],
        // });
    };

    const DeleteCityAPI = async () => {
        const APIResponse = await DeleteFamily(rowData._id);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success(APIResponse?.data?.messages);
            reloadData(true);
        } else {
            toast.error("Something went wrong, please try again");
        }
    };

    const showSchedule = () => {
        callbackId(rowData)
    }
    const getcolorCodes = useMemo(() => {
        return getColorCode(rowData.meeting_room_name)
    }, [rowData.meeting_room_name])
    return (
        <>
            <tr key={index}>
                <td>{rowData.name}</td>
                <td>{rowData?.date}</td>
                <td> {rowData?.meeting_type}</td>
                
                <td>{moment(rowData?.created_on).format("DD/MM/YYYY")}</td>
                <td>
                    <div className="flex">
                        <Button
                            onClick={() => DeleteCity()}
                            buttonStyleOutline
                            buttonStyleType="danger"
                        >
                            <span className="material-symbols-outlined">
                            visibility
                            </span>
                        </Button>
                       
                        {/* <Button
                            onClick={() => editPage()}
                            buttonStyleOutline
                            buttonStyleType="primary"
                            disabled={rowData.meeting_type === 'Recurring Meeting'}
                        >
                            <span className="material-symbols-outlined">
                                edit
                            </span>
                        </Button> */}

                    </div>
                </td>

            </tr>
        </>
    );
};

export default MedicalHistoryRow;
