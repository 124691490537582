import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CreateMedia, GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Select } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";

import InputFile from "../../Library/File/File";
import { displayRazorpay } from "../OrderSummery/OrderSummery";
import moment from "moment";
import { mainAddress } from "../../Library/Utility/Utility";
const PathologyForm = ({
    details
}: { details: any }) => {
    const [formData, setFormData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };



    const Options = [
        {
            "text": "Block",
            "value": "Block",
            "id": 1
        }, {
            "text": "Slide",
            "value": "Slide",
            "id": 2
        }
    ]
    const genderOptions = [
        {
            "text": "Male",
            "value": "male",
            "id": 1
        }, {
            "text": "Female",
            "value": "female",
            "id": 2
        }, {
            "text": "Other",
            "value": "other",
            "id": 3
        },
    ]

    const biopsyDoneAt = [
        {
            "text": "Hospital",
            "value": "Hospital",
            "id": 1
        }, {
            "text": "Clinic",
            "value": "Clinic",
            "id": 2
        }, {
            "text": "Other",
            "value": "other",
            "id": 3
        },
    ]

    const SlideOptions = [
        {
            "text": "1 Slide - 1000 Rs",
            "value": "1000",
            "id": 1
        }, {
            "text": "2 Slides - 1500 Rs",
            "value": "1500",
            "id": 1
        },
        {
            "text": "4 Slides - 2500 Rs",
            "value": "2500",
            "id": 1
        },
        {
            "text": "More than 4 Slides - 4000 Rs",
            "value": "4000",
            "id": 1
        }
    ]

    // @ts-ignore
    const timeDate = JSON.parse(localStorage.getItem("time_date"));
    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        if (formData?.full_name &&
            formData?.city &&
            formData?.mobile &&
            formData?.email_Id &&
            formData?.biopsy &&
            formData?.biopsy_done_at &&
            formData?.specimen &&
            formData?.slides) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [formData?.biopsy, formData?.biopsy_done_at, formData?.city, formData?.email_Id, formData?.full_name, formData?.mobile, formData?.slides, formData?.specimen])


    const onChangeFileSingleCallback = (data: any) => {
        console.log("data onChangeFileSingleCallback", data)
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };


    const Submit = async () => {
        if (
            details?.title !== undefined &&
            details?.title.length !== 0 &&
            formData?.full_name &&
            formData?.city &&
            formData?.mobile &&
            formData?.email_Id &&
            formData?.biopsy &&
            formData?.biopsy_done_at &&
            formData?.specimen &&
            formData?.slides
        ) {
            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

            let fileNamePath: any = []
            if (formData?.medical_report) {
                console.log("formData?.medical_report", formData?.medical_report)
                const formData1 = new FormData();
                const files = Array.from(formData?.medical_report).map((i: any) => {
                    return formData1.append("file", i);
                })
                console.log("files", files)
                formData1.append("email_id", formData?.email_Id);
                formData1.append("type", "pythology");
                console.log(JSON.stringify(formData1));
                const filePath = await CreateMedia(formData1);
                console.log("CreateMedia", filePath?.data?.data);
                if (filePath?.data?.data?.length) {
                    filePath?.data?.data?.map((path: any) => {
                        fileNamePath?.push(path?.path)
                    })
                } else {
                    fileNamePath?.push(filePath?.data?.data?.path)

                }
            }
            console.log("fileNamePath", fileNamePath)

            displayRazorpay({
                user_details_frontend: user_details_frontend,
                plan_name: details?.title,
                service_type: details?.service_type,
                appointment_date_time: new Date(),
                mobile: formData?.mobile,
                email_Id: formData?.email_Id,

                year: moment().format("YYYY"),
                month: moment().format("MM"),
                day: moment().format("DD"),
                date: moment(),
                time: moment().format("hh/mm"),
                internal_id: moment(),

                details: {
                    "full_name": formData?.full_name,
                    "city": formData?.city,
                    "mobile": formData?.mobile,
                    "email_Id": formData?.email_Id,
                    "biopsy": formData?.biopsy,
                    "biopsy_done_at": formData?.biopsy_done_at,
                    "specimen": formData?.specimen,
                    "slides": formData?.slides,
                    "file_path": fileNamePath,
                },
                price: formData?.slides,
                discount: "0",
                amount: (formData?.slides - Number(0)).toString(),
                directURL: 'thank-you'
            })


        } else {
            toast.error("Please enter required fields");
        }
    }

    return (
        <>
            <div className="col-md-4 mb-4">
                <div className="inner">
                    <h3>Form</h3>
                    <Input
                        inputName="full_name"
                        inputType="text"
                        labelName={"Name of patient"}
                        placeholder="Enter Name of patient"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.full_name}
                    />

                    <Input
                        inputName="city"
                        inputType="text"
                        labelName={"City"}
                        placeholder="Enter City"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.city}
                    />

                    <Input
                        inputName="mobile"
                        inputType="text"
                        labelName={"Mobile"}
                        placeholder="Enter Mobile"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.mobile}
                    />

                    <Input
                        inputName="email_Id"
                        inputType="text"
                        labelName={"Email Id"}
                        placeholder="Enter Email Id"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.email_Id}
                    />

                    <Input
                        inputName="biopsy"
                        inputType="text"
                        labelName={"Medical problem for which Biopsy has done"}
                        placeholder="Enter Medical problem for which Biopsy has done"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.biopsy}
                    />

                    <Select
                        inputName={'biopsy_done_at'}
                        labelName={'Biopsy done at'}
                        options={biopsyDoneAt ?? []}
                        onChangeSingleCallback={onChangeSingleCallback}
                        selectedItem={undefined}
                        required={true}
                        placeholder={'Select Biopsy done at'}
                        search_option={false}
                        isLoading={true}
                        value={formData?.biopsy_done_at}
                    ></Select>

                    <Select
                        inputName={'specimen'}
                        labelName={'Specimen'}
                        options={Options ?? []}
                        onChangeSingleCallback={onChangeSingleCallback}
                        selectedItem={undefined}
                        required={true}
                        placeholder={'Select Specimen'}
                        search_option={false}
                        isLoading={true}
                        value={formData?.specimen}
                    ></Select>

                    <Select
                        inputName={'slides'}
                        labelName={'Slides'}
                        options={SlideOptions ?? []}
                        onChangeSingleCallback={onChangeSingleCallback}
                        selectedItem={undefined}
                        required={true}
                        placeholder={'Select Slides'}
                        search_option={false}
                        isLoading={true}
                        value={formData?.slides}
                    ></Select>

                    <InputFile multiple={true} onChangeFileSingleCallback={onChangeFileSingleCallback} inputName="medical_report" />


                    <Button
                        onClick={() => Submit()}
                        buttonText={`Pay ${formData?.slides ? formData?.slides : ''}`}
                        buttonStyleType={"primary"}
                        theme={theme}
                        disabled={!isValid}
                    >
                    </Button>


                    <div className="note">
                        <p><strong>Address</strong> <br></br>
                        <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span>
                        </p>
                    </div>

                    <div className="note">
                        <strong>Timings</strong> <br></br> Mon-Fri 9AM - 5PM
                    </div>
                </div>
            </div>
        </>
    )
}
export default PathologyForm