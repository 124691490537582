import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import SimpleSlider from "../../components/Slider";
import { ThemeContext } from "../../Context/Theme/Context";
import { useContext, useState } from "react";
import TestimonialsSlider from "../../components/TestimonialsSlider";
import Note1 from "../../images/note1.jpeg"
import Note2 from "../../images/note2.jpeg"
import Note3 from "../../images/note3.jpeg"
import TNote1 from "../../images/tnote1.jpg"
import TNote2 from "../../images/tnote2.jpg"
import TNote3 from "../../images/tnote3.jpg"
import { SideDrawer } from "../../Library/Module";

const Home = () => {
    const { mainCategory } = useContext(ThemeContext)
    const navigate = useNavigate();
    const clickHandle = (data: any) => {
        navigate(`/services/${data?.slug}`);
        localStorage.setItem('selected_services', JSON.stringify(data))
    }
    const [actionType, setActionType] = useState<any>({});
    const imageOnClick = (imageName: any) => {
        setActionType({
            type: "add",
            id: 'id',
            data: imageName
        })
    }
    const CloseDrawer = () => {
        setActionType({});
    };

    return (
        <Wrapper>
            <SimpleSlider></SimpleSlider>
            <div className="homeServices servicesListHome">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <h1 className="heading text-center">Our Services</h1>
                        {mainCategory?.map((service: any) => {
                            return (
                                <div className="col-md-3 mb-4 ">
                                    <div className="inner flex align-items-center justify-content-center">
                                        <NavLink to={`/services/${service?.slug}`} onClick={() => clickHandle(service)}>
                                            <h2 dangerouslySetInnerHTML={{ __html: service?.title }}></h2>
                                            <p className="type">Type : {service?.service_type}</p>
                                            {service?.service_type === "Pathology" ? '' : <p>Duration : {service?.duration} min</p>}
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="meetOurCEO">
                <h1 className="heading text-center">Testimonials</h1>
                <p className="sub-heading text-center">Increase credibility by Showing Testimonials and Clients</p>
                <div className="meetOurCEOInner">
                    <div className="container-fluid">
                        <TestimonialsSlider></TestimonialsSlider>
                    </div>
                </div>
            </div>


            <div className="homeServices servicesListHome">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <h1 className="heading text-center">Awards</h1>

                        <div className="col-md-3 mb-4 ">
                            <img onClick={() => imageOnClick(Note1)} src={TNote1} alt="" title="" />
                        </div>

                        <div className="col-md-3 mb-4 ">
                            <img onClick={() => imageOnClick(Note2)} src={TNote2} alt="" title="" />
                        </div>

                        <div className="col-md-3 mb-4 ">
                            <img onClick={() => imageOnClick(Note3)} src={TNote3} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>

            {actionType?.type === "add" && (
                <SideDrawer
                    size={'750px'}
                    pagetitle={`Award Details`}
                    action={CloseDrawer}
                    type="modal"
                >
                    <div className="inner">
                        <img src={actionType?.data} alt="" title="" />
                    </div>
                </SideDrawer>
            )}
        </Wrapper>
    );
};
export default Home;
