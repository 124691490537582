import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm'
import { loadStripe } from "@stripe/stripe-js";
import { Button } from '../Library/Module';

function Payment(props) {
  // const { stripePromise } = props;
  // const [clientSecret, setClientSecret] = useState('');

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   fetch("/create-payment-intent")
  //     .then((res) => res.json())
  //     .then(({ clientSecret }) => setClientSecret(clientSecret));
  // }, []);

  const [product, setProduct] = useState({
    name: "Go FullStack with KnowledgeHut",
    price: 1000,
    productOwner: "KnowledgeHut",
    description: "This beginner-friendly Full-Stack Web Development Course is offered online in blended learning mode, and also in an on-demand self-paced format.",
    quantity: 1,
  });


  const makePayment = async () => {
    const stripe = await loadStripe("pk_test_51O25mhSGukGk5MZ2KJYQjFzawUXN7BsOjETBD6bLLZX1IUi2pALxlF4nEqM8PZs4x40tSDMZEUPyvoLIFpcdAc1b00bmOoNYgk");
    const body = { product };
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      "https://dranandjoshi.com/nodeapi/api/v1/order/create-checkout-session",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  };


  return (
    <>
      <Button variant="primary" onClick={() => makePayment()}>
        Buy Now for {product.price}
      </Button>
    </>
  );
}

export default Payment;