import React from "react";
import Wrapper from "../Wrapper";
import { mainAddress } from "../../Library/Utility/Utility";

const RefundPolicy = () => {
    return (
        <Wrapper
            pageDes={`At Dr. Annad Joshi, we strive to provide high-quality healthcare services. We understand that unforeseen circumstances may arise, and we want to be transparent about our refund policy. This policy applies to all appointments booked through our website or contact details.`}
            pageTitle="Refund Policy">
            <div className="sectionInner">
                <div className="container pt-4 pb-4">
                    <div className="row align-items-center justify-content-center pb-3 pt-3">
                    <div className="col-md-12 col-sm-12 cms_pages">
                        <p>At Dr. Annad Joshi, we strive to provide high-quality healthcare services. We understand that unforeseen circumstances may arise, and we want to be transparent about our refund policy. This policy applies to all appointments booked through our website or contact details.</p>
                        <h5>1. General Policy:</h5>
                        <ul>
                            <li>Refunds will be considered on a case-by-case basis.</li>
                            <li>To request a refund, please contact us immediately via +91 98660 35911 or info@dranandjoshi.com</li>
                            <li>We strongly encourage you to notify us no later than 7 hours before your scheduled appointment time.</li>
                            <li>All refund requests will be reviewed by our administrative team within 7 business days.</li>
                        </ul>
                        <h5>2. Approved Reasons for Refund:</h5>
                        <p>Doctor cancellation: If Dr. Anand Joshi needs to cancel your appointment due to unforeseen circumstances, you will receive a full refund.</p>
                        <p>Double booking: If you are accidentally double-booked for the same time slot, you will receive a full refund for the duplicate appointment.</p>
                        <p>Medical Emergencies: If you experience a medical emergency that prevents you from attending your appointment, you may be eligible for a full or partial refund, depending on the circumstances. Documentation from a healthcare professional may be required.</p>
                        <p>Insurance issues: If your insurance unexpectedly doesn't cover your appointment, you may be eligible for a partial refund.</p>

                        <h5>3. Non-Refundable Situations:</h5>
                        <p>Patient no-show: If you miss your appointment without prior notice, you will not be eligible for a refund.</p>
                        <p>Late cancellations: If you cancel your appointment within 7 hours of the scheduled time, you will not be eligible for a refund.</p>
                        <p>Change of mind: If you simply change your mind about needing an appointment, you will not be eligible for a refund.</p>

                        <h5>4. Payment Methods:</h5>
                        <p>Refunds will be issued using the same payment method used for booking the appointment.</p>
                        <p>Please allow 7 business days for the refund to appear in your account.</p>

                        <h5>5. Questions and Concerns:</h5>
                        <p>If you have any questions or concerns about our refund policy, please do not hesitate to contact us at +91 98660 35911 or info@dranandjoshi.com. We are always happy to help.</p>

                        <h5>Additional Considerations:</h5>

                        <ul>
                            <li>You may need to add specific details depending on your practice and jurisdiction, such as:</li>
                            <li>Cancellation fees, if applicable.</li>
                            <li>Alternative dispute resolution processes.</li>
                            <li>Specific timeframe for receiving refunds.</li>
                            <li>It is recommended to consult with a lawyer to ensure your refund policy complies with all applicable laws and regulations.</li>
                        </ul>

                        <div className="innerContactdetails">
                                <ul>
                                    <li><span className="material-symbols-outlined"> mail </span> <span>info@dranandjoshi.com </span></li>
                                    <li><span className="material-symbols-outlined"> call </span> <span>+91 98660 35911</span> </li>
                                    <li><span className="material-symbols-outlined"> pin_drop </span> <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    );
};
export default RefundPolicy;


