import React from "react";
import Wrapper from "../Wrapper";
import { mainAddress } from "../../Library/Utility/Utility";

const PrivacyPolicy = () => {
    return (
        <Wrapper
            pageDes={` This privacy policy explains how Dr Anand Joshi, we collects, uses, and protects your personal information when you visit our website.`}
            pageTitle="Privacy Policy">
            <div className="sectionInner">
                <div className="container pt-4 pb-4">
                    <div className="row align-items-center justify-content-center pb-3 pt-3">
                        <div className="col-md-12 col-sm-12 cms_pages">
                            <p>This privacy policy explains how Dr Anand Joshi, we collects, uses, and protects your personal information when you visit our website https://dranandjoshi.com/.</p>
                            <h5>Information We Collect:</h5>
                            <p>Personally Identifiable Information (PII): This includes information that identifies you, such as your name, email address, phone number, date of birth, gender, medical history, insurance information, and appointment details. We collect PII when you:</p>
                            <ul>
                                <li>Schedule appointments through our website.</li>
                                <li>Request patient forms.</li>
                                <li>Contact us through our website form or email.</li>
                                <li>Use our patient portal.</li>
                                <li>Non-Personally Identifiable Information (NPII): This includes information that does not identify you, such as your browser type, IP address, date and time of access, and pages you visit. We collect NPII through cookies and analytics tools to improve our website and understand how visitors use it.</li>
                            </ul>
                            <h5> How We Use Your Information:</h5>
                            <p>We use PII to provide you with healthcare services, such as scheduling appointments, sending appointment reminders, communicating with you about your care, and processing insurance claims.</p>
                            <p>We use NPII to analyze website traffic, personalize your experience, and improve our website.</p>
                            <h5> Disclosure of Your Information:</h5>
                            <ul>
                                <li>We will not share your PII with third parties without your consent, except:</li>
                                <li>To providers who need access to your information to provide you with care.</li>
                                <li>To fulfill legal requirements, such as reporting to law enforcement or public health authorities.</li>
                                <li>To comply with court orders or subpoenas.</li>
                                <li>We may share NPII with third-party analytics providers to help us understand how visitors use our website. These providers are contractually obligated to keep your information confidential.</li>
                            </ul>

                            <h5> Data Security:</h5>

                            <p></p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. We use encryption technologies and security measures to protect your PII.

                            <h5>Your Choices:</h5>

                            <p>You can manage your cookie preferences through your browser settings.</p>

                            <h5> Changes to This Policy:</h5>

                            <p>We may update this privacy policy from time to time. We will post any changes on our website.
                            </p>
                            <h5>Contact Us:</h5>

                            <p>If you have any questions about this privacy policy, please contact us at info@dranandjoshi.com or +91 98660 35911.</p>

                            <h5>Additional Considerations:</h5>

                            <p>You may need to add specific sections depending on your practice and jurisdiction, such as:</p>
                            <ul>
                                <li>Information about HIPAA compliance.</li>
                                <li>How long you retain patient records.</li>
                                <li>How patients can access their medical records.</li>
                                <li>Your policy on telemedicine and online appointments.</li>
                                <li>It is recommended to consult with a lawyer to ensure your privacy policy complies with all applicable laws and regulations.</li>
                            </ul>
                            <div className="innerContactdetails">
                                <ul>
                                    <li><span className="material-symbols-outlined"> mail </span> <span>info@dranandjoshi.com </span></li>
                                    <li><span className="material-symbols-outlined"> call </span> <span>+91 98660 35911</span> </li>
                                    <li><span className="material-symbols-outlined"> pin_drop </span> <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>

    );
};
export default PrivacyPolicy;


