/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { CustomTable } from "../../Library/Module";
import MyAccountWrapper from "./MyAccountWrapper";
import MyAppointmentRow from "./MyAppointmentsRow";
import { GetFamily } from "../../servies/services";
import MedicalHistoryRow from "./MedicalHistoryRow";


const tableConfig = {
    search: {
        name: "id",
        isAsc: false,
    },
    sortDefault: {
        name: "created_on",
        isAsc: false,
    },
    tableHeader: [
        {
            name: "name",
            display: "Name",
            sort: false,
        },
        {
            name: "date",
            display: "Date",
            sort: true,
        },
        {
            name: "meeting_type",
            display: "Meeting Type",
            sort: true,
        },
        {
            name: "created_on",
            display: "Added Date",
            sort: true,
        },
        {
            name: "Action",
            display: "Actions",
            sort: false,
        },
    ],
};



const MedicalHistory = (): JSX.Element => {
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState(tableConfig.sortDefault);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});

    const pageData = 'Medical History'
    const pageDescData = 'You can manage the Medical History in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const callbackFresh = (data: any) => {
        console.log('data', data)
        setActionType({
            type: "add",
            id: 'id',
            data: data
        })
    }

    const showCellCallback = () => {

    }

    useEffect(() => {
        setIsLoading(false);
        setTableData([]);
        const getData = async () => {
            const apidata = await GetFamily();
            const APIResponse = apidata?.data?.data;
            console.log('APIResponse', APIResponse)
            setTableData(APIResponse);
            setIsLoading(true);
            setReloadAPI(false);
        };
        getData();
    }, [reloadAPI]);

    return (
        <MyAccountWrapper>
            <CustomTable
                header={tableConfig.tableHeader}
                sortDefault={tableConfig.sortDefault}
                data={tableData}
                tableTitle={pageData}
                tableSubTitle={pageDescData}
                dropdown={""}
                search_bar={false}
                showCellCallback={showCellCallback}
                sortState={sortState}
                isLoading={isLoading}
                headerAction={''}
            >
                {({ rowData, index }: { rowData: any; index: any }) => (
                    <MedicalHistoryRow
                        rowData={rowData}
                        index={index}
                        showCells={showCells}
                        reloadData={reloadData}
                        callbackId={callbackFresh}
                    ></MedicalHistoryRow>
                )}
            </CustomTable>
        </MyAccountWrapper>
    );
};
export default MedicalHistory;
