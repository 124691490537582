import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CreateMedia, GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button, Input, Radio, Select } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import InputFile from "../../Library/File/File";
import { displayStripPay } from "../../components/PlanDetails";
import { displayRazorpay } from "../OrderSummery/OrderSummery";
import moment from "moment";
const AcademyForm = ({
    details
}: { details: any }) => {
    const [formData, setFormData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const Submit = async () => {
        if (
            details?.title !== undefined &&
            details?.title.length !== 0 &&
            formData?.full_name &&
            formData?.city &&
            formData?.mobile &&
            formData?.email_Id
        ) {
            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

            displayRazorpay({
                user_details_frontend: user_details_frontend,
                plan_name: details?.title,
                service_type: details?.service_type,
                appointment_date_time: new Date(),
                mobile: formData?.mobile,
                email_Id: formData?.email_Id,

                year: moment().format("YYYY"),
                month: moment().format("MM"),
                day: moment().format("DD"),
                date: moment(),
                time: moment().format("hh/mm"),
                internal_id: moment(),


                details: {
                    "full_name": formData?.full_name,
                    "city": formData?.city,
                    "mobile": formData?.mobile,
                    "email_Id": formData?.email_Id
                },
                price: details?.price,
                discount: "0",
                amount: details?.price,
                directURL: 'thank-you'
            })
        } else {
            toast.error("Please enter required fields");
        }
    }

    return (
        <>
            <div className="col-md-4 mb-4">
                <div className="inner">
                    <div className="row">
                        <h3>Form</h3>
                        <Input
                            inputName="full_name"
                            inputType="text"
                            labelName={"Name of patient"}
                            placeholder="Enter Name of patient"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.full_name}
                        />

                        <Input
                            inputName="city"
                            inputType="text"
                            labelName={"City"}
                            placeholder="Enter City"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.city}
                        />

                        <Input
                            inputName="mobile"
                            inputType="text"
                            labelName={"Mobile"}
                            placeholder="Enter Mobile"
                            required={false}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.mobile}
                        />

                        <Input
                            inputName="email_Id"
                            inputType="text"
                            labelName={"Email Id"}
                            placeholder="Enter Email Id"
                            required={false}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.email_Id}
                        />

                        <div className="col-md-6 text-right"></div>
                        <div className="col-md-6 text-right">
                            <Button
                                onClick={() =>
                                    Submit()
                                }
                                buttonText={"Pay Now"}
                                buttonStyleType={"primary"}
                                theme={theme}
                            >
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AcademyForm