/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import { Button, Input } from "../../Library/Module";
import { theme } from "../../theme";
import MyAccountWrapper from "./MyAccountWrapper";
import { UserChangePassword } from "../../servies/services";
import { toast } from "react-toastify";

type formDataProps = {
    password: string;
    password_confirm: string;
};


const ChangePasswordInner = (): JSX.Element => {

    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>();
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

    const Submit = async () => {
        if (formData?.password_confirm === formData?.password) {
            const data = {
                id: user_details_frontend?.id,
                password: formData?.password
            }

            // delete data.
            const apidata = await UserChangePassword(data);
            console.log("apidata", apidata)

            if (apidata?.data?.isSuccess) {
                toast.success(apidata?.data?.messages);
            } else {
                toast.error(apidata?.data?.messages);
            }
        } else {
            toast.error("Password and confirm password did not match, please try again.");
        }
    }

    return (
        <MyAccountWrapper>
            <div className="row">
            <h4 className="card-title">Change Password</h4>
            <p className="mb-3">You can Change Password in this module.</p>
                <div className="col-md-6">
                    <Input
                        inputName="password"
                        inputType="password"
                        labelName={"Password"}
                        placeholder="Enter Password"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.password}
                    />
                    <Input
                        inputName="password_confirm"
                        inputType="password"
                        labelName={"Confirm Password"}
                        placeholder="Enter Confirm Password"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.password_confirm}
                    />
                </div>
            </div>
            <div className="row align-content-end justify-content-end">

                <div className="col-sm-6 text-right">
                    <Button
                        onClick={() => Submit()}
                        buttonText={"Change Password"}
                        buttonStyleType={"primary"}
                        theme={theme}
                    >
                        <span className="material-symbols-outlined">
                            login
                        </span>
                    </Button>
                </div>
            </div>
        </MyAccountWrapper>
    );
};
export default ChangePasswordInner;
