/* eslint-disable no-empty-pattern */
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AddFamilyMember, AddMedicalHistory, AddQuestionnaire, GetFamily, GetFamilyDetailById, GetQuestionnaireById, GetServices, UpdateQuestionnaireById, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Card, CustomTable, Input, Select, SideDrawer } from "../../Library/Module";
import { theme } from "../../theme";
import Login from "../Login/Login";
import LoginForm from "../Login/LoginForm";
import TabComp from "../../Library/Tab/Tab";
import RegisterForm from "../Register/RegisterForm";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'

import { objectToQueryString } from "../../Library/Utility/Utility";
import PlanDetails from "../../components/PlanDetails";
import AuthDetect from "../MyAccount/AuthDetect";

const QuestionnaireInnerV2 = (): JSX.Element => {
    const [formData, setFormData] = useState<any>();
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCells, setShowCells] = useState(null);
    const [reloadAPI, setReloadAPI] = useState(false);
    const [actionType, setActionType] = useState<any>({});
    const navigate = useNavigate();
    const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();
    const pageData = 'Family Details'
    const pageDescData = 'You can manage the Family Details in this module.'
    const reloadData = (data: any) => {
        console.log("reloadData", data);
        setReloadAPI(true);
    };

    const location = useLocation()
    const myAccountRoute = location.pathname?.includes('my-account')

    // const callbackFresh = (data: any) => {


    //     console.log('data', data)
    //     setActionType({
    //         type: "add",
    //         id: 'id',
    //         data: data
    //     })

    //     setTableDataCalendarSingle([]);
    //     const getData2 = async () => {
    //         const query = {
    //             parent_id: [data?.parent_id]
    //         }
    //         const apidata = await GetFamilyDetailById(objectToQueryString(query));
    //         const APIResponse = apidata?.data?.data;
    //         setTableDataCalendarSingle(APIResponse);
    //     };
    //     getData2();
    // }



    const showCellCallback = () => {

    }

    // useEffect(() => {
    //     setIsLoading(false);
    //     setTableData([]);
    //     const getData = async () => {
    //         const apidata = await GetFamily();
    //         const APIResponse = apidata?.data?.data;
    //         console.log('APIResponse', APIResponse)
    //         setTableData(APIResponse);
    //         setIsLoading(true);
    //         setReloadAPI(false);
    //     };
    //     getData();
    // }, [reloadAPI]);

    // const AddMember = () => {
    //     setActionType({
    //         type: "add",
    //         id: 'id',

    //     })

    // }
    // const CloseDrawer = () => {
    //     setActionType({});
    // };

    // const FormSubmission = (data: boolean) => {
    //     setActionType({});
    //     setReloadAPI(true);
    // }

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const [apiType, setApiType] = useState('post')
    const [id, setId] = useState(null)
    useEffect(() => {
        const GetData = async () => {
            // @ts-ignore
            const familyDetails = JSON.parse(localStorage.getItem("family_details"));
            const APIResponse = await GetQuestionnaireById(familyDetails?._id);
            console.log("APIResponse asdsad", APIResponse?.data?.data)
            if (APIResponse?.data?.data?.[0]?.questionnaire) {
                console.log("APIResponse", APIResponse?.data?.data?.[0]?.questionnaire)
                setApiType('patch')
                setFormData(APIResponse?.data?.data?.[0]?.questionnaire)
                setId(APIResponse?.data?.data?.[0]?._id)
            }
        }

        GetData()
    }, [])

    const Submit = async () => {
        if (
            formData !== undefined
        ) {

            // @ts-ignore
            const selectedServices = JSON.parse(localStorage.getItem("selected_services"));
            // @ts-ignore
            const timeDate = JSON.parse(localStorage.getItem("time_date"));
            // @ts-ignore
            const familyDetails = JSON.parse(localStorage.getItem("family_details"));

            // @ts-ignore
            const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
            const data = {
                "user": user_details_frontend?.user_name,
                "member_name": familyDetails?.name,
                "member_id": familyDetails?._id,
                "user_id": user_details_frontend?.id,
                "questionnaire": formData,
                "status": "1",
                "created_by": user_details_frontend?.user_name,
                ...(apiType === "patch" && id ? { _id: id } : {})
            }

            const APIResponse = apiType === "post" ? await AddQuestionnaire(data) : await UpdateQuestionnaireById(data);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success("Your details submitted succesfully.");
                if (!myAccountRoute) {
                    navigate("/payment")
                }
            } else {
                if (APIResponse?.data?.messages) {
                    toast.error(APIResponse?.data?.messages);
                } else {
                    toast.error("Something went wrong. please try again.");
                }
            }
        } else {
            toast.error("Please enter required fields");
        }
    }

    const options = [
        {
            "text": "No",
            "value": "No",
            "id": 2
        }, {
            "text": "Yes",
            "value": "Yes",
            "id": 1
        }, {
            "text": "Not Sure",
            "value": "Not Sure",
            "id": 3
        },
    ]

    const HighBloodPressure = [
        {
            "text": "None",
            "value": "None",
            "id": 3
        },
        {
            "text": "well controlled",
            "value": "well controlled",
            "id": 2
        }, {
            "text": "not well controlled",
            "value": "not well controlled",
            "id": 1
        },
    ]

    const Health_History = [
        {
            "text": "Patient",
            "value": "Patient",
            "id": 3
        },
        {
            "text": "Family Member",
            "value": "Family Member",
            "id": 2
        }, {
            "text": "Health Care Provider",
            "value": "Health Care Provider",
            "id": 1
        }, {
            "text": "Other (specify)",
            "value": "Other (specify)",
            "id": 1
        },
    ]

    // Patient " Family Member " Health Care Provider " Other (specify):

    return (
        <>
            <div className="note mb-3">Please complete the questionaire to give doctor as much informaiton as you can. you can also edit later</div>

            <table className="table" id="questionnaireTable">
                <thead>
                    <tr>
                        <th colSpan={3}>Sugar / Diabetes</th>
                    </tr>
                </thead>
                <tr>
                    <td>Do you have: </td>

                    <td style={{ width: '280px' }}></td>
                </tr>

                <tr>
                    <td>Sugar / Diabetes</td>
                    <td>
                        <Input
                            inputName="diabetes1"
                            inputType="text"
                            placeholder="If Yes, since when "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.diabetes1}
                        />
                    </td>
                </tr>


                <tr>
                    <td>Tablets and / or Insulin</td>
                    <td>
                        <Input
                            inputName="diabetes2"
                            inputType="text"
                            placeholder="Give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.diabetes2}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Last HbA1C, Fasting, Random, Post lunch</td>
                    <td>
                        <Input
                            inputName="diabetes3"
                            inputType="text"
                            placeholder="Please write last checked reports"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.diabetes3}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Any complications due to sugar, you know?</td>
                    <td>
                        <Input
                            inputName="diabetes4"
                            inputType="text"
                            placeholder=""
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.diabetes4}
                        />
                    </td>
                </tr>
                <thead>
                    <tr>
                        <th colSpan={3}>Thyroid Problem</th>
                    </tr>
                </thead>
                <tr>
                    <td>Thyroid Problem</td>
                    <td>
                        <Input
                            inputName="thyroid1"
                            inputType="text"
                            placeholder="If yes, give details including medication"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.thyroid1}
                        />
                    </td>
                </tr>
                <thead>
                    <tr>
                        <th colSpan={3}>Blood pressure</th>
                    </tr>
                </thead>
                <tr>
                    <td>Blood pressure</td>
                    <td>
                        <Input
                            inputName="bloodpressure1"
                            inputType="text"
                            placeholder="If Yes, since when "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.bloodpressure1}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>Heart Problem</th>
                    </tr>
                </thead>

                <tr>
                    <td>Ever had heart attack, Palpitation(abnormal heart beats), blood clotting problems</td>
                    <td>
                        <Input
                            inputName="heart1"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.heart1}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Angioplasty done, heart bypass done</td>
                    <td>
                        <Input
                            inputName="heart2"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.heart2}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Visited or visiting any cardiologist for any reason
                    </td>
                    <td>
                        <Input
                            inputName="heart3"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.heart3}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Any blood thinners ? (E.g Ecosprin/Aspirin, warfarin, other) </td>
                    <td>
                        <Input
                            inputName="heart4"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.heart4}
                        />
                    </td>
                </tr>
                <thead>
                    <tr>
                        <th colSpan={3}>Lung problem</th>
                    </tr>
                </thead>

                <tr>
                    <td>Ever Smoked</td>
                    <td>
                        <Input
                            inputName="lung1"
                            inputType="text"
                            placeholder="If yes, how many years and when did you stopped"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung1}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Asthama, COPD, Bronchitis</td>
                    <td>
                        <Input
                            inputName="lung2"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung2}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Sleep Apnea, Do you use machine/ advise to use machine while sleeping.</td>
                    <td>
                        <Input
                            inputName="lung3"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung3}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Sleep Apnea, Do you use machine/ advise to use machine while sleeping.

                    </td>
                    <td>
                        <Input
                            inputName="lung4"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung4}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Do you get breathless or wheeze or get wheeze

                    </td>
                    <td>
                        <Input
                            inputName="lung5"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung5}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Do you use inhalers or puffers

                    </td>
                    <td>
                        <Input
                            inputName="lung6"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung6}
                        />
                    </td>
                </tr>


                <tr>
                    <td>Have you even been admitted with lung infection to hospital and/or put on ventilator ? </td>
                    <td>
                        <Input
                            inputName="lung7"
                            inputType="text"
                            placeholder="If yes give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lung7}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>Brain and Neurology</th>
                    </tr>
                </thead>

                <tr>
                    <td>Ever had brain Stroke/ Paralysis?</td>
                    <td>
                        <Input
                            inputName="neurology1"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.neurology1}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Epilepsy, fits, seizures ?

                    </td>
                    <td>
                        <Input
                            inputName="neurology2"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.neurology2}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Brain surgery/cancer? </td>
                    <td>
                        <Input
                            inputName="neurology3"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.neurology3}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Recurrent Fall, Imbalance while walking? </td>
                    <td>
                        <Input
                            inputName="neurology4"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.neurology4}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Any muscle or nerve problem </td>
                    <td>
                        <Input
                            inputName="neurology5"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.neurology5}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>Kidney Problem </th>
                    </tr>
                </thead>

                <tr>
                    <td>Any kidney problem (e.g high creatinine) </td>
                    <td>
                        <Input
                            inputName="kidney1"
                            inputType="text"
                            placeholder=""
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.kidney1}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Any Dialysis/ Kidney Transplant </td>
                    <td>
                        <Input
                            inputName="kidney2"
                            inputType="text"
                            placeholder=""
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.kidney2}
                        />
                    </td>
                </tr>



                <thead>
                    <tr>
                        <th colSpan={3}>STOMACH problem

                        </th>
                    </tr>
                </thead>

                <tr>
                    <td>Any acidity, stomach ulcer, gas problem

                    </td>
                    <td>
                        <Input
                            inputName="stomach1"
                            inputType="text"
                            placeholder="If yes, any medication taken?

                            "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.stomach1}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>Pain Problem</th>
                    </tr>
                </thead>
                <tr>
                    <td>Any pain in Bones / Joints, anywhere in body ? </td>
                    <td>
                        <Input
                            inputName="pain1"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.pain1}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Any Pain killers taking intermittently for these pains? </td>
                    <td>
                        <Input
                            inputName="pain2"
                            inputType="text"
                            placeholder="If yes, give details"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.pain2}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>Regular Follow up with any other doctor/ Specialist?</th>
                    </tr>
                </thead>
                <tr>
                    <td>Regular Follow up with any other doctor/ Specialist?</td>
                    <td>
                        <Input
                            inputName="regularFollow1"
                            inputType="text"
                            placeholder="If yes, for what? Which place?"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.regularFollow1}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>ALLERGY</th>
                    </tr>
                </thead>
                <tr>
                    <td>Any allergy to any medication</td>
                    <td>
                        <Input
                            inputName="allergy1"
                            inputType="text"
                            placeholder="If yes, give details including medication"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.allergy1}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Any allergy to food or other things </td>
                    <td>
                        <Input
                            inputName="allergy2"
                            inputType="text"
                            placeholder="If yes, give details including medication"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.allergy2}
                        />
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>LIFESTYLE</th>
                    </tr>
                </thead>
                <tr>
                    <td>Sleep </td>
                    <td>
                        <Input
                            inputName="lifestyle1"
                            inputType="text"
                            placeholder="Good / Disturbed, if disturbed give reason"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle1}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Food habit</td>
                    <td>
                        <Input
                            inputName="lifestyle2"
                            inputType="text"
                            placeholder="Veg / Non Veg
                            Regular / Irregular.
                            "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle2}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Work / Job

                    </td>
                    <td>
                        <Input
                            inputName="lifestyle3"
                            inputType="text"
                            placeholder="Desk job or Moving around
                            Stress or Retired/ at home
                            "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle3}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Exercise </td>
                    <td>
                        <Input
                            inputName="lifestyle4"
                            inputType="text"
                            placeholder="Discribe"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle4}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Smoking / alcohol</td>
                    <td>
                        <Input
                            inputName="lifestyle5"
                            inputType="text"
                            placeholder="Give details like how many cigarets per day and how many drinks in a day/ week?"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle5}
                        />
                    </td>
                </tr>
                <tr>
                    <td>BMI </td>
                    <td>
                        <Input
                            inputName="lifestyle6"
                            inputType="text"
                            placeholder=""
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle6}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Do you use Hearing Aids ?</td>
                    <td>
                        <Input
                            inputName="lifestyle7"
                            inputType="text"
                            placeholder="Yes/ No"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle7}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Do you used mobility aids?

                    </td>
                    <td>
                        <Input
                            inputName="lifestyle8"
                            inputType="text"
                            placeholder="Walking stick, Walking frame, Wheel chair."
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle8}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Are you able to climb stairs, without tiredness or breathless ness?</td>
                    <td>
                        <Input
                            inputName="lifestyle9"
                            inputType="text"
                            placeholder="Yes / no / never tried in last 6 months. "
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.lifestyle9}
                        />
                    </td>
                </tr>


                <thead>
                    <tr>
                        <th colSpan={3}>LIST ANY SURGERIES OR MINOR PROCEDURES USING ANESTHETIC YOU HAVE HAD IN THE PAST. </th>
                    </tr>
                </thead>

                <tr>
                    <td colSpan={3}>
                        <table className="table" style={{ width: "100%" }}>
                            <tr>
                                <td>Procedure</td>
                            </tr>
                            <tr>
                                <td>
                                    <Input
                                        inputName="procedure1"
                                        inputType="textarea"
                                        placeholder="Procedure"
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={false}
                                        value={formData?.procedure1}
                                    />
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <thead>
                    <tr>
                        <th colSpan={3}>
                            LIST ALL OF THE MEDICATIONS THAT YOU TAKE (INCLUDING HERBAL MEDICATION, VITAMINS, AND NON
                            PRESCRIPTION DRUGS).
                        </th>
                    </tr>
                </thead>

                <tr>
                    <td colSpan={3}>
                        <table className="table">
                            <tr>
                                <td>
                                    <Input
                                        inputName="medications1"
                                        inputType="text"
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={false}
                                        value={formData?.medications1}
                                    /></td>
                                <td><Input
                                    inputName="medications2"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications2}
                                /></td>
                                <td><Input
                                    inputName="medications3"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications3}
                                /></td>
                                <td><Input
                                    inputName="medications4"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications4}
                                /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Input
                                        inputName="medications5"
                                        inputType="text"
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={false}
                                        value={formData?.medications5}
                                    /></td>
                                <td><Input
                                    inputName="medications6"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications6}
                                /></td>
                                <td><Input
                                    inputName="medications7"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications7}
                                /></td>
                                <td><Input
                                    inputName="medications8"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications8}
                                /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Input
                                        inputName="medications9"
                                        inputType="text"
                                        onChangeSingleCallback={onChangeSingleCallback}
                                        col="12"
                                        isFormSubmitted={false}
                                        value={formData?.medications9}
                                    /></td>
                                <td><Input
                                    inputName="medications10"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications10}
                                /></td>
                                <td><Input
                                    inputName="medications11"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications11}
                                /></td>
                                <td><Input
                                    inputName="medications12"
                                    inputType="text"
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={false}
                                    value={formData?.medications12}
                                /></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>Do you have any other illness, limitations or any other concerns we should know about?</td>
                    <td>
                        <Input
                            inputName="any_heart_problem1"
                            inputType="text"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={false}
                            value={formData?.any_heart_problem1}
                        />
                    </td>
                </tr>



                <tr>
                    <td>Patient Health History Questionnaire completed by:</td>
                    <td><Select
                        inputName={'health_history_questionnaire'}
                        options={Health_History ?? []}
                        onChangeSingleCallback={onChangeSingleCallback}
                        selectedItem={formData?.health_history_questionnaire ? Health_History?.find(
                            (selected: any) => {
                                return (selected.value === formData?.health_history_questionnaire)
                            }
                        ) : Health_History?.[0]}
                        search_option={false}
                        isLoading={true}
                        value={formData?.health_history_questionnaire}
                        customClass={'mb-0'}
                    ></Select></td>
                    <td>
                        {formData?.health_history_questionnaire === "Other (specify)" ?
                            <Input
                                inputName="questionnaireCompleted"
                                inputType="text"
                                placeholder="Name"
                                onChangeSingleCallback={onChangeSingleCallback}
                                col="12"
                                isFormSubmitted={false}
                                value={formData?.questionnaireCompleted}
                            />
                            : ''}
                    </td>
                </tr>
            </table>



            <div className="navigationBar">
                <div className="row">
                    <div className="col-md-6">
                        <NavLink className={'btn'} to={'/problem'}>Back to Current Problem</NavLink>
                    </div>
                    <div className="col-md-6 text-right">
                        <Button onClick={() => Submit()}>Submit &#38; Next</Button>
                    </div>
                </div>
            </div>

        </>
    );
};
export default QuestionnaireInnerV2;
