// /* eslint-disable no-empty-pattern */
// import { NavLink, useNavigate } from "react-router-dom";
// import Wrapper from "../Wrapper";
// import { GetFamily, GetFamilyDetailById, GetServices, authenticate } from "../../servies/services";
// import { toast } from "react-toastify";
// import { useEffect, useState } from "react";
// import { Button, Card, CustomTable, Input, SideDrawer } from "../../Library/Module";
// import { theme } from "../../theme";
// import Login from "../Login/Login";
// import LoginForm from "../Login/LoginForm";
// import TabComp from "../../Library/Tab/Tab";
// import RegisterForm from "../Register/RegisterForm";
// import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'

// import { objectToQueryString } from "../../Library/Utility/Utility";


// export const MyAccountMenu = [
//     { "text": "Update Profile", "link": "/my-account/update-profile" },
//     { "text": "Appointments", "link": "/my-account/my-appointment", },
//     { "text": "Medical History", "link": "/my-account/medical-history", },
//     { "text": "Relatives", "link": "/my-account/relatives", },
//     { "text": "Questions", "link": "/my-account/questions", },
//     { "text": "Orders", "link": "/my-account/orders", },
//     { "text": "Payments", "link": "/my-account/payments", }
// ]

// const tableConfig = {
//     search: {
//         name: "id",
//         isAsc: false,
//     },
//     sortDefault: {
//         name: "created_on",
//         isAsc: false,
//     },
//     tableHeader: [
//         {
//             name: "select",
//             display: "Select Patient",
//             sort: false,
//         },
//         {
//             name: "name",
//             display: "Name",
//             sort: true,
//         },
//         {
//             name: "age",
//             display: "Age",
//             sort: true,
//         },
//         {
//             name: "gender",
//             display: "Gender",
//             sort: true,
//         },

//         {
//             name: "relation",
//             display: "Relation",
//             sort: true,
//         },
//         {
//             name: "created_on",
//             display: "Added Date",
//             sort: true,
//         },
//         {
//             name: "Action",
//             display: "Action",
//             sort: false,
//         },
//     ],
// };

// const MyAccount = (): JSX.Element => {
//     const [tableData, setTableData] = useState<any>([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [sortState, setSortState] = useState(tableConfig.sortDefault);
//     const [showCells, setShowCells] = useState(null);
//     const [reloadAPI, setReloadAPI] = useState(false);
//     const [actionType, setActionType] = useState<any>({});
//     const navigate = useNavigate();
//     const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();
//     const pageData = 'Family Details'
//     const pageDescData = 'You can manage the Family Details in this module.'
//     const reloadData = (data: any) => {
//         console.log("reloadData", data);
//         setReloadAPI(true);
//     };

//     const callbackFresh = (data: any) => {
//         console.log('data', data)
//         setActionType({
//             type: "add",
//             id: 'id',
//             data: data
//         })

//         setTableDataCalendarSingle([]);
//         const getData2 = async () => {
//             const query = {
//                 parent_id: [data?.parent_id]
//             }
//             const apidata = await GetFamilyDetailById(objectToQueryString(query));
//             const APIResponse = apidata?.data?.data;
//             setTableDataCalendarSingle(APIResponse);
//         };
//         getData2();
//     }



//     const showCellCallback = () => {

//     }

//     useEffect(() => {
//         setIsLoading(false);
//         setTableData([]);
//         const getData = async () => {
//             const apidata = await GetFamily();
//             const APIResponse = apidata?.data?.data;
//             console.log('APIResponse', APIResponse)
//             setTableData(APIResponse);
//             setIsLoading(true);
//             setReloadAPI(false);
//         };
//         getData();
//     }, [reloadAPI]);

//     const AddMember = () => {
//         setActionType({
//             type: "add",
//             id: 'id',

//         })

//     }
//     const CloseDrawer = () => {
//         setActionType({});
//     };

//     const FormSubmission = (data: boolean) => {
//         setActionType({});
//         setReloadAPI(true);
//     }

//     const Submit = () => {
//         navigate("/forgot-password");
//     }

//     // @ts-ignore
//     const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

//     console.log("user_details_frontend", user_details_frontend)

//     useEffect(() => {
//         if (user_details_frontend?.token) {
//             navigate('/my-account/update-profile')
//         } else {

//         }
//     }, [navigate, user_details_frontend])

//     return (
//         <Wrapper>
//             <div className="innerWrapperFull">
//                 <div className="servicesList">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <TabComp
//                                 tabSelected={tabSelected}
//                                 tabList={[
//                                     { "name": 'Login' },
//                                     { "name": 'Register' }
//                                 ]}>
//                                 {selectedTab === 'Register' ?
//                                     <div className="registerForm"><RegisterForm></RegisterForm></div> : <LoginForm></LoginForm>}
//                             </TabComp>

//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </Wrapper>
//     );
// };
// export default MyAccount;


import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { GetServices, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button } from "../../Library/Module";
import { theme } from "../../theme";
import Login from "../Login/Login";
import LoginForm from "../Login/LoginForm";
import TabComp from "../../Library/Tab/Tab";
import RegisterForm from "../Register/RegisterForm";

const MyAccount = (): JSX.Element => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(null)
    const [services, setServices] = useState([])

    useEffect(() => {
        getProfileDetails()
    }, [])

    const getProfileDetails = async () => {
        const APIResponse = await GetServices();
        console.log("CreateMedia", APIResponse);
        setServices(APIResponse?.data?.data)
    };

    const clickHandle = (data: any) => {
        navigate(`/booking/${data}`);
        localStorage.setItem('selected_services', JSON.stringify(data))
    }

    const tabSelected = (data: any) => {
        setSelectedTab(data)
    }

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
    console.log("user_details_frontend", user_details_frontend)

    useEffect(() => {
        if (user_details_frontend?.token) {
            navigate('/availability')
        } else {
            navigate('/my-account')
        }
    }, [navigate, user_details_frontend])

    return (
        <Wrapper>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-12">
                            <TabComp
                                tabSelected={tabSelected}
                                tabList={[
                                    { "name": 'Login' },
                                    { "name": 'New Register' }
                                ]}>
                                {selectedTab === 'New Register' ?
                                    <div className="registerForm"><RegisterForm activeTab={setSelectedTab}></RegisterForm></div> : <LoginForm></LoginForm>}
                            </TabComp>

                        </div>

                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default MyAccount;
