import react from "react";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomTooltip from "../../Library/Tooltip/Tippy";

interface AlertProps {
    rowData?: any;
    index?: any;
    showCells?: any;
    reloadData?: any;
    callbackId?: any;
}

const TableRow = ({
    rowData,
    index,
    showCells,
    reloadData,
    callbackId
}: AlertProps): JSX.Element => {
    return (
        <tr key={index}>
            <td>{index + 1}</td>

            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <p>Plan Name - {rowData?.plan_name}</p>
                        <p>Plan Type - {rowData.service_type}</p>
                    </>}>
                    Plan Details
                </CustomTooltip>
            </td>
            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <p>Price - {rowData.price}</p>
                        <p>Discount - {rowData.discount}</p>
                        <p>Amount Paid - {rowData.amount}</p>
                    </>}>
                    {rowData.amount}
                </CustomTooltip>
            </td>
            <td>{rowData.appointment_date_time}</td>
            <td>
                <CustomTooltip
                    title={"title"}
                    position={"bottom"}
                    content={<>
                        <p>Name - {rowData?.patient_name}</p>
                        <p>Age - {rowData?.patient_age}</p>
                        <p>Gender - {rowData?.patient_gender}</p>
                        <p>Relation  - {rowData?.patient_relation}</p>
                        <p>Other Details  {rowData?.other_details}</p>
                    </>}>
                    {rowData.patient_name}
                </CustomTooltip>
            </td>
            <td><CustomTooltip
                title={"title"}
                position={"bottom"}
                content={<>
                    <p>Payment status - {rowData.payment_status}</p>
                    <p>Status - {rowData.status}</p>
                    <p>Order Id - {rowData.order_id}</p>
                    <p>Payment Id - {rowData.payment_id}</p>
                </>}>
                {rowData.payment_status}
            </CustomTooltip></td>
            <td>{moment(rowData.created_on).format("DD/MM/YYYY")}</td>
        </tr>
    );
};

export default TableRow;
