import { useContext } from 'react'
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.jpg";
import { MenuTop } from "./Header";
import { ThemeContext } from "../Context/Theme/Context";
import { Button, Input } from '../Library/Module';
import { mainAddress } from '../Library/Utility/Utility';

export const MenuFooter = [
    { "text": "Privacy Policy", "link": "/privacy-policy" },
    { "text": "Terms Of Use", "link": "/terms-conditions" },
    { "text": "Refund Policy", "link": "/refund-policy" }
  ]

const Footer = () => {
    const { mainCategory } = useContext(ThemeContext)
    return (
        <>
            <div className='footerBlock'>
                <div className='container'>
                    <div className='row align-items-center justify-content-center'>
                        {/* <div className='col-md-3'>
                            <div className='inner'>
                                <div className='icon'>
                                    <span className="material-symbols-outlined">location_on</span>
                                </div>
                                <div className='content'>
                                    <h3>Visit our Location</h3>
                                    <p>Online</p></div>
                            </div>
                        </div> */}

                        <div className='col-md-3'>
                            <div className='inner'>
                                <div className='icon'>
                                    <span className="material-symbols-outlined">
                                        phone_in_talk
                                    </span>
                                </div>
                                <div className='content'>
                                    <h3>Give us a Call</h3>
                                    <p>+91 98660 35911</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inner'>
                                <div className='icon'>
                                    <span className="material-symbols-outlined">
                                        mail
                                    </span>
                                </div>
                                <div className='content'>
                                    <h3>Send us a Message</h3>
                                    <p>info@dranandjoshi.com</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='inner'>
                                <div className='icon'>
                                    <span className="material-symbols-outlined">
                                        medical_services
                                    </span>
                                </div>
                                <div className='content'>
                                    <h3>Online Appointment Hours</h3>
                                    <p>Monday - Friday : 9AM - 5PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h2>Contact Info</h2>
                            <p><strong>Call : </strong> +91 98660 35911</p>
                            <p><strong>Email : </strong> info@dranandjoshi.com</p>
                            <p><strong>Support :  </strong> +91 98660 35911</p>
                            <p><strong>Address :</strong> <br/> <span dangerouslySetInnerHTML={{ __html: mainAddress }}></span>
                            </p>
                        </div>

                        <div className="col-md-4">
                            <h2>Main Menu</h2>
                            <ul>
                                {MenuTop?.map((item, index) => {
                                    return <>
                                        <li>

                                            <NavLink to={item.link} title={item.text}>{item.text}</NavLink>

                                        </li>
                                    </>
                                })}
                                {MenuFooter?.map((item, index) => {
                                    return <>
                                        <li>

                                            <NavLink to={item.link} title={item.text}>{item.text}</NavLink>

                                        </li>
                                    </>
                                })}
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h2>Our Services</h2>
                            <ul>
                                {mainCategory?.map((item: any, index: any) => {
                                    return <>
                                        <li>
                                            {/* {JSON.stringify(item)} */}
                                            <NavLink to={`/services/${item?.slug}`} title={item.title}>
                                            <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                            </NavLink>
                                        </li>
                                    </>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Footer;
