import { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "../Library/Module";
import { toast } from "react-toastify";
import Logo from "../images/logo2.jpg";
import { ThemeContext } from "../Context/Theme/Context";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Logout } from "../servies/services";

export const MenuTop = [
  { "text": "Home", "link": "/" },
  { "text": "About", "link": "/about-us" },
  { "text": "Services", "link": "/services" },
  { "text": "Testimonials", "link": "/testimonials" },
  // { "text": "My Account", "link": "/my-account" },
  { "text": "Contact Us", "link": "/contact-us" }
]



export const MyAccountMenu = [
  { "text": "Services", "link": "/services", "tagLine": "Choose" },
  { "text": "Login / Register", "link": "/my-account", "tagLine": "Select" },
  { "text": "Date / Appointment", "link": "/availability", "tagLine": "Select" },
  { "text": "Member", "link": "/family-details", "tagLine": "Select" },
  { "text": "Problem ", "link": "/problem", "tagLine": "List Current" },
  { "text": "Questionnaire", "link": "/questionnaire", "tagLine": "Answer/Update" },
  { "text": "Appointment", "link": "/payment", "tagLine": "Pay and Confirm " }
]

const Header = () => {
  const { mainCategory } = useContext(ThemeContext)
  const [mainMenuTop, setMainMenuTop] = useState([])
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  // @ts-ignore
  const profile_details_frontend = JSON.parse(localStorage.getItem("profile_details_frontend"));


  useEffect(() => {
    if (mainCategory) {
      const newMenu: any = []
      MenuTop?.map((i: any) => {
        if (i?.text === 'Services') {
          i['subMenu'] = mainCategory
        }
        return i?.text === 'Services' ?
          newMenu.push(i) : newMenu.push(i)
      })
      console.log("newMenu", newMenu)
      setMainMenuTop(newMenu)
    }
  }, [mainCategory])

  const isMobile = width < 990
  console.log("isMobile", isMobile)
  const [menuOpen, setMenuOpen] = useState(false)
  const mainMenu = (data: any) => {
    return setMenuOpen(data)
  }
  // @ts-ignore
  const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

  const logoutAction = async () => {
    const formData = {
      token: user_details_frontend?.token
    }
    const APIResponse = await Logout(formData);
    console.log("APIResponse", APIResponse)
    localStorage.removeItem("user_details_frontend")
    localStorage.removeItem("profile_details_frontend")
    seIsLoggedUser(false)
    navigate('/')
  }
  const [isLoggedUser, seIsLoggedUser] = useState(false)

  useEffect(() => {
    console.log("user_details_frontend", user_details_frontend?.user_name)
    seIsLoggedUser(user_details_frontend?.user_name ? true : false)
  }, [user_details_frontend])

  const myaccountNav = (link: any) => {
    console.log("link", link)
    if (link === "/questionnaire" || link === "/problem" || link === "/payment") {
      // isLoggedUser
      if (isLoggedUser) {
        navigate(window.location.pathname)
      } else {
        navigate("/my-account")

      }

    } else {
      navigate(link)
    }

  }

  return (
    <>
      <div className="header" id="desktop">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="logo">
                <NavLink to={'/'}>
                  <img src={Logo} title="" alt="" />
                </NavLink>
              </div>
            </div>
            <div className="col-md-10">
              <div className="topRow">
                <div className="phone">
                  <span className="material-symbols-outlined">
                    phone_in_talk
                  </span>
                  +91 98660 35911
                </div>
                <div className="email">
                  <span className="material-symbols-outlined">
                    mail
                  </span>
                  info@dranandjoshi.com
                </div>
                <div className="actionBtn">
                  <NavLink className={'btn btn-primary'} to={'/services'}>
                    <span className="material-symbols-outlined">
                      calendar_month
                    </span>
                    Book An Appointment
                  </NavLink>
                </div>
              </div>
              <div className="menuMain">
                <ul>
                  {mainMenuTop?.map((i: any) => {
                    return (
                      <>
                        <li>
                          <NavLink to={i?.link}>
                            {i?.text}
                          </NavLink>
                          {i?.subMenu && <ul>
                            {i?.subMenu.map((sub: any) => {
                              return (
                                <>
                                  <li>
                                    <NavLink to={`/services/${sub?.slug}`} title={sub.title}>
                                      <span dangerouslySetInnerHTML={{ __html: sub.title }}></span>
                                    </NavLink>
                                  </li>
                                </>
                              )
                            })}
                          </ul>}
                        </li>
                      </>
                    )
                  })}

                  {isLoggedUser ?
                    <>
                      <li><NavLink className={'active'} to={'/my-account/my-appointment/dashboard'}>
                        <span className="material-symbols-outlined">
                          person
                        </span>
                        Welcome {profile_details_frontend?.first_name}
                      </NavLink></li>
                      <li><NavLink className={'active'} to={'/my-account/my-appointment/dashboard'}>
                        <span className="material-symbols-outlined">
                          space_dashboard
                        </span>
                        Dashboard
                      </NavLink></li>
                      <li>
                        <Button onClick={() => logoutAction()} buttonStyleType="primary">Logout</Button>
                      </li>
                    </>
                    : <li>
                      <NavLink to={'/my-account'}>
                        <span className="material-symbols-outlined">
                          dashboard
                        </span>
                        Login / Register
                      </NavLink></li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header" id="mobile">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-2 col-6">
              <div className="logo">
                <NavLink to={'/'}>
                  <img src={Logo} title="" alt="" />
                </NavLink>
              </div>
            </div>
            <div className="col-md-10 col-6">
              <Button className="float-right humbargarMenu" onClick={() => mainMenu(true)} buttonStyleType="primary">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMobile && menuOpen &&
        <>

          <div className="mobileMenu" id="mobile">

            <div className="headerMenuBar">
              <div className="container">
                <div className="row ">
                  <div className="col-md-2 col-6">
                    <div className="logo">
                      <NavLink to={'/'}>
                        <img src={Logo} title="" alt="" />
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-md-10 col-6 text-right-only">
                    <Button onClick={() => mainMenu(false)} buttonStyleType="primary">Close</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="menuMain">
              <ul>
                {mainMenuTop?.map((i: any) => {
                  return (
                    <>
                      <li>
                        <NavLink to={i?.link}>
                          {i?.text}
                          {i?.subMenu && <>+</>}
                        </NavLink>
                        {i?.subMenu && <ul>
                          {i?.subMenu.map((sub: any) => {
                            return (
                              <>
                                <li>
                                  <NavLink to={`/services/${sub?.slug}`} title={sub.title}>{sub.title}</NavLink>
                                </li>
                              </>
                            )
                          })}
                        </ul>}
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
            <div className="topRow">
              <div className="phone">
                <span className="material-symbols-outlined">
                  phone_in_talk
                </span>
                +91 98660 35911
              </div>
              <div className="email">
                <span className="material-symbols-outlined">
                  mail
                </span>
                info@dranandjoshi.com
              </div>
              <div className="actionBtn">
                <NavLink className={'btn btn-primary'} to={'/services'}>
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                  Book An Appointment
                </NavLink>
              </div>

              <div className="menuMain">
                <ul>

                  {isLoggedUser ?
                    <>
                      <li><NavLink className={'active'} to={'/my-account/my-appointment/dashboard'}>
                        <span className="material-symbols-outlined">
                          person
                        </span>
                        Welcome {profile_details_frontend?.first_name}
                      </NavLink></li>
                      <li><NavLink className={'active'} to={'/my-account/my-appointment/dashboard'}>
                        <span className="material-symbols-outlined">
                          space_dashboard
                        </span>
                        Dashboard
                      </NavLink></li>
                      <li className="actionBtn">
                        <Button onClick={() => logoutAction()} buttonStyleType="primary">Logout</Button>
                      </li>
                    </>
                    : <li>
                      <NavLink to={'/my-account'}>
                        <span className="material-symbols-outlined">
                          dashboard
                        </span>
                        Login / Register
                      </NavLink></li>
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="overLay"></div>
        </>
      }

      <div className="menuMainMobile">
        <ul>
          <li>
            <NavLink className={'btn btn-primary'} to={'/services'}>
              <span className="material-symbols-outlined">
                calendar_month
              </span>
              Book An Appointment
            </NavLink>
          </li>
          {isLoggedUser ?
            <>
              <li><NavLink className={'btn btn-primary'} to={'/my-account/my-appointment/dashboard'}>
                <span className="material-symbols-outlined">
                  person
                </span>
                Welcome {profile_details_frontend?.first_name}
              </NavLink></li>
              <li><NavLink className={'btn btn-primary'} to={'/my-account/my-appointment/dashboard'}>
                <span className="material-symbols-outlined">
                  space_dashboard
                </span>
                Dashboard
              </NavLink></li>
              <li className="actionBtn">
                <Button className={'btn btn-primary'} onClick={() => logoutAction()} buttonStyleType="primary">Logout</Button>
              </li>
            </>
            : <li>
              <NavLink className={'btn btn-primary'} to={'/my-account'}>
                <span className="material-symbols-outlined">
                  dashboard
                </span>
                Login / Register
              </NavLink></li>
          }
        </ul>
        <div className="clearfix"></div>
      </div>

      <div className="MyAccountMenu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flex align-content-between justify-content-center">
                <p className="stepsTag">
                  <div className="arrow_box">
                    <span>Book An Appointment</span>
                  </div>
                </p>
                <ul>
                  {MyAccountMenu?.map((i) => {
                    return (
                      <>
                        <li className={i?.link === window.location.pathname ? 'active' : ''}>
                          <div onClick={() => myaccountNav(i?.link)} className="block_text">
                            {i?.tagLine && <span>{i?.tagLine}</span>}
                            <p>{i?.text}</p>
                          </div>
                        </li>
                      </>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Header;

