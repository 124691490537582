/* eslint-disable no-empty-pattern */
import { NavLink, useNavigate } from "react-router-dom";
import Wrapper from "../Wrapper";
import { Card } from "../../Library/Module";
import { useEffect } from "react";

export const MyAccountMenu = [

    { "text": "Dashboard", "link": "/my-account/my-appointment/dashboard", },
    // { "text": "In-person Appointments", "link": "/my-account/my-appointment/In-person Meeting", },

    { "text": "SMS Query", "link": "/my-account/my-appointment/sms-query", },
    { "text": "Pathology Report", "link": "/my-account/my-appointment/pathology", },
    // { "text": "Payments", "link": "/my-account/my-payments", },
    // { "text": "Orders", "link": "/my-account/my-orders", },
    // { "text": "Medical History", "link": "/my-account/medical-history", },
    { "text": "Members", "link": "/my-account/relatives", },
    // { "text": "Questionnaire", "link": "/my-account/questionnaire", },
    { "text": "Update", "link": "/my-account/update-profile" },
    { "text": "Change Password", "link": "/my-account/change-password" },
]


type MyAccountWrapperProps = {
    children: any
}
const MyAccountWrapper = ({
    children
}: MyAccountWrapperProps): JSX.Element => {

    const navigate = useNavigate()

    useEffect(() => {
        // @ts-ignore
        const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));
        console.log("user_details_frontend", user_details_frontend)
        if (user_details_frontend?.token) {
           
        } else {
            navigate('/my-account')
        }
    }, [navigate])

    return (
        <Wrapper>
            <div className="innerWrapperFull">
                <div className="servicesList">
                    <div className="row">
                        <div className="col-md-2">
                            <Card>
                                <div className="planDetails">
                                    <div className="inner">
                                        <ul className="navigation">
                                            {MyAccountMenu?.map((i) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <NavLink to={i?.link}>
                                                                <p>{i?.text}</p>
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-10">
                            <div className="inner">
                                {children}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Wrapper>
    );
};
export default MyAccountWrapper;
