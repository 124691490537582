/* eslint-disable no-empty-pattern */
import Wrapper from "../Wrapper";
import RegisterForm from "./RegisterForm";
const Register = () => {
    return (
        <Wrapper>
            <div className="">
                <div className="loginBoxBg">
                    <div className="login_admin">
                        <RegisterForm></RegisterForm>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
export default Register;
