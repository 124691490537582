export const theme = {
    'primary': '#007bff',
    'secondary': '#6610f2',
    'dark': '#333',
    'semidark': '#666',
    'light': '#999',
    "white": "#ffffff",
    "fss": "12px",
    "fsm": "14px",
    "fsr": "16px",
    "fsb": "18px",
}

export const variables = {
    "site_title": "Dr. Anand Joshi"
}