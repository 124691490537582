/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { Button, Card, CustomTable, SideDrawer } from "../../Library/Module";
import MyAccountWrapper from "./MyAccountWrapper";
import MyAppointmentRow from "./MyAppointmentsRow";
import { GetAppointments, GetMedicalHistoryByAppointment } from "../../servies/services";
import { NavLink, useParams } from "react-router-dom";
import { objectToQueryString } from "../../Library/Utility/Utility";
import moment from "moment";


const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "appointment_date_time",
    isAsc: true,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "plan_name",
      display: "plan_name",
      sort: false,
    },

    {
      name: "appointment_date_time",
      display: "appointment_date_time",
      sort: true,
    },

    {
      name: "patient_name",
      display: "patient_name",
      sort: false,
    },
    {
      name: "created_on",
      display: "Booking Date",
      sort: false,
    },
    {
      name: "Meeting Link",
      display: "Meeting Link",
      sort: false,
    },
    {
      name: "service_type",
      display: "Questionnaire",
      sort: false,
    },
    {
      name: "service_type",
      display: "Problem & Prescription",
      sort: false,
    },
    // {
    //   name: "created_by",
    //   display: "created_by",
    //   sort: true,
    // },
    // {
    //   name: "action",
    //   display: "Action",
    //   sort: false,
    // }
  ],
};



const MyAppointments = (): JSX.Element => {
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [showCells, setShowCells] = useState(null);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [actionType, setActionType] = useState<any>({});
  const { type } = useParams()
  const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();

  const [userQuery, setUserQuery] = useState<any>([]);
  const [userSolutions, setUserSolutions] = useState<any>([]);

  const pageData = `In-person  & Online Appointment Management `
  const pageDescData = `You can manage the ${pageData} in this module.`
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const callbackFresh = (data: any) => {
    setUserSolutions([])
    setUserQuery([])
    console.log('data', data)
    setActionType({
      type: "add",
      id: 'id',
      data: data
    })


    const GetData = async () => {
      const query = {
        "appointment_date_time": data?.appointment_date_time,
        "submitted_by": "patient",
        "submitted_type": "query",
        user_id: data?.user_id
      }
      const APIResponse = await GetMedicalHistoryByAppointment(objectToQueryString(query));
      console.log("GetMedicalHistory", APIResponse?.data?.data)
      setUserQuery(APIResponse?.data?.data)
    }
    const GetData2 = async () => {
      const query = {
        "appointment_date_time": data?.appointment_date_time,
        "submitted_by": "doctor",
        "submitted_type": "solutions",
        user_id: data?.user_id
      }
      const APIResponse = await GetMedicalHistoryByAppointment(objectToQueryString(query));
      console.log("GetMedicalHistory", APIResponse?.data?.data)
      setUserSolutions(APIResponse?.data?.data)
    }
    if (data?.appointment_date_time) {
      GetData()
      GetData2()
    }

  }

  const showCellCallback = () => {

  }

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";

    const getData = async () => {
      const query = {
        "payment_status": "paid",
        "appointment_type": 'appointment',
        // "sortBy":'appointment_date_time'
      }
      const apidata = await GetAppointments(user_details_frontend?.id, objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      console.log('APIResponse', APIResponse)
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI, type]);

  const CloseDrawer = () => {
    setActionType({});
  };


  return (
    <MyAccountWrapper>
      <CustomTable
        header={tableConfig.tableHeader}
        sortDefault={tableConfig.sortDefault}
        data={tableData}
        tableTitle={pageData}
        tableSubTitle={pageDescData}
        dropdown={""}
        search_bar={false}
        showCellCallback={showCellCallback}
        sortState={sortState}
        isLoading={isLoading}
        headerAction={<NavLink to={'/services'}><Button buttonStyleType="primary">Book An Appointment</Button></NavLink>}
      >
        {({ rowData, index }: { rowData: any; index: any }) => (
          <MyAppointmentRow
            rowData={rowData}
            index={index}
            showCells={showCells}
            reloadData={reloadData}
            callbackId={callbackFresh}
          ></MyAppointmentRow>
        )}
      </CustomTable>
      {(actionType?.type === "add" || actionType?.type === "edit") && (
        <SideDrawer
          size={'750px'}
          pagetitle={`Problem & Prescription Details`}
          action={CloseDrawer}
        ><div className="p-5">
            <Card>
              <h5>Prescription</h5>
              {userSolutions?.length > 0 ?
                userSolutions?.map((problem: any) => {
                  const files = typeof problem?.file_path === 'object' ? problem?.file_path && problem?.file_path?.map((item: any) => {
                    console.log("item", item)
                    return <a className="mr-2 btn btn-primary float-left" target="_blank" href={process.env.react_app_base_url + "/" + item} rel="noreferrer">Download</a>
                  }) : ''
                  console.log("files", typeof problem?.file_path, problem?.file_path)
                  return (
                    <div className="info">
                      <p><strong>Solutions</strong></p>
                      <p>{problem?.solutions}</p>
                      <p><strong>Submitted At</strong></p>
                      <p>{moment(problem?.created_on).format("DD/MM/YYYY hh:mm:ss a")}</p>
                      {files}
                      <div className="clearfix"></div>
                    </div>
                  )
                }) : 'Prescription not found.'}
            </Card>
          </div>
          <div className="p-5">
            <Card >
              <h5>Patient Problem</h5>
              <div className="p-2">
                {userQuery?.length > 0 ? userQuery?.map((problem: any) => {
                  const files = typeof problem?.file_path === 'object' ? problem?.file_path && problem?.file_path?.map((item: any) => {
                    console.log("item", item)
                    return <a className="mr-2 btn btn-primary float-left" target="_blank" href={process.env.react_app_base_url + "/" + item} rel="noreferrer">Download</a>
                  }) : ''
                  console.log("files", typeof problem?.file_path, problem?.file_path)
                  return (
                    <div className="info">
                      <h5><strong>Problem</strong></h5>
                      <p>{problem?.problems}</p>
                      <h5><strong>Submitted At</strong></h5>
                      <p>{moment(problem?.created_on).format("DD/MM/YYYY hh:mm:ss a")}</p>
                      {files}
                      <div className="clearfix"></div>
                    </div>
                  )
                }): 'Patient Problem not found.'}
              </div>
            </Card>
          </div>
        </SideDrawer>
      )}
    </MyAccountWrapper>
  );
};
export default MyAppointments;
