import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../../Library/Module";
import Wrapper from "../Wrapper";
import Photo from "../../images/photo.png";
import { ThemeContext } from "../../Context/Theme/Context";
import Profile from "../../images/Dr-anand-josh-profile.jpg"
import VaraveenaMadhuri from "../../images/Dr-VaraveenaMadhuri.jpg"

interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const AboutUs = ({ link }: HomeProps): JSX.Element => {
    const { whyUsList } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            <Wrapper
                pageDes={`Your family doctor at your door step`}
                pageTitle="Dr. Anand Vinaykumar Joshi">
                <div className="sectionInner">
                    <div className="container pt-4 pb-4">
                        <div className="row align-items-center justify-content-center pb-3 pt-3">
                            <div className="col-md-6 col-sm-12 text-center">
                                <img className="profileImage" src={Profile} title="" alt="" />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <h3>Dr. Anand V Joshi</h3>
                                <p><strong>Intensivist and Physician</strong></p>
                                <p>Dr. Anand Joshi is a committed medical practitioner with a compelling profile as an Intensivist spanning over two decades and across continents.</p>
                                <p>Having been in charge of complex and critical care in various capacities, Dr. Joshi deftly handles a broad spectrum of health conditions with optimal knowledge, care and consideration.</p>
                                <p>At the foundation of Dr. Joshi's practice is his firm belief that besides due medical intervention; empathy and communication effectively contribute in mitigating patient concerns. Helping patients make informed decisions through perhaps the most challenging phase of their lives, he believes, is half the battle won.</p>
                                <p>Keeping in line, Dr. Joshi's website is an endeavour to accord patients maximum reach and ease of communication. His earnest attempt at making what could be an often daunting experience, less cumbersome and more human.</p>
                                <p>**English, Hindi, Marathi, Telugu and Gujarati are his preferred languages.</p>
                                <div className="innerContactdetails">
                                    <ul>
                                        <li><span className="material-symbols-outlined"> mail </span> <span>info@dranandjoshi.com </span></li>
                                        <li><span className="material-symbols-outlined"> call </span> <span>+91 98660 35911</span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
<hr className="hr" />
                    <div className="container pt-4 pb-4">
                        <div className="row align-items-center justify-content-center pb-3 pt-3">
                            
                            <div className="col-md-6 col-sm-12">
                                <h3>Dr Varaveena Madhuri</h3>
                                <p><strong>Your Trusted Histopathologist</strong></p>
                                <p>Dr Veena Madhuri is a Consultant Gastrointestinal-Hepatopathologist.  </p>
                                <p>Her primary qualification is Diploma in clinical pathology from Osmania Medical College, followed by DNB (Pathology) from the prestigious Deenanath Mangeshkar Hospital and Research Centre, Pune. </p>
                                <p>She has worked as an observer at West Mead Hospital, Sydney, Australia. She has national and international publications at her credit. </p>
                                <p>She has a good expertise in handling a wide variety of cases including IHC for over 12 years.</p>

                            </div>
                            <div className="col-md-6 col-sm-12 text-center">
                                <img className="profileImage" src={VaraveenaMadhuri} title="" alt="" />
                            </div>
                        </div>
                    </div>


                    {/* <div className="whyUs">
                        <div className="container">
                            <div className="row justify-content-center align-content-center">
                                <div className="col-md-6">
                                    <div className="row">
                                        <h1>Why Us</h1>
                                        <p>  Specialized medical checkup tailored to the patient</p>
                                        {whyUsList?.map((item: any) => {
                                            return (
                                                <div className="col-sm-6 mb-2">
                                                    <div className="inner">
                                                        <div className="icon"><span className="material-symbols-outlined">stacked_line_chart</span></div>
                                                        <h4>{item?.title}</h4>
                                                        <div className="content" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img src={Photo} alt="" title="" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="contactMap">
                        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.3012030251!2d54.94730223834279!3d25.076381466775654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1678113041432!5m2!1sen!2sin" width="100%" height="600" loading="lazy"></iframe>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};
export default AboutUs;
