/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { Alert, Card, Input, Button } from "../../Library/Module";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { RegisterUser } from "../../servies/services";
import Checkboxes from "../../Library/Checkbox/Checkboxes";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import { theme, variables } from "../../theme";
type formDataProps = {
    user_name: string;
    password: string;
    email_id: string;
    mobile: string;
    age: string;
    first_name: string;
    last_name: string;
    country: string;
    city: string;
    user_type: 'patient' | 'admin'
    created_by: string;
};

const RegisterForm = ({
    activeTab
}: { activeTab?: (data?: any) => void }) => {
    const navigate = useNavigate();
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const login_details_frontend = localStorage.getItem("login_details_frontend")
    useEffect(() => {
        console.log("login_details_frontend", login_details_frontend)
        if (login_details_frontend) {
            setFormData(JSON.parse(login_details_frontend))
        }
    }, [login_details_frontend])

    const Submit = async (event: any) => {
        setIsFormSubmitted(true);
        if (
            formData?.user_name !== undefined &&
            formData?.user_name.length !== 0 &&
            formData?.password !== undefined &&
            formData?.password.length !== 0
        ) {
            setIsValid(true);
            formData.user_type = 'patient'
            formData.created_by = 'self'
            formData.age = 'null'

            const APIResponse = await RegisterUser(formData);
            console.log("APIResponse", APIResponse)
            if (APIResponse?.data?.isSuccess) {
                toast.success(APIResponse?.data?.messages);
                activeTab?.('Login')
            } else {
                toast.error(APIResponse?.data?.messages);
                setIsError(true);
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    }

    return (

        <Card>
            {/* <div className="logoPlaceholder">
                <img src={Logo} alt="" title="" />
            </div> */}
            <h3>Welcome to {variables?.site_title}</h3>
            <p className="text-center mb-4">
                Sign in to continue to {variables?.site_title}
            </p>
            <div className="container-fluid">
                {!isValid && (
                    <Alert
                        AlertStyleType="danger"
                        AlertText={`Please enter required fields`}
                    ></Alert>
                )}
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            inputName="user_name"
                            inputType="text"
                            labelName={"Username"}
                            placeholder="Username"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.user_name}
                        />
                        <Input
                            inputName="email_id"
                            inputType="text"
                            labelName={"Email ID"}
                            placeholder="Enter email id"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.email_id}
                            required={true}
                        />
                        <Input
                            inputName="mobile"
                            inputType="mobile"
                            labelName={"Mobile"}
                            placeholder="Enter mobile"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.mobile}
                        />
                        <Input
                            inputName="password"
                            inputType="password"
                            labelName={"Password"}
                            placeholder="Enter Password"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.password}
                        />
                        {/* <Input
                            inputName="age"
                            inputType="text"
                            labelName={"Age"}
                            placeholder="Enter Age"
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.age}
                        /> */}
                    </div>
                    <div className="col-md-6">
                        <Input
                            inputName="first_name"
                            inputType="text"
                            labelName={"First Name"}
                            placeholder="First Name"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.first_name}
                        />
                        <Input
                            inputName="last_name"
                            inputType="text"
                            labelName={"Last Name"}
                            placeholder="Last Name"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.last_name}
                        />
                        <Input
                            inputName="country"
                            inputType="text"
                            labelName={"Country"}
                            placeholder="Enter Country"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.country}
                        />
                        <Input
                            inputName="city"
                            inputType="text"
                            labelName={"City"}
                            placeholder="Enter City"
                            required={true}
                            onChangeSingleCallback={onChangeSingleCallback}
                            col="12"
                            isFormSubmitted={isFormSubmitted}
                            value={formData?.city}
                        />

                    </div>
                </div>
                <div className="row align-content-end justify-content-end">
                    <div className="col-sm-6 text-left">
                        <NavLink to="/forgot-password">Forgot Password</NavLink>
                    </div>
                    <div className="col-sm-6 text-right">
                        <Button
                            onClick={() => Submit("")}
                            buttonText={"Register"}
                            buttonStyleType={"primary"}
                            theme={theme}
                        >
                            <span className="material-symbols-outlined">
                                login
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </Card>

    );
};
export default RegisterForm;
