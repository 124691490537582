/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-empty-pattern */
import { useContext, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { toast } from "react-toastify";
import { CMSByType } from "../../servies/services";
import Loader from "../../Library/Loader/Loader";
import { ThemeContext } from "../../Context/Theme/Context";
import TestimonialsInner from "./TestimonialsInner";

const Testimonials = () => {
    return (
        <Wrapper pageTitle="Testimonials" pageDes="We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.">
            <div className="pageSection">
                <div className="aboutUs">
                    <div className="container">
                        <div className="row testimonilasList">
                            <TestimonialsInner></TestimonialsInner>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Testimonials;
