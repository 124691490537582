/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { Button, CustomTable } from "../../Library/Module";
import MyAccountWrapper from "./MyAccountWrapper";
import MyOrdersRow from "./MyOrdersRow";
import { GetAppointments, GetFamily } from "../../servies/services";
import { NavLink } from "react-router-dom";


const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "id",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "plan_name",
      display: "plan_name",
      sort: true,
    },
    {
      name: "Order Details",
      display: "Order Details",
      sort: true,
    },
    {
      name: "appointment_date_time",
      display: "appointment_date_time",
      sort: true,
    },

    {
      name: "patient_name",
      display: "patient_name",
      sort: true,
    },
    {
      name: "payment_id",
      display: "Order Details",
      sort: true,
    },
    {
      name: "created_on",
      display: "Created Date",
      sort: true,
    },

  ],
};



const MyOrders = (): JSX.Element => {
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [showCells, setShowCells] = useState(null);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [actionType, setActionType] = useState<any>({});

  const pageData = 'Order Details'
  const pageDescData = 'You can manage the Order Details in this module.'
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const callbackFresh = (data: any) => {
    console.log('data', data)
    setActionType({
      type: "add",
      id: 'id',
      data: data
    })
  }

  const showCellCallback = () => {

  }

  useEffect(() => {
    setIsLoading(false);
    setTableData([]);
    const getData = async () => {
      // @ts-ignore
      const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend")) || "";
      const apidata = await GetAppointments(user_details_frontend?.id);
      const APIResponse = apidata?.data?.data;
      console.log('APIResponse', APIResponse)
      setTableData(APIResponse);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);

  return (
    <MyAccountWrapper>

      <CustomTable
        header={tableConfig.tableHeader}
        sortDefault={tableConfig.sortDefault}
        data={tableData}
        tableTitle={pageData}
        tableSubTitle={pageDescData}
        dropdown={""}
        search_bar={false}
        showCellCallback={showCellCallback}
        sortState={sortState}
        isLoading={isLoading}
        headerAction={undefined}
      >
        {({ rowData, index }: { rowData: any; index: any }) => (
          <MyOrdersRow
            rowData={rowData}
            index={index}
            showCells={showCells}
            reloadData={reloadData}
            callbackId={callbackFresh}
          ></MyOrdersRow>
        )}
      </CustomTable>
    </MyAccountWrapper>
  );
};
export default MyOrders;
