import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { CreateMeeting, GetServices, GetSessionStatus, authenticate } from "../../servies/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card } from "../../Library/Module";
import { theme } from "../../theme";
import { ThemeContext } from "../../Context/Theme/Context";
import { objectToQueryString } from "../../Library/Utility/Utility";
import moment from "moment";
import Loader from "../../Library/Loader/Loader";

const Success = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams();
    const session_id = searchParams.get("session_id")
    const [statusDetails, setStatusDetails] = useState<any>({})
    const [apiStatus, setApiStatus] = useState<any>(false)
    const errorMsg = 'We are not able to process your request. Please check your payment details in your dashboard.'
    const [isSuccess, setIsSuccess] = useState()
    const [creatMeeting, setCreatMeeting] = useState<any>()
    const [orderDetails, setOrderDetails] = useState<any>()
    const [isLoading, setIsloading] = useState(false)
    const [msg, setMsg] = useState('Getting payment details')

    const combineDateAndTime = (date: any, time: any) => {
        // var date = '2019-02-16';
        // var time = '8:24 PM';

        // tell moment how to parse the input string
        var momentObj = moment(date + time, 'DD/MM/YYYYTHH:mm:s');

        // conversion
        var dateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');

        console.log("dateTime", dateTime);
        return dateTime

    };


    useEffect(() => {
        if (session_id && session_id?.length === 66) {
            const apiData = async () => {
                setApiStatus(false)
                const query = {
                    session_id: session_id
                }
                setIsloading(true)
                const apidata = await GetSessionStatus(objectToQueryString(query));
                console.log("apidata?.data", apidata?.data?.isSuccess)
                setIsSuccess(apidata?.data?.isSuccess)
                if (apidata?.data?.isSuccess) {
                    setStatusDetails(apidata?.data?.session)
                    if (apidata?.data?.session?.payment_status === "unpaid") {
                        setMsg('Payment Failed')
                        setApiStatus(false)
                    }
                    if (apidata?.data?.session?.payment_status === "paid") {
                        setMsg('Payment Received')
                    setApiStatus(true)
                    let orderDetails = apidata?.data?.order_details
                    setOrderDetails(apidata?.data?.order_details)
                    console.log("orderDetails?.service_type", orderDetails?.service_type)
                    if (orderDetails?.service_type === "Online Meeting" && orderDetails?.meeting_type !== "WhatsApp video call") {
                        const postData = {
                            "topic": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
                            "agenda": `Online meeting with ${orderDetails?.details?.patient_name} for ${orderDetails?.plan_name}`,
                            "duration": orderDetails?.meeting_duration,
                            "password": "12345678",
                            "host_email": "balaji.image@gmail.com",
                            "start_time": combineDateAndTime(orderDetails?.appointment_date_time, '08:24 PM'),
                            "host_name": "Balaji",
                            "patient_email": "btlakal25@gmail.com",
                            "id": orderDetails?.payment_id
                        }
                        console.log("postData", postData)
                        const apiMeetingData = await CreateMeeting(postData, apidata?.data?.session);
                        setCreatMeeting(apiMeetingData?.data?.zoom)
                        console.log("postData", apiMeetingData?.data?.zoom)
                        setIsloading(false)
                    }
                }
                } else {
                    toast.error("Unable to process your payment details.")
                    setMsg('Unable to process your payment details.')
                }
            }
            apiData()

        } else {
            toast.error(errorMsg)
            setApiStatus(false)
            setMsg('We are not able to process your request. Please check your payment details in your dashboard.')
        }
    }, [session_id])

    console.log("session_id", session_id, statusDetails)

    return (
        <>
            <Wrapper pageTitle={msg}>
                <div className="innerWrapperFull">
                    <div className="servicesList">
                        <div className="row">
                            <div className="inner list">
                                {!session_id && <div className="info">
                                    <Alert AlertType="inline" AlertText={errorMsg}></Alert>
                                </div>
                                }
                                {apiStatus &&
                                    <>
                                        {orderDetails?.service_type !== "Online Meeting" ?
                                            <>
                                                <h2>Your appointment is booked at the following clinic.</h2>
                                                <p>Pune</p>
                                                <p><label>Date & Time </label>{orderDetails?.appointment_date_time}</p>
                                                <p>Please call secretary on following number for any query or change.</p>
                                            </>
                                            :
                                            <>
                                                <h2>Your appointment - link is as follows.</h2>
                                                <p><label>{orderDetails?.meeting_type} Link </label>{orderDetails?.meeting_type === "WhatsApp video call" ? `Admin will call you on whatsapp at ${orderDetails?.appointment_date_time}` : creatMeeting?.start_url ? <a href={`${creatMeeting?.start_url}`}>Zoom Link url</a> : ''} </p>
                                                <p>Please call or message to online number for any change.</p>
                                            </>
                                        }
                                        <h2 className="mt-3">Payment Details</h2>
                                        <p><label>Amount Total</label>{statusDetails?.amount_total / 100}</p>
                                        <p><label>Email</label>{statusDetails?.customer_details?.email}</p>
                                        <p><label>Name</label>{statusDetails?.customer_details?.name}</p>
                                        <p><label>Mode</label>{statusDetails?.mode}</p>
                                        <p><label>Payment Intent</label>{statusDetails?.payment_intent}</p>
                                        <p><label>Payment Status</label>{statusDetails?.payment_status}</p>
                                        <p><label>Status</label>{statusDetails?.status}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>



            {/* <Wrapper pageTitle={session_id ? statusDetails?.payment_status === "unpaid" ? "Payment Failed" : "Payment Received" : 'Payment id missing'} pageDes={session_id ? `We have ${statusDetails?.payment_status === "unpaid" ? "not" : ''} received your payment.` : 'Unable to process your payment details.'}>
                <div className="innerWrapperFull">
                    <div className="servicesList">
                        <div className="row">
                            <div className="inner list">
                                {!session_id && <div className="info">
                                    <Alert AlertType="inline" AlertText={errorMsg}></Alert>
                                </div>
                                }
                                {apiStatus &&
                                    <>
                                        {orderDetails?.service_type !== "Online Meeting" ?
                                            <>
                                                <h2>Your appointment is booked at the following clinic.</h2>
                                                <p>Pune</p>
                                                <p><label>Date & Time </label>{orderDetails?.appointment_date_time}</p>
                                                <p>Please call secretary on following number for any query or change.</p>
                                            </>
                                            :
                                            <>
                                                <h2>Your appointment - link is as follows.</h2>
                                                <p><label>{orderDetails?.meeting_type} Link </label>{orderDetails?.meeting_type === "WhatsApp video call" ? `Admin will call you on whatsapp at ${orderDetails?.appointment_date_time}` : creatMeeting?.start_url ? <a href={`${creatMeeting?.start_url}`}>Zoom Link url</a> : ''} </p>
                                                <p>Please call or message to online number for any change.</p>
                                            </>
                                        }
                                        <h2 className="mt-3">Payment Details</h2>
                                        <p><label>Amount Total</label>{statusDetails?.amount_total / 100}</p>
                                        <p><label>Email</label>{statusDetails?.customer_details?.email}</p>
                                        <p><label>Name</label>{statusDetails?.customer_details?.name}</p>
                                        <p><label>Mode</label>{statusDetails?.mode}</p>
                                        <p><label>Payment Intent</label>{statusDetails?.payment_intent}</p>
                                        <p><label>Payment Status</label>{statusDetails?.payment_status}</p>
                                        <p><label>Status</label>{statusDetails?.status}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>  */}

        </>
    );
};
export default Success;
