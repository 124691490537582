/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from "react";
import { Button, Input } from "../../Library/Module";
import { theme } from "../../theme";
import MyAccountWrapper from "./MyAccountWrapper";
import { GetUserDetailsById, RegisterUserUpdate } from "../../servies/services";
import { toast } from "react-toastify";

type formDataProps = {
    user_name: string;
    password: string;
    email_id: string;
    mobile: string;
    age: string;
    first_name: string;
    last_name: string;
    country: string;
    city: string;
    user_type: 'patient' | 'admin'
    created_by: string;
};


const UpdateProfile = (): JSX.Element => {
    const [tableData, setTableData] = useState([])
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<any>();
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    // @ts-ignore
    const user_details_frontend = JSON.parse(localStorage.getItem("user_details_frontend"));

    useEffect(() => {
        setFormData({});
        const getData = async () => {
            const apidata = await GetUserDetailsById(user_details_frontend?.id);
            const APIResponse = apidata?.data?.data;
            setFormData(APIResponse);
        };
        getData();
    }, [user_details_frontend?.id]);

    const Submit = async () => {
        const data = { ...formData }
        delete data.password
        delete data.email_id_verified
        delete data.mobile_verified
        delete data.created_on
        delete data.updated_on
        delete data.__v
        // data.id = data?._id
        // delete data.
        const apidata = await RegisterUserUpdate(data);
        console.log("apidata", apidata)

        if (apidata?.data?.isSuccess) {
            toast.success(apidata?.data?.messages);
        } else {
            toast.error(apidata?.data?.messages);
        }

    }

    return (
        <MyAccountWrapper>
            <div className="row">
            <h4 className="card-title">Update Details</h4>
            <p className="mb-3">You can Update the details in this module.</p>
                <div className="col-md-6">
                    <Input
                        inputName="user_name"
                        inputType="text"
                        labelName={"Username"}
                        placeholder="Username"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.user_name}
                    />
                    <Input
                        inputName="email_id"
                        inputType="text"
                        labelName={"Email ID"}
                        placeholder="Enter email id"
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.email_id}
                    />
                    <Input
                        inputName="mobile"
                        inputType="text"
                        labelName={"Mobile"}
                        placeholder="Enter mobile"
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.mobile}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        inputName="first_name"
                        inputType="text"
                        labelName={"First Name"}
                        placeholder="First Name"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.first_name}
                    />
                    <Input
                        inputName="last_name"
                        inputType="text"
                        labelName={"Last Name"}
                        placeholder="Last Name"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.last_name}
                    />
                    <Input
                        inputName="country"
                        inputType="text"
                        labelName={"country"}
                        placeholder="Enter country"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.country}
                    />
                    <Input
                        inputName="city"
                        inputType="text"
                        labelName={"City"}
                        placeholder="Enter City"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.city}
                    />

                </div>
            </div>
            <div className="row align-content-end justify-content-end">

                <div className="col-sm-6 text-right">
                    <Button
                        onClick={() => Submit()}
                        buttonText={"Update"}
                        buttonStyleType={"primary"}
                        theme={theme}
                    >
                        <span className="material-symbols-outlined">
                            login
                        </span>
                    </Button>
                </div>
            </div>
        </MyAccountWrapper>
    );
};
export default UpdateProfile;
